import { takeLatest, put, call } from 'redux-saga/effects';

import {
  automatedFeedDynamicApi,
  automatedFeedErrorCorrectionApi,
  automatedFeedPolicyApi,
  automatedFeedSearchApi,
} from '@src/redux/api/FADB/inquiry/automatedFeeds/searchAPI';
import {
  SET_FILE_DATA,
  SET_PAYLOAD_POLICY_DETAILS,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS,
  UPDATE_AUTOMATED_FEED_SEARCH,
  UPDATE_AUTOMATED_FEED_SEARCH_ERROR,
  UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS,
} from '@src/redux/action/FADB/inquiry/automatedFeeds/automatedConstants';
import {
  FETCH_DYNAMIC_DROPDOWN_FAILURE,
  FETCH_DYNAMIC_DROPDOWN_REQUEST,
  FETCH_DYNAMIC_DROPDOWN_SUCCESS,
} from '@src/redux/action/FADB/policy/policyConstants';

function* searchAutomatedFeedSaga(action) {
  try {
    const feedData = yield call(automatedFeedSearchApi, action.feedData);
    yield put({ type: UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS, feedData });
  } catch (error) {
    yield put({ type: UPDATE_AUTOMATED_FEED_SEARCH_ERROR, error });
  }
}
function* policyDetailsSaga(action) {
  try {
    const policyData = yield call(automatedFeedPolicyApi, action.policyData);
    yield put({
      type: UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS,
      policyData,
    });
  } catch (error) {
    yield put({ type: UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR, error });
  }
}
function* errorCorrectionSaga(action) {
  try {
    const findData = yield call(
      automatedFeedErrorCorrectionApi,
      action.findData
    );
    yield put({
      type: UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS,
      findData,
    });
  } catch (error) {
    yield put({ type: UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR, error });
  }
}
function* fetchDynamicDropdownSaga(action) {
  try {
    const dynamicData = yield call(automatedFeedDynamicApi);
    yield put({ type: FETCH_DYNAMIC_DROPDOWN_SUCCESS, dynamicData });
  } catch (error) {
    yield put({ type: FETCH_DYNAMIC_DROPDOWN_FAILURE, error });
  }
}
export default function* searchAutomatedFeedsSaga() {
  yield takeLatest(UPDATE_AUTOMATED_FEED_SEARCH, searchAutomatedFeedSaga);
  yield takeLatest(SET_PAYLOAD_POLICY_DETAILS, searchAutomatedFeedSaga);

  yield takeLatest(UPDATE_AUTOMATED_FEED_POLICY_DETAILS, policyDetailsSaga);
  yield takeLatest(UPDATE_AUTOMATED_FEED_ERROR_CORRECTION, errorCorrectionSaga);

  yield takeLatest(FETCH_DYNAMIC_DROPDOWN_REQUEST, fetchDynamicDropdownSaga);
}
