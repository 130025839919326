import {
  LOOK_UP_POLICY,
  LOOK_UP_POLICY_SUCCESS,
  LOOK_UP_POLICY_ERROR,
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_ERROR,
  FETCH_LEGAL_ENTITIES_REQUEST,
  FETCH_LEGAL_ENTITIES_SUCCESS,
  FETCH_LEGAL_ENTITIES_FAILURE,
  FETCH_DYNAMIC_DROPDOWN_REQUEST,
  FETCH_DYNAMIC_DROPDOWN_SUCCESS,
  FETCH_DYNAMIC_DROPDOWN_FAILURE,
  CANCEL_LOOK_UP_POLICY,
} from '../../../action/FADB/policy/policyConstants';

const initialState = {
  policyData: {},
  // dropdownData1: [],
  error: null,
};

export default function policyReducer(state = initialState, action) {
  switch (action.type) {
    case LOOK_UP_POLICY:
      return { ...state, policyData: {} };
    case LOOK_UP_POLICY_SUCCESS:
      return { ...state, policyData: action.policyData };
    case LOOK_UP_POLICY_ERROR:
      return { ...state, error: action.error };
    case UPDATE_POLICY:
      return { ...state, policyData: action.policyData };
    case UPDATE_POLICY_SUCCESS:
      return { ...state, policyData: action.policyData };
    case UPDATE_POLICY_ERROR:
      return { ...state, error: action.error };
    case FETCH_LEGAL_ENTITIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_LEGAL_ENTITIES_SUCCESS:
      return { ...state, legalEntities: action.data, loading: false };
    case FETCH_LEGAL_ENTITIES_FAILURE:
      return { ...state, error: action.error, loading: false };
    case FETCH_DYNAMIC_DROPDOWN_REQUEST:
      return { ...state, loading: true };
    case FETCH_DYNAMIC_DROPDOWN_SUCCESS:
      return { ...state, legalEntities: action.data, loading: false };
    case FETCH_DYNAMIC_DROPDOWN_FAILURE:
      return { ...state, error: action.error, loading: false };
    case CANCEL_LOOK_UP_POLICY:
      return { ...state, policyData: {} };
    default:
      return state;
  }
}
