import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

export default function Suffix_FundingBankingInfo() {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>Default Funding & Banking </Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Funding Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>Fully Insured</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Case Installation Bank Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>Fleet</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Company Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>X</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>By :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>PRIMEUPD</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Last Changed :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>11/05/2018</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  )
}
