import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import { PolicySection } from './PolicySection';
import { SuffixSection } from './SuffixSection';
import { AccountSection } from './AccountSection';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInput } from '@abyss/web/ui/DateInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { formateDate } from '@src/utils/dateUtil';
import { Alert } from '@abyss/web/ui/Alert';

const AccountLookup = ({ policyData, suffixData }) => {
  const { getLocation } = useRouter();
  let location = getLocation();
  const { navigate } = useRouter();
  console.log(
    'AccountLookup location---',
    location?.state?.policyData?.[0]?.POL_NBR
  );

  const { readOnly, token } = useAuth();
  const [dropdownData, setDropDownData] = useState('');
  const [generalData, setGeneralData] = useState('');

  const [policyNumber, setPolicyNumber] = useState();
  const [suffixCode, setSuffixCode] = useState();
  const [accountCode, setAccountCode] = useState('');
  const [accountEffectiveDate, setAccountEffectiveDate] = useState('');
  const [attributeEffectiveDate, setAttributeEffectiveDate] = useState('');
  const [cancellationDate, setCancellationDate] = useState('');
  const [subscriberStatusValidation, setSubscriberStatusValidation] =
    useState('');

  const [hmoAccountDivision, setHmoAccoutnDivision] = useState('');
  const [checkReview, setCheckReview] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertSuccText, setAlerSucctText] = useState('');
  const [showSuccAlert, setShowSuccAlert] = useState(false);

  const handleGeneralData = (data) => {
    console.log('data----', data[0]?.POL_ACCT_NBR);
    setGeneralData(data);
    setAccountCode(data[0]?.POL_ACCT_NBR);
  };

  useEffect(() => {}, [location]);

  const fecthDropdownData = async () => {
    await axios
      // .get(`${BASE_URL}fadb-service/acc-inq-dropdown-data/`, {
      .get(`${BASE_URL}static/accDrpdwnData/accountDrpdwnData `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data, '--------');
        setDropDownData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (policyData) {
      setPolicyNumber(policyData?.[0]?.POL_NBR);
    } else {
      setPolicyNumber(location?.state?.policyData?.[0]?.POL_NBR);
    }

    setSuffixCode(suffixData?.[0]?.SUFX_CD);
  }, [suffixData, policyData]);

  useEffect(() => {
    if (token) fecthDropdownData();
  }, [token]);

  useEffect(() => {
    if (generalData) {
      setAccountEffectiveDate(generalData[0]?.ACCT_EFF_DT);
      setAttributeEffectiveDate(generalData[0]?.ATT_EFF_DT);
      setCancellationDate(generalData[0]?.ACCT_CANC_DT);
      setSubscriberStatusValidation(generalData[0]?.SBSCR_STS_VLD_IND);
      setHmoAccoutnDivision(generalData[0]?.HMO_ACCTDIV_ADM_CD);
    }
  }, [generalData]);

  // STG Payload

  // {"polacctEntId":"3262919","polAcctNbr":"000","acctEffDt":"01/04/2019","attEffDt":"01/04/2019",
  // "acctCancDt":"03/01/2020","hmoAcctdivAdmCd":"021","sbscrStsVldInd":"Y",
  // "userShortNm":"JMOGAL","sufxEntId":2083844,"suffxTypCd":"SG","sufxEffDt":"2018-12-01",
  // "sufxAttEffDt":"2018-12-01","sufxCancDt":"2020-03-01","fndngEffDt":"2018-12-01",
  // "legEntyCd":"30400","polEntId":1529696}

  const handleSubmit = async (redirectPage) => {
    console.log('---submit called---');
    console.log(generalData, '---account lookup reponse');
    if (!generalData) {
      setShowAlert(true);
      setAlertText('Press the lookup button first ');
    }
    try {
      const data = {
        polacctEntId: generalData[0]?.POLACCT_ENT_ID,
        polAcctNbr: generalData[0]?.POL_ACCT_NBR,
        acctEffDt: formateDate(accountEffectiveDate),
        attEffDt: formateDate(attributeEffectiveDate),
        acctCancDt: formateDate(cancellationDate),
        hmoAcctdivAdmCd: hmoAccountDivision,
        sbscrStsVldInd: subscriberStatusValidation,
        userShortNm: generalData[0]?.USER_SHORT_NM,
        sufxEntId: generalData[0]?.SUFX_ENT_ID,
        suffxTypCd: location?.state?.suffixData?.[0]?.SUFX_TYP_CD,
        sufxEffDt: location?.state?.suffixData?.[0]?.SUFX_EFF_DT,
        sufxAttEffDt: location?.state?.suffixData?.[0]?.ATT_EFF_DT,
        sufxCancDt: location?.state?.suffixData?.[0]?.SUFX_CANC_DT,
        fndngEffDt: location?.state?.suffixData?.[0]?.SUFX_EFF_DT,
        legEntyCd: location?.state?.suffixData?.[0]?.LEG_ENTY_CD,
        polEntId: generalData[0]?.POL_ENT_ID,
      };

      if (redirectPage === 'accountPage') {
        const response = await axios
          .post(`${BASE_URL}account/update`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                setAlertText(result.replace(/<br\/>$/, ''));
                break;
              } else if (item.hasOwnProperty('success')) {
                result = item['success'];
                setShowSuccAlert(true);
                setAlerSucctText(result);
                break;
              }
            }
          });

        console.log(response);
      } else if (redirectPage === 'suffixPage') {
        const response = await axios
          .post(`${BASE_URL}account/update`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                setAlertText(result.replace(/<br\/>$/, ''));
                break;
              } else if (item.hasOwnProperty('success')) {
                navigate('/fadb/suffix/lookup', {
                  state: {
                    data: location?.state?.policyData,
                    policyNumber: policyNumber,
                  },
                });
                break;
              }
            }
          });
      } else if (redirectPage === 'policyPage') {
        console.log('-----payload----', data);
        const response = await axios
          .post(`${BASE_URL}account/update`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                setAlertText(result.replace(/<br\/>$/, ''));
                break;
              } else if (item.hasOwnProperty('success')) {
                navigate('/fadb/policy');
                break;
              }
            }
          });
      } else if (redirectPage === 'dataEntry') {
        console.log('-----payload----', data);
        const response = await axios
          .post(`${BASE_URL}account/update`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            let result = '';
            for (const item of response.data) {
              if (item.hasOwnProperty('fail')) {
                result = item['fail'];
                setShowAlert(true);
                setAlertText(result.replace(/<br\/>$/, ''));
                break;
              } else if (item.hasOwnProperty('success')) {
                result = item['success'];
                setShowSuccAlert(true);
                setAlerSucctText(result);
                break;
              }
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  };
  function onCancel() {
    setGeneralData('');
    setAccountCode('');
    setAccountEffectiveDate('');
    setAttributeEffectiveDate('');
    setCancellationDate('');
    setSubscriberStatusValidation('');
    setHmoAccoutnDivision('');
    setCheckReview(false);
  }

  // Bank Code Label
  const bankCode = location?.state?.policyData[0].BNK_CD;
  const dropDownBankCode = dropdownData.bnkCd;
  const bankCodeLabel = dropDownBankCode?.find(
    (item) => item.value === bankCode
  )?.label;

  // Funding code Label
  const fundingCode = location?.state?.policyData[0].FUND_ARNG_CD;
  const dropDownFundingCode = dropdownData.fndCd;
  const fundingCodeLabel = dropDownFundingCode?.find(
    (item) => item.value === fundingCode
  )?.label;

  // Type of Effective date label
  const typeOfEffDate = location?.state?.policyData[0].SRVC_ISS_DT_RUL_CD;
  const dropDownEffDate = dropdownData.typEffDate;
  const effDateLabel = dropDownEffDate?.find(
    (item) => item.value === typeOfEffDate
  )?.label;

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
                <Grid>
                  <PolicySection
                    polData={location?.state?.policyData}
                    bankCodeLabel={bankCodeLabel}
                    fundingCodeLabel={fundingCodeLabel}
                    effDateLabel={effDateLabel}
                  />
                  <SuffixSection
                    sufData={location?.state?.suffixData}
                    bankCodeLabel={bankCodeLabel}
                    fundingCodeLabel={fundingCodeLabel}
                  />
                  <AccountSection
                    handleGeneralData={handleGeneralData}
                    polData={location?.state?.policyData}
                    sufData={location?.state?.suffixData}
                  />
                  {/* <GeneralInformation
                    generalData={generalData}
                    dropdownData={dropdownData}
                    setGeneralInfoData={handleGeneralInfoData}
                  /> */}
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                  }}
                >
                  <Alert
                    variant="error"
                    isVisible={showAlert}
                    onClose={() => {
                      setShowAlert(false);
                    }}
                  >
                    {alertText}
                  </Alert>
                </Grid>
                {showSuccAlert && (
                  <Grid
                    css={{
                      margin: '0px !important',
                      marginTop: '20px !important',
                    }}
                  >
                    <Alert
                      variant="success"
                      isVisible={showSuccAlert}
                      onClose={() => {
                        setShowSuccAlert(false);
                      }}
                    >
                      {alertSuccText}
                    </Alert>
                  </Grid>
                )}
                <Grid>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>General Information</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Account Effective Date"
                      value={formateDate(accountEffectiveDate)}
                      onChange={(date) => setAccountEffectiveDate(date)}
                      subText={' '}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Attribute Effective Date"
                      value={formateDate(attributeEffectiveDate)}
                      onChange={(date) => setAttributeEffectiveDate(date)}
                      subText={' '}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Cancellation Date"
                      value={formateDate(cancellationDate)}
                      onChange={(date) => setCancellationDate(date)}
                      subText={' '}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="HMO Account Division"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={hmoAccountDivision}
                      onChange={(value) => setHmoAccoutnDivision(value)}
                      options={dropdownData.healthPlanSite?.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <SelectInput
                      label="Subscriber Status Validation"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      isDisabled={readOnly}
                      value={subscriberStatusValidation}
                      onChange={(value) => setSubscriberStatusValidation(value)}
                      options={[
                        {
                          value: 'N',
                          label: 'No',
                        },
                        {
                          value: 'Y',
                          label: 'Yes',
                        },
                      ]}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <Checkbox
                      label="Check here to review Subscriber status"
                      isChecked={checkReview}
                      onChange={(e) => setCheckReview(e.target.checked)}
                      isDisabled={readOnly}
                      css={{ 'abyss-checkbox-root': { paddingTop: '2.1rem' } }}
                    />
                  </Grid.Col>

                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Last Changed"
                      value={generalData[0]?.ACCT_CHG_DT}
                      isDisabled
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="By"
                      isDisabled
                      value={generalData[0]?.USER_SHORT_NM}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          padding: '0.5rem',
                        },
                      }}
                      onClick={() => handleSubmit('accountPage')}
                      isDisabled={readOnly}
                    >
                      Submit & Account Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          padding: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={() => handleSubmit('suffixPage')}
                    >
                      Submit & Suffix Page
                    </Button>

                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          padding: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={() => handleSubmit('policyPage')}
                    >
                      Submit & Policy Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                          padding: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={() => handleSubmit('dataEntry')}
                    >
                      Submit & Data Entry
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(AccountLookup);
