import {
  UPDATE_CASE_STRUCTURE_SEARCH,
  UPDATE_CASE_STRUCTURE_SEARCH_ERROR,
  UPDATE_CASE_STRUCTURE_SEARCH_SUCCESS,
} from './caseStructureContants';

export function updateCaseStructureSearch(data) {
  return {
    type: UPDATE_CASE_STRUCTURE_SEARCH,
    data,
  };
}
export function updateCaseStructurSearchSuccess(data) {
  return {
    type: UPDATE_CASE_STRUCTURE_SEARCH_SUCCESS,
    data,
  };
}

export function updateCaseStructurSearchError(error) {
  return {
    type: UPDATE_CASE_STRUCTURE_SEARCH_ERROR,
    error,
  };
}
