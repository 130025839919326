import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';

const SuffixLevelSearch = () => {
  const data = [
    {
      col1: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/policy-details">
          00F0001
        </a>
      ),
      col2: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/suffix-details">
          BW
        </a>
      ),
      col3: '12/01/2018',
      col4: 'PRIME',
      col5: '',
      col6: '12/01/2018',
      col7: '-',
      col8: '-',
      col9: 'J',
      col10: '12/01/2018',
      col11: '-',
      col12: 'PRIMUPD',
      col13: '13/12/2019',
    },
    {
      col1: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/policy-details">
          00F0002
        </a>
      ),
      col2: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/suffix-details">
          BW
        </a>
      ),
      col3: '12/01/2018',
      col4: 'PRIME',
      col5: '12/01/2018',
      col6: 'FULLY INSURED',
      col7: '30100',
      col8: '-',
      col9: 'J',
      col10: '',
      col11: 'CHASE',
      col12: '12/31/2019',
      col13: '13/12/2019',
    },
    {
      col1: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/policy-details">
          00F0003
        </a>
      ),
      col2: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/suffix-details">
          BW
        </a>
      ),
      col3: '12/01/2018',
      col4: 'PRIME',
      col5: '12/01/2018',
      col6: 'FULLY INSURED',
      col7: '32100',
      col8: '-',
      col9: 'J',
      col10: '',
      col11: 'Fleet',
      col12: '12/31/2019',
      col13: '13/12/2019',
    },
    {
      col1: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/policy-details">
          00F0004
        </a>
      ),
      col2: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/suffix-details">
          BW
        </a>
      ),
      col3: '12/01/2018',
      col4: 'PRIME',
      col5: '12/01/2018',
      col6: 'FULLY INSURED',
      col7: '34000',
      col8: '-',
      col9: 'J',
      col10: '',
      col11: 'CHASE',
      col12: '12/31/2019',
      col13: '13/12/2019',
    },
    {
      col1: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/policy-details">
          00F0005
        </a>
      ),
      col2: (
        <a href="/fadb/inquiry/case-structure/case-structure-search/suffix-details">
          BW
        </a>
      ),
      col3: '12/01/2018',
      col4: 'PRIME',
      col5: '12/01/2018',
      col6: 'FULLY INSURED',
      col7: '34000',
      col8: '-',
      col9: 'J',
      col10: '',
      col11: 'Fleet',
      col12: '12/31/2019',
      col13: '13/12/2019',
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'POLICY NUMBER',
        accessor: 'col1',
        style: { width: '10px !important' },
      },
      {
        Header: 'SUFFIX CODE',
        accessor: 'col2',
      },
      {
        Header: 'ORG DATE',
        accessor: 'col3',
      },
      {
        Header: 'TYPE',
        accessor: 'col4',
      },
      {
        Header: 'EFF DATE TYPE',
        accessor: 'col5',
      },
      {
        Header: 'ATTRBTS EFF DATE',
        accessor: 'col6',
      },
      {
        Header: 'LEG ENT',
        accessor: 'col7',
      },
      {
        Header: 'GL REINS',
        accessor: 'col8',
      },
      {
        Header: 'HMO LE',
        accessor: 'col9',
      },
      {
        Header: 'CANCLTN DATE',
        accessor: 'col10',
      },
      {
        Header: 'EXT COV',
        accessor: 'col11',
      },
      {
        Header: 'CHNG BY',
        accessor: 'col12',
      },
      {
        Header: 'CHG DT',
        accessor: 'col13',
      },
    ],
    []
  );

  const handleClick = () => {
    window.location.href = '/fadb/inquiry/case-structure/';
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleClick,
    },
  ];

  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData: data,
    initialColumns: columns,
    showDownloadButton: true,
    customHeaderButtons,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: true,
  });
  // const drawer = useOverlay('cellOverflow-drawer');
  // const { data: drawerData } = drawer.getState();
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <DataTable
                  title="Policy Level Results by Change Date"
                  tableState={dataTablePropsPagination}
                />
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(SuffixLevelSearch);
