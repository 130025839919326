import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { getEnvironment } from '@src/utils/environmentUtil';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { TextInput } from '@abyss/web/ui/TextInput';
const FinancialMarket = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const [updateRow, setUpdateRow] = useState({});
  const [marketSegInd, setmarketSegInd] = useState('');
  const [marketSegTxt, setmarketSegTxt] = useState('');
  const [FmsiTypeCd, setFmsiTypeCd] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setUpdateRow(row);
    setmarketSegInd(row?.['FINL_MKT_SEG_IND']);
    setFmsiTypeCd(row?.['FMSI_TYPE_CD']);
    setmarketSegTxt(row?.['FINL_MKT_SEG_TXT']);
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
      console.log('upds row: ', row);
    },
    icon: <IconSymbol icon="edit" />,
    label: <IconSymbol icon="edit" />,
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate ? [] : [updateLinkButton],
  });
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const save = async () => {
    setFlag(false);
    setHideUpdate(false);
    const now = new Date();
    let payload = {
      finlMktSegInd: marketSegInd,
      finlMktSegTxt: marketSegTxt,
      fmsiTypeCd: FmsiTypeCd,
      lstChangeId: userName,
    };
    setmarketSegInd('');
    setFmsiTypeCd('');
    setmarketSegTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/fin-mar-seg-ind/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    setFlag(false);
    setHideAdd(false);
    const now = new Date();
    let payload = {
      newRecord: {
        finlMktSegInd: marketSegInd,
        finlMktSegTxt: marketSegTxt,
        fmsiTypeCd: FmsiTypeCd,
      },
      oldRecord: {
        finlMktSegInd: updateRow['FINL_MKT_SEG_IND'],
        finlMktSegTxt: updateRow['FINL_MKT_SEG_TXT'],
        fmsiTypeCd: updateRow['FMSI_TYPE_CD'],
        lstChangeId: updateRow['LST_CHANGE_ID'],
      },
    };
    setmarketSegInd('');
    setFmsiTypeCd('');
    setmarketSegTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/fin-mar-seg-ind/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setHideUpdate(false);
    setHideAdd(false);
    setFlag(false);
    setmarketSegInd('');
    setFmsiTypeCd('');
    setmarketSegTxt('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />

              <Button variant="tertiary" onClick={addRow} isDisabled={hideAdd}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="Market Seg Ind"
                        value={marketSegInd}
                        onChange={(e) => setmarketSegInd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="Market Seg Txt"
                        value={marketSegTxt}
                        onChange={(e) => setmarketSegTxt(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="FMSI Type Cd"
                        value={FmsiTypeCd}
                        onChange={(e) => setFmsiTypeCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={updateFlag ? update : save}>Save</Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(FinancialMarket);
