export const getEnvironment = () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes('local') || currentUrl.includes('dev')) {
    return 'DEV';
  } else if (currentUrl.includes('tst')) {
    return 'TST';
  } else if (currentUrl.includes('stg')) {
    return 'STG';
  } else {
    return 'PROD';
  }
};
