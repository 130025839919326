import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import useAuth from '@src/utils/useAuth';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
const UCAS = () => {
  const { token } = useAuth();
  const { navigate } = useRouter();
  const tables = [
    {
      id: 1,
      label:
        'UCAS' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'Bank Account' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'BANK_ACCOUNT',
      value: 'BANK_ACCOUNT',
    },
    {
      id: 2,
      label:
        'UCAS' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'Error Suspense' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'ERR_SUSP',
      value: 'ERR_SUSP',
    },
    {
      id: 1,
      label:
        'UCAS' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'SRS Desg' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'SRS_DESG',
      value: 'SRS_DESG',
    },

    {
      id: 4,
      label:
        'UCAS' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'ESCP' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'CFR_ERR_TRANS',
      value: 'ESCP',
    },
    {
      id: 5,
      label:
        'UCAS' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'FMSI AHP Indicator' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'FMSI_AHP_INDICATOR',
      value: 'FMSI_AHP_INDICATOR',
    },
  ];
  const [systemSelectedValue, setSystemSelectedValue] = useState(
    tables[0].value
  );
  const [database, setSelectedDatabase] = useState('');

  const [databaseDropdown, setDatabaseDropdown] = useState([]);
  const [transformedData, setTransformedData] = useState([]);

  const [ftsErrorList, setFtsErrorList] = useState([
    { id: 1, label: 'N', value: 'N', selected: 'selected' },
    { id: 2, label: 'Y', value: 'Y' },
  ]);

  const [selectedFtsError, setSelectedFtsError] = useState(
    ftsErrorList[0].label
  );

  useEffect(() => {
    if (databaseDropdown?.length > 0) {
      const transformedData1 = databaseDropdown.map((item) => ({
        value: item['NAME'],
        label: item['NAME'],
      }));
      setTransformedData(transformedData1);
      setSelectedDatabase(transformedData1[0].label);
    }
  }, [databaseDropdown]);
  const handleDynamicDropdown = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}tableMaintainance/listDbNames/5372/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { queryString: '5065' },
        }
      );
      if (response.status === 200) {
        setDatabaseDropdown(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  const getBankAccData = async () => {
    let payload = [database, systemSelectedValue];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintenance/ucas/get/bankAccount`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const handleClick = async () => {
    if (database) {
      let tableData = await getBankAccData();

      if (systemSelectedValue === 'BANK_ACCOUNT') {
        navigate('/ftmf/ucas/bank-account', {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,

            data: tableData,
          },
        });
      } else if (systemSelectedValue === 'SRS_DESG') {
        let config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        try {
          const response = await axios.get(
            `${BASE_URL}tableMaintenance/ucas/get/srs-desg/${database}/srsDesg`,

            config
          );
          if (response.status === 200) {
            navigate('/ftmf/ucas/srs-desg', {
              state: {
                database: database,
                systemSelectedValue: systemSelectedValue,
                data: response.data,
              },
            });
          }
        } catch (error) {
          console.error(error);
          return {};
        }
      } else if (systemSelectedValue === 'FMSI_AHP_INDICATOR') {
        let config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        try {
          const response = await axios.get(
            `${BASE_URL}tableMaintenance/ucas/get/escp/${database}/fmsiAhpInd`,

            config
          );
          if (response.status === 200) {
            navigate('/ftmf/ucas/fmsi-ahp-indicator', {
              state: {
                database: database,
                systemSelectedValue: systemSelectedValue,
                data: response.data,
              },
            });
          }
        } catch (error) {
          console.error(error);
          return {};
        }
      } else if (systemSelectedValue === 'ERR_SUSP') {
        let config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        try {
          const response = await axios.get(
            // `${BASE_URL}tableMaintenance/ucas/err-susp-data/${database}/${selectedFtsError}`,
            `${BASE_URL}tableMaintenance/ucas/err-susp-data/${database}`,
            config
          );
          if (response.status === 200) {
            navigate('/ftmf/ucas/error-susp', {
              state: {
                database: database,
                systemSelectedValue: systemSelectedValue,
                data: response.data,
              },
            });
          }
        } catch (error) {
          console.error(error);
          return {};
        }
      } else if (systemSelectedValue === 'ESCP') {
        // let config = {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // };
        // try {
        //   const response = await axios.get(
        //     `${BASE_URL}tableMaintenance/ucas/get/escp/${database}/cfrErrTrans`,

        //     config
        //   );
        //   if (response.status === 200) {
        navigate('/ftmf/ucas/escp', {
          state: {
            database: database,
            systemSelectedValue: systemSelectedValue,
            // data: response.data,
          },
        });
        //   }
        // } catch (error) {
        //   console.error(error);
        //   return {};
        // }
      }
      // if (sysTable === 'ERR_SUSP') {
      //   this.router.navigate(['/ftmf/ucas/error-susp'], navigationExtras);
      // } else if (sysTable === 'BANK_ACCOUNT') {
      //   this.router.navigate(['/ftmf/ucas/bank-account'], navigationExtras);
      // } else if (sysTable === 'SRS_DESG') {
      //   this.router.navigate(['/ftmf/ucas/srs-desg'], navigationExtras);
      // }
      // else if (sysTable === 'ESCP') {
      //   this.router.navigate(['/ftmf/ucas/escp'], navigationExtras);
      // }
      // else if (sysTable === 'FMSI_AHP_INDICATOR') {
      //   this.router.navigate(['/ftmf/ucas/fmsi-ahp-indicator'], navigationExtras);
      // }
    }
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white" css={{ padding: '2rem' }}>
              <Grid css={{ marginTop: '40px', marginLeft: '20px' }}>
                <Heading offset={5}>
                  Financial Tables Table Selection Screen
                </Heading>
                <SelectInput
                  label={
                    'System\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Screen\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Table'
                  }
                  placeholder="Pick one"
                  isClearable
                  isSearchable
                  value={systemSelectedValue}
                  onChange={(e) => setSystemSelectedValue(e)}
                  options={tables}
                />
                <SelectInput
                  label="Database"
                  placeholder="Pick one"
                  isClearable
                  isSearchable
                  onChange={(e) => setSelectedDatabase(e)}
                  value={database}
                  options={transformedData}
                />
                {systemSelectedValue === 'ERR_SUSP' && (
                  <>
                    <SelectInput
                      label="FTS Error only"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      onChange={(e) => setSelectedFtsError(e)}
                      value={selectedFtsError}
                      options={ftsErrorList}
                      width="40%"
                    />
                  </>
                )}
              </Grid>
              <Grid css={{ marginTop: '20px', marginLeft: '20px' }}>
                <Button
                  css={{
                    'abyss-button-root': { marginTop: '25px !important' },
                  }}
                  onClick={handleClick}
                >
                  Select
                </Button>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(UCAS);
