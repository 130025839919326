import {
  FETCH_SUFFIX_TYPE_REQUEST,
  FETCH_SUFFIX_TYPE_SUCCESS,
  FETCH_SUFFIX_TYPE_FAILURE,
  FETCH_SUFFIX_DYNAMIC_REQUEST,
  FETCH_SUFFIX_DYNAMIC_SUCCESS,
  FETCH_SUFFIX_DYNAMIC_FAILURE,
} from './suffixConstants';

export function fetchSuffixTypeRequest() {
  return { type: FETCH_SUFFIX_TYPE_REQUEST };
}

export function fetchSuffixTypeSuccess(data) {
  return { type: FETCH_SUFFIX_TYPE_SUCCESS, data };
}

export function fetchSuffixTypeFailure(error) {
  return { type: FETCH_SUFFIX_TYPE_FAILURE, error };
}

export function fetchSuffixDynamicRequest() {
  return { type: FETCH_SUFFIX_DYNAMIC_REQUEST };
}

export function fetchSuffixDynamicSuccess(data) {
  return { type: FETCH_SUFFIX_DYNAMIC_SUCCESS, data };
}

export function fetchSuffixDynamicFailure(error) {
  return { type: FETCH_SUFFIX_DYNAMIC_FAILURE, error };
}
