import { takeLatest, put, call } from 'redux-saga/effects';

import {
  UPDATE_INQUIRY_SEARCH_ERROR,
  UPDATE_INQUIRY_SEARCH_SUCCESS,
  UPDATE_INQUIRY_SEARCH,
} from '@src/redux/action/CMDB/suspectErrror/inquiry/inquiryConstants';
import { inquirySearchApi } from '@src/redux/api/CMDB/suspectError/inquiry/inquiryAPI';
function* searchSaga(action) {
  try {
    const data = yield call(inquirySearchApi, action.data);
    yield put({ type: UPDATE_INQUIRY_SEARCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: UPDATE_INQUIRY_SEARCH_ERROR, error });
  }
}
export default function* searchCaseStructureSaga() {
  yield takeLatest(UPDATE_INQUIRY_SEARCH, searchSaga);
}
