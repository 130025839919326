import React from 'react';
import { PageHeader } from '@src/components';

import { Button } from '@abyss/web/ui/Button';

import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Text } from '@abyss/web/ui/Text';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';

const UcasFMSITable = () => {
  const { navigate, getLocation } = useRouter();

  const location = getLocation();
  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Edit',
        accessor: 'errSusptblClm',
        dataType: 'text',
        enableEditAction: true,
        sortable: false,
        enableFiltering: false,
      },
      {
        Header: 'FMSI_CD',
        accessor: 'fmsiCd',
      },
      {
        Header: 'FMSI_TXT',
        accessor: 'fmsiTxt',
      },
      {
        Header: 'FMSI_GRP_CD',
        accessor: 'fmsiGrpCd',
      },
      {
        Header: 'FMSI_GRP_TXT',
        accessor: 'fmsiGrpTx',
      },
      {
        Header: 'LAST_UPDT_DT',
        accessor: 'lastUpdtD',
      },
      {
        Header: 'LAST_UPDATED_BY',
        accessor: 'lastUpdatedBy',
      },
    ],
    []
  );
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,

    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
  });

  const handleClick = () => {
    navigate('/fmtf/ucas');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Database:
                  </Text>
                  <span>DEV - {location?.state?.database}</span>
                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: '20px',
                    }}
                  >
                    Table:
                  </Text>
                  <span>{location?.state?.systemSelectedValue}</span>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title=""
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'left',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default UcasFMSITable;
