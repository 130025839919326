export const UPDATE_INQUIRY_SEARCH = 'UPDATE_INQUIRY_SEARCH';
export const UPDATE_INQUIRY_SEARCH_SUCCESS = 'UPDATE_INQUIRY_SEARCH_SUCCESS';
export const UPDATE_INQUIRY_SEARCH_ERROR = 'UPDATE_INQUIRY_SEARCH_ERROR';

export const SET_PAYLOAD_DATA = 'SET_PAYLOAD_DATA';

export const SET_POLICY_DATA = 'SET_POLICY_DATA';
export const SET_SUFFIX_DATA = 'SET_SUFFIX_DATA';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

export const UPDATE_INQUIRY_ELEMENT_SEARCH = 'UPDATE_INQUIRY_ELEMENT_SEARCH';
export const UPDATE_INQUIRY_ELEMENT_SEARCH_SUCCESS =
  'UPDATE_INQUIRY_ELEMENT_SEARCH_SUCCESS';
export const UPDATE_INQUIRY_ELEMENT_SEARCH_SEARCH_ERROR =
  'UPDATE_INQUIRY_ELEMENT_SEARCH_ERROR';
