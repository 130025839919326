import { BASE_URL, API_BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
const api = axios.create({
  baseURL: BASE_URL,
});
export function inquirySearchApi(payload, config) {
  return api.post(`cmdb-service/suspect-error-data`, payload, config);
}
export function inquirySearchByElementApi(
  payload,
  queryParam1,
  queryParam2,
  config
) {
  return api.post(
    `cmdb-service/suspect-error-details-by-element/${queryParam1}/${queryParam2}`,
    payload,
    config
  );
}
