export const LOOK_UP_POLICY = 'LOOK_UP_POLICY';
export const LOOK_UP_POLICY_SUCCESS = 'LOOK_UP_POLICY_SUCCESS';
export const LOOK_UP_POLICY_ERROR = 'LOOK_UP_POLICY_ERROR';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const UPDATE_POLICY_ERROR = 'UPDATE_POLICY_ERROR';
export const FETCH_LEGAL_ENTITIES_REQUEST = 'FETCH_LEGAL_ENTITIES_REQUEST';
export const FETCH_LEGAL_ENTITIES_SUCCESS = 'FETCH_LEGAL_ENTITIES_SUCCESS';
export const FETCH_LEGAL_ENTITIES_FAILURE = 'FETCH_LEGAL_ENTITIES_FAILURE';
export const FETCH_DYNAMIC_DROPDOWN_REQUEST = 'FETCH_DYNAMIC_DROPDOWN_REQUEST';
export const FETCH_DYNAMIC_DROPDOWN_SUCCESS = 'FETCH_DYNAMIC_DROPDOWN_SUCCESS';
export const FETCH_DYNAMIC_DROPDOWN_FAILURE = 'FETCH_DYNAMIC_DROPDOWN_FAILURE';
export const CANCEL_LOOK_UP_POLICY = 'CANCEL_LOOK_UP_POLICY';
