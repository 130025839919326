import React, { createContext, useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import SelectTransaction from './SelectTransaction';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Text } from '@abyss/web/ui/Text';
import KeyCorrection from './KeyCorrection';
import useAuth from '@src/utils/useAuth';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { BASE_URL } from '@src/utils/endpoints';
import { format } from 'path';
import { Alert } from '@abyss/web/ui/Alert';
const ItemizeCorrection = () => {
  const { readOnly, token } = useAuth();
  const { navigate } = useRouter();
  const [keyCorrectionData, setKeyCorrectionData] = useState({});
  const [transData, setTransData] = useState({});
  const [pnTrnasError, setPnTransError] = useState('');
  const [policyNum, setPolicyNum] = useState('');
  const [policyNumErr, setPolicyNumErr] = useState('');
  const [sufxCd, setSufxCd] = useState('');
  const [sufxCdErr, setSufxCdErr] = useState('');
  const [policyAccNum, setPolicyAccNum] = useState('');
  const [policyAccNumErr, setPolicyAccNumErr] = useState('');
  const [selectedFeeCd, setSelectedFeeCd] = useState('');
  const [selectedFundCd, setSelectedFundCd] = useState('');
  const [subscSSN, setSubscSSN] = useState('');
  const [subscSSNErr, setSubscSSNErr] = useState('');
  const [subscSSNErr1, setSubscSSNErr1] = useState('');
  const [transID, setTransID] = useState('');
  const [transIDErr, setTransIDErr] = useState('');
  const [toDateChange, setToDateChange] = useState(null);
  const [fromDateChange, setFromDateChange] = useState(null);
  const [toDateChangeErr, setToDateChangeErr] = useState('');
  const [transToDate, setTransToDate] = useState(null);
  const [transFromDate, setTransFromDate] = useState(null);
  const [transToDateErr, setTransToDateErr] = useState('');
  const [chcekToDate, setCheckToDate] = useState(null);
  const [chcekToDateErr, setCheckToDateErr] = useState('');
  const [chcekFromDate, setCheckFromDate] = useState(null);
  const [itemizeOptions, setItemizeOptions]: any = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [policyNum1, setPolicyNum1] = useState('');
  const [sufxCd1, setSufxCd1] = useState('');
  const [policyAccNum1, setPolicyAccNum1] = useState('');
  const [policyNum1Err, setPolicyNum1Err] = useState('');
  const [sufxCd1Err, setSufxCd1Err] = useState('');
  const [policyAccNum1Err, setPolicyAccNum1Err] = useState('');
  const [form2Err, setForm2Err] = useState('');
  const [selectedProductCd, setSelectedProductCd] = useState('');
  const [selectedFundCd1, setSelectedFundCd1] = useState('');
  const [subscSSN1, setSubscSSN1] = useState('');
  const [selectedSublType, setSelectedSublType] = useState('');
  const [selectedActiveRetCd, setSelectedActiveRetCd] = useState('');
  const [selectedHMO, setSelectedHMO] = useState('');
  const id = 'aggregationCorrectionDropdown';
  const url = window.location.href;
  const requestId = url.split('/').pop();
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/aggrCrctn/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setItemizeOptions(response.data);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (token) fetchDropdownData();
  }, [token]);
  useEffect(() => {
    if (itemizeOptions) {
      const item = itemizeOptions?.productCdDropDown;
      setSelectedFundCd1(itemizeOptions?.fundCdDropDown?.[0]?.value);
      setSelectedProductCd(item?.[0]?.label);
      setSelectedFeeCd(itemizeOptions?.feeCdDropDown?.[0]?.value);
      setSelectedFundCd(itemizeOptions?.fundCdDropDown?.[0]?.value);
      setSelectedActiveRetCd(itemizeOptions?.activeRetCdDropDown?.[0]?.value);
      setSelectedHMO(itemizeOptions?.hmoAcctDivDropDown?.[0]?.value);
      setSelectedSublType(itemizeOptions?.sublTypCdDropDown?.[0]?.value);
    }
  }, [itemizeOptions]);
  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formattedDate;
  };
  const checkPolicyNumber = (policyNbr) => {
    const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;
    if (policyNbr.match(regEx)) {
      return true;
    } else {
      return false;
    }
  };
  const checkAlphabet = (sufixcd) => {
    const letters: RegExp = /^[A-Za-z ]+$/;
    if (sufixcd.match(letters)) {
      return false;
    } else {
      return true;
    }
  };
  const checkPolicyAccount = (polAcc) => {
    const pol: RegExp = /^[0-9]+$/;
    if (polAcc.match(pol)) {
      return true;
    } else {
      return false;
    }
  };

  const onReset = () => {
    setSelectedProductCd(''),
      setSelectedFundCd1(''),
      setTransID(''),
      setSubscSSN1(''),
      setSelectedActiveRetCd(''),
      setSelectedSublType(''),
      setSelectedHMO(''),
      setPolicyAccNum1(''),
      setSufxCd1(''),
      setPolicyAccNum1(''),
      setSufxCd1(''),
      setPolicyNum1(''),
      setChecked(false),
      setCheckFromDate(null),
      setCheckToDate(null),
      setTransFromDate(null),
      setTransToDate(null),
      setFromDateChange(null),
      setToDateChange(null),
      setSubscSSN(''),
      setSelectedFundCd(''),
      setSelectedFeeCd(''),
      setPolicyAccNum(''),
      setSufxCd(''),
      setPolicyNum('');
  };
  const validate = () => {
    let valid = true;
    // Step 1 Policy number(mandatory)
    if (
      null === policyNum ||
      policyNum === undefined ||
      policyNum === '' ||
      policyNum.trim() === ''
    ) {
      setPolicyNumErr('Policy Number is required.');
      valid = false;
    } else if (policyNum.length !== 7) {
      setPolicyNumErr('Policy Number must be 7 characters.');
      valid = false;
    } else if (policyNum.length === 7 && !checkPolicyNumber(policyNum)) {
      setPolicyNumErr(
        'Policy Number must be numeric, Except for the 3rd position.'
      );
      valid = false;
    }
    // Step 1 Suffix Code
    if (sufxCd !== null && sufxCd !== undefined && sufxCd !== '') {
      if (checkAlphabet(sufxCd)) {
        setSufxCdErr(
          'Please enter a valid suffix with only letters or spaces.'
        );
        valid = false;
      } else if (sufxCd.length > 2) {
        setSufxCdErr('Suffix Code can be max of 2 characters long.');
        valid = false;
      }
    }
    // Step 1 Policy Account Number
    if (
      policyAccNum !== null &&
      policyAccNum !== undefined &&
      policyAccNum !== ''
    ) {
      if (policyAccNum.length !== 3) {
        setPolicyAccNumErr('Policy Account Number must have a length of 3.');
        valid = false;
      } else if (!checkPolicyAccount(policyAccNum)) {
        setPolicyAccNumErr('Policy Account Number must be a whole number.');
        valid = false;
      }
    }
    // Step 1 Subscriber SSN
    if (subscSSN !== null && subscSSN !== undefined && subscSSN !== '') {
      if (subscSSN.trim().length !== 9 || isNaN(subscSSN)) {
        setSubscSSNErr('Please enter valid Subscriber ID (SSN).');
        valid = false;
      }
    }
    // Step 1 TransId
    if (transID !== null && transID !== undefined && transID !== '') {
      if (isNaN(transID)) {
        setTransIDErr('Step 1: Please enter valid Transaction ID.');
        valid = false;
      }
    }
    // Step 1 Charge Date
    if (fromDateChange != null && toDateChange != null) {
      if (formatDate(fromDateChange) > formatDate(toDateChange)) {
        setToDateChangeErr('From Charge Date not before To Date.');
        valid = false;
      }
    }
    // Step 1 Trans Date
    if (transFromDate != null && transToDate != null) {
      if (formatDate(transFromDate) > formatDate(transToDate)) {
        setTransToDateErr('From TransFst Service Date not before To Date.');
        valid = false;
      }
    }
    // Step 1 Chk Issue Date
    if (chcekFromDate != null && chcekToDate != null) {
      if (formatDate(chcekFromDate) > formatDate(chcekToDate)) {
        setCheckToDateErr('From Check Issue Date not before To Date.');
        valid = false;
      }
    }
    // Step 2 validations
    // Step 2 Policy Number
    if (null !== policyNum1 && policyNum1 !== undefined && policyNum1 !== '') {
      if (policyNum1.length !== 7) {
        setPolicyNum1Err('Policy Number must be 7 characters.');
        valid = false;
      } else if (policyNum1.length === 7 && !checkPolicyNumber(policyNum1)) {
        setPolicyNum1Err(
          'Policy Number must be numeric, Except for the 3rd position.>'
        );
        valid = false;
      }
    }
    // Step 2 Suffix Code
    if (sufxCd1 !== null && sufxCd1 !== undefined && sufxCd1 !== '') {
      if (checkAlphabet(sufxCd1)) {
        setSufxCd1Err(
          'Please enter a valid suffix with only letters or spaces.'
        );
        valid = false;
      } else if (sufxCd1.length > 2) {
        setSufxCd1Err('Suffix Code can be max of 2 characters long');
        valid = false;
      }
    }
    // Step 2 Policy Account Number
    if (
      policyAccNum1 !== null &&
      policyAccNum1 !== undefined &&
      policyAccNum1 !== ''
    ) {
      if (policyAccNum1.length !== 3) {
        setPolicyAccNum1Err('Policy Account Number must have a length of 3.');
        valid = false;
      } else if (!checkPolicyAccount(policyAccNum1)) {
        setPolicyAccNum1Err('Policy Account Number must be a whole number.');
        valid = false;
      }
    }
    // Step 2 Subscriber SSN
    if (subscSSN1 !== null && subscSSN1 !== undefined && subscSSN1 !== '') {
      if (subscSSN1.trim().length !== 9 || isNaN(subscSSN1)) {
        setSubscSSNErr1('Please enter valid Subscriber ID (SSN).');
        valid = false;
      }
      // if (subscSSN1 !== subscSSN) {
      //   if (rqstTxt !== 'EMPLOYEE') {
      //     erroString +=
      //       'Step 2: Request Type for your Request ID is not EMPLOYEE.';
      //     valid = false;
      //   }
      // }
    }
    // Step 2 Retag validations
    // if both the values are not there
    // if (isChecked == true) return valid;
    if (isChecked === null || isChecked === undefined || isChecked === false) {
      if (
        (policyNum1 === null ||
          policyNum1 === undefined ||
          policyNum1 === '') &&
        (sufxCd1 === null || sufxCd1 === undefined || sufxCd1 === '') &&
        (policyAccNum1 === null ||
          policyAccNum1 === undefined ||
          policyAccNum1 === '') &&
        (subscSSN1 === null || subscSSN1 === undefined || subscSSN1 === '') &&
        (selectedSublType === 'Not Used' || selectedSublType === '') &&
        (selectedActiveRetCd === 'Not Used' || selectedActiveRetCd === '') &&
        (selectedHMO === 'Not Used' || selectedHMO === '') &&
        (selectedProductCd === 'Not Used' || selectedProductCd === '') &&
        (selectedFundCd1 === 'Not Used' || selectedFundCd1 === '')
      ) {
        setShowAlert(true);
        setForm2Err(
          'Please either check the Change Financials(LE/Funding) Checkbox \xa0\xa0\xa0\xa0 -OR-\nEnter Atleast one of the following fields:Step 2: Policy NumberStep 2: Suffix Code\nStep 2: Policy Account Number\nStep 2: Subscriber SSN\nStep 2: HMO Acct DivStep 2: SubLine Type CdStep 2: Active Ret Cd\nStep 2: Fund CdStep 2: Product Cd'
        );
        valid = false;
      }
    }
    // if both the values are there
    if (isChecked !== null && isChecked !== undefined && isChecked !== false) {
      if (
        (policyNum1 !== null &&
          policyNum1 !== '' &&
          policyNum1 !== undefined) ||
        (sufxCd1 !== null && sufxCd1 !== '' && sufxCd1 !== undefined) ||
        (policyAccNum1 !== null &&
          policyAccNum1 !== '' &&
          policyAccNum1 !== undefined) ||
        (subscSSN1 !== null && subscSSN1 !== undefined && subscSSN1 !== '') ||
        (selectedSublType !== 'Not Used' && selectedSublType !== '') ||
        (selectedActiveRetCd !== 'Not Used' && selectedActiveRetCd !== '') ||
        (selectedHMO !== 'Not Used' && selectedHMO !== '') ||
        (selectedProductCd !== 'Not Used' && selectedProductCd !== '') ||
        (selectedFundCd1 !== 'Not Used' && selectedFundCd1 !== '')
      ) {
        setShowAlert(true);
        setForm2Err(
          'Please either check the Change Financials(LE/Funding) Checkbox \n -OR-\nEnter Atleast one of the following fields:Step 2: Policy NumberStep 2: Suffix Code\nStep 2: Policy Account Number\nStep 2: Subscriber SSN\nStep 2: HMO Acct DivStep 2: SubLine Type CdStep 2: Active Ret Cd\nStep 2: Fund CdStep 2: Product Cd'
        );
        valid = false;
      }
    }
    return valid;
  };
  const clearFields = () => {
    setShowAlert(false);
    setPolicyNumErr('');
    setSufxCdErr('');
    setPolicyAccNumErr('');
    setSubscSSNErr('');
    setSubscSSNErr1('');
    setTransIDErr('');
    setToDateChangeErr('');
    setTransToDateErr('');
    setCheckToDateErr('');
    setPolicyNum1Err('');
    setSufxCd1Err('');
    setPolicyAccNum1Err('');
    setForm2Err('');
  };
  const handleSubmit = async () => {
    clearFields();
    const valid = validate();
    let payload = {
      requestId: requestId,
      polNbr1: policyNum,
      sufxCd1: sufxCd,
      polAcctNbr1: policyAccNum,
      transId: transID,
      feeCd1: selectedFeeCd,
      fundCd1: selectedFundCd,
      subscrSSN: subscSSN,
      fromChrgDt: fromDateChange,
      toChrgDt: toDateChange,
      fromTrnSvcDt: transFromDate,
      toTrnSvcDt: transToDate,
      fromChkIssDt: chcekFromDate,
      toChkIssDt: chcekToDate,
    };

    if (valid) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
        const response = await axios.post(
          `${BASE_URL}manageRequest/get-correctable-transactions`,
          payload,
          config
        );
        console.log(response);
        if (response.status === 200) {
          let state = {
            requestId: requestId,
            selectedHMO: selectedHMO,
            selectedSublType: selectedSublType,
            selectedProductCd: selectedProductCd,
            retag: isChecked ? 'Y' : 'N',
            selectedActiveRetCd: selectedActiveRetCd,
            polNbr1: policyNum,
            sufxCd1: sufxCd,
            polAcctNbr1: policyAccNum,
            transId: transID,
            feeCd1: selectedFeeCd,
            fundCd1: selectedFundCd,
            subscrSSN: subscSSN,
            fromChrgDt: fromDateChange,
            toChrgDt: toDateChange,
            fromTrnSvcDt: transFromDate,
            toTrnSvcDt: transToDate,
            fromChkIssDt: chcekFromDate,
            toChkIssDt: chcekToDate,
            tableData: response.data,
          };
          navigate(`/cmdb/correction-management/apply-itemized/${requestId}`, {
            state: {
              data: state,
              response: response.data,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>
                    Apply Itemized Correction for Request ID: {requestId}
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                }}
              >
                <React.Fragment>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5} css={{ fontSize: '18px !important' }}>
                      Step 1 : Select Transactions To Be Corrected
                    </Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Policy Number"
                      value={policyNum}
                      onChange={(e) => setPolicyNum(e.target.value)}
                      isDisabled={readOnly}
                      errorMessage={policyNumErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Suffix Code"
                      value={sufxCd}
                      onChange={(e) => setSufxCd(e.target.value)}
                      isDisabled={readOnly}
                      errorMessage={sufxCdErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Policy Account Number"
                      value={policyAccNum}
                      onChange={(e) => setPolicyAccNum(e.target.value)}
                      isDisabled={readOnly}
                      errorMessage={policyAccNumErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Transaction ID"
                      isDisabled={readOnly}
                      value={transID}
                      onChange={(e) => setTransID(e.target.value)}
                      errorMessage={transIDErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="Fee Code"
                      isClearable
                      isSearchable
                      value={selectedFeeCd}
                      onChange={(e) => setSelectedFeeCd(e)}
                      options={itemizeOptions?.feeCdDropDown?.map((col) => ({
                        value: col.value,
                        label: col.label,
                      }))}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="Fund Code"
                      isClearable
                      isSearchable
                      value={selectedFundCd}
                      onChange={(e) => setSelectedFundCd(e)}
                      options={itemizeOptions?.fundCdDropDown?.map((col) => ({
                        value: col.value,
                        label: col.label,
                      }))}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Subscriber SSN"
                      value={subscSSN}
                      onChange={(e) => setSubscSSN(e.target.value)}
                      isDisabled={readOnly}
                      errorMessage={subscSSNErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                    <Text css={{ fontWeight: 600 }}>Charge Date:</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                    <Text css={{ fontWeight: 600 }}>
                      Transaction Fst Service Date:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <DateInput
                      label="From"
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      value={fromDateChange}
                      onChange={(e) => setFromDateChange(e)}
                      errorMessage={toDateChangeErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <DateInput
                      label="To"
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      value={toDateChange}
                      onChange={(e) => setToDateChange(e)}
                      errorMessage={toDateChangeErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <DateInput
                      label="From"
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      value={transFromDate}
                      onChange={(e) => setTransFromDate(e)}
                      errorMessage={transToDateErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <DateInput
                      label="To"
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      value={transToDate}
                      onChange={(e) => setTransToDate(e)}
                      errorMessage={transToDateErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text css={{ fontWeight: 600 }}>Check Issue Date:</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <DateInput
                      label="From"
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      value={chcekFromDate}
                      onChange={(e) => setCheckFromDate(e)}
                      errorMessage={chcekToDateErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <DateInput
                      label="To"
                      subText={' '}
                      isClearable={true}
                      isDisabled={readOnly}
                      value={chcekToDate}
                      onChange={(e) => setCheckToDate(e)}
                      errorMessage={chcekToDateErr}
                    />
                  </Grid.Col>
                </React.Fragment>

                <React.Fragment>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5} css={{ fontSize: '18px !important' }}>
                      Step 2 : Key Correction Values
                    </Heading>
                    <Alert
                      variant="error"
                      isVisible={showAlert}
                      onClose={() => {
                        setShowAlert(false);
                      }}
                    >
                      {form2Err}
                    </Alert>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Policy Number"
                      value={policyNum1}
                      onChange={(e) => {
                        setPolicyNum1(e.target.value);
                      }}
                      isDisabled={readOnly}
                      errorMessage={policyNum1Err}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Suffix Code"
                      value={sufxCd1}
                      onChange={(event) => setSufxCd1(event.target.value)}
                      isDisabled={readOnly}
                      errorMessage={sufxCd1Err}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Policy Account Number"
                      value={policyAccNum1}
                      onChange={(event) => setPolicyAccNum1(event.target.value)}
                      isDisabled={readOnly}
                      errorMessage={policyAccNum1Err}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="Fund Code"
                      isClearable
                      isSearchable
                      value={selectedFundCd1}
                      onChange={(event) => setSelectedFundCd1(event)}
                      options={itemizeOptions?.fundCdDropDown?.map((col) => ({
                        value: col.value,
                        label: col.label,
                      }))}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="Product Code"
                      isClearable
                      isSearchable
                      value={selectedProductCd}
                      onChange={(event) => setSelectedProductCd(event)}
                      options={itemizeOptions?.productCdDropDown?.map(
                        (col) => ({
                          value: col.value,
                          label: col.label,
                        })
                      )}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <TextInput
                      label="Subscriber SSN"
                      value={subscSSN1}
                      onChange={(e) => setSubscSSN1(e.target.value)}
                      isDisabled={readOnly}
                      errorMessage={subscSSNErr1}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="Subl Type Code"
                      isClearable
                      isSearchable
                      value={selectedSublType}
                      onChange={(event) => setSelectedSublType(event)}
                      options={itemizeOptions?.sublTypCdDropDown?.map(
                        (col) => ({
                          value: col.value,
                          label: col.label,
                        })
                      )}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="Active Ret Code"
                      isClearable
                      isSearchable
                      value={selectedActiveRetCd}
                      onChange={(e) => setSelectedActiveRetCd(e)}
                      options={itemizeOptions?.activeRetCdDropDown?.map(
                        (col) => ({
                          value: col.value,
                          label: col.label,
                        })
                      )}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>

                  <Grid.Col span={{ xs: 3 }}>
                    <SelectInput
                      label="HMO Acct Div"
                      isClearable
                      isSearchable
                      value={selectedHMO}
                      onChange={(e) => setSelectedHMO(e)}
                      options={itemizeOptions?.hmoAcctDivDropDown?.map(
                        (col) => ({
                          value: col.value,
                          label: col.label,
                        })
                      )}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col
                    span={{ xs: 2, md: 2, lg: 2, xl: 2 }}
                    css={{ textAlign: 'center' }}
                  >
                    <Text css={{ fontWeight: 600, color: '$redDvz1' }}>
                      -OR-
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <Checkbox
                      label="Change Financials(LE/Funding)"
                      isChecked={isChecked}
                      onChange={(e) => setChecked(e.target.checked)}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                </React.Fragment>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleSubmit}
                  >
                    Next
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    onClick={() => navigate('/cmdb/correction-management')}
                    isDisabled={readOnly}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(ItemizeCorrection);
