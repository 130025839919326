import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

const PolicyLevel = ({ policyDetails }) => {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>Suffix Level</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Type. :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxTypCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Legal Entity :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxLegEntyCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Funding Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxFndEffDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Cancellation Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxCancDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Bank Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxBnkCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Renewal CD Type :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.renewalCdTyp}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Reinstallation Indicator :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.reinstInd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Legal Enitiry OFN :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxLegOfnCd}</Text>
        </Grid.Col>
        {/* TODO: need to check with client */}
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Type oF effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text></Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Funding Arng :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxFundArng}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Attribute Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxAttDt}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Gl Insurance Code:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.sufxGlReinsCd}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Ext. Ben Month Number:</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{policyDetails?.extBenMoNbr}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default PolicyLevel;
