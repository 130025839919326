import { fork } from 'redux-saga/effects';
import policySaga from './FADB/policy/policySaga';
import searchAutomatedFeedsSaga from './FADB/inquiry/automatedFeeds/search';
import searchCaseStructureSaga from './FADB/inquiry/caseStructure/caseStructure';
import searchInquirySaga from './CMDB/suspectError/inquiry/inquiry'

export default function* rootSaga() {
  yield fork(policySaga);
  yield fork(searchAutomatedFeedsSaga);
  yield fork(searchCaseStructureSaga);
  yield fork(searchInquirySaga);
}
