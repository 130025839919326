import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import withAuth from '@src/auth/withAuth';

const PrelimFin = () => {
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card css={{ marginTop: '130px', marginLeft: '230px' }}>
            view-prelim-fin-sum works!
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(PrelimFin);
