import React from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';
import { Link } from '@abyss/web/ui/Link';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import * as XLSX from 'xlsx';
const InquirySearchResult = () => {
  const { readOnly } = useAuth();
  const { navigate, getLocation } = useRouter();

  let location = getLocation();

  const path = '/cmdb/correction-management';
  //get table data
  const data = location?.state;

  const LinkCellRenderer = ({ value }) => {
    return (
      <Link
        href={`/cmdb/correction-management/view-correction-request-detail/${value}`}
      >
        {value}
      </Link>
    );
  };
  const handleModifySearch = () => {
    navigate('/cmdb/correction-management/inquiry-search');
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleModifySearch,
    },
  ];

  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Rqst Id',
        accessor: 'RQST_ID',
        Cell: ({ value }) => <LinkCellRenderer value={value} />,
      },
      {
        Header: 'Rqst Date		',
        accessor: 'RQST_DT',
      },
      {
        Header: 'Pol Nbr	',
        accessor: 'POL_NBR',
      },
      {
        Header: 'Sufx Code	',
        accessor: 'SUFX_CD',
      },
      {
        Header: 'Status',
        accessor: 'STS_TXT',
      },
      {
        Header: 'Message		',
        accessor: 'MESSAGE_TXT',
      },
      {
        Header: '# Records Extracted',
        accessor: 'RQST_TRAN_CNT',
      },
      {
        Header: 'Rqst User Nm',
        accessor: 'RQST_USER_SHORT_NM',
      },
      {
        Header: 'Request Type',
        accessor: 'RQST_TXT',
      },
      {
        Header: 'Event Type',
        accessor: 'EVENT_TXT',
      },
      {
        Header: 'Last Uptd User Nm',
        accessor: 'LAST_USER_SHORT_NM',
      },
      {
        Header: 'Last Uptd Date',
        accessor: 'LAST_UPDT_DT',
      },
      {
        Header: 'Auto Gen',
        accessor: 'SYS_AUTO_PATH_IND',
      },
    ],

    []
  );
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'inquiry-search.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData:
      data === 'Not available' ||
      data === 'Data not found' ||
      data === 'No records found' ||
      data === null ||
      data === undefined ||
      data === ''
        ? []
        : data,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    customHeaderButtons,
  });

  const handleClick = () => {
    navigate(`${path}/inquiry-search`);
  };
  const handleBackButton = () => {
    navigate(path);
  };
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    marginTop: '0px !important',
                  }}
                >
                  <Heading offset={5}>View Correction Request</Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderTop: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <span>{data?.length || 0} Records</span>
                  <DataTable
                    title="View Correction Request"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Modify Search
                  </Button>
                  <Button
                    onClick={handleBackButton}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(InquirySearchResult);
