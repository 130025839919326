import React, { useEffect } from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';

import { Routes } from './routes';

const themeOverride = {
  theme: {
    colors: {
      // primary
      primary1: '#e45f07',
      primary2: '#FFFFFF',

      // interactive
      interactive1: '#196ECF',
      interactive2: '#0C55B8',
    },
    // fonts: { ...},
  },
};

const theme = createTheme('uhg', themeOverride);
const router = createRouter(Routes);

export const browser = () => {
  return (
    <React.Fragment>
      <AbyssProvider theme={theme} router={router} />;
    </React.Fragment>
  );
};
