import React from 'react';

import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { Table } from '@abyss/web/ui/Table';
import { Text } from '@abyss/web/ui/Text';
import { Label } from '@abyss/web/ui/Label';
import withAuth from '@src/auth/withAuth';

const Help = () => {
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  padding: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading color="$black" offset={5}>
                    Correction Management Help
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Text fontWeight={700}>Table of Contents</Text>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Table of Contents</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Description_*</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Description of Links_*</Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Text fontWeight={700}>Request Workflow_*</Text>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>
                    Custom Request with Itemized Corrections_*
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>
                    Custom Request with Bulk Corrections_*
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Automated Requests_*</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Reconciliation Notes_*</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}> Best Practices _*</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}> Excel Tips _*</Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '40px !important',
                  marginLeft: '22px !important',
                  padding: '0px !important',
                }}
              >
                <Text fontWeight={700}>Description</Text>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    This document will define the workflow processing for the
                    new Claim Accounting Correction Management application. The
                    core of the system is the Correction Request Management Home
                    page. This page is organized into the 4 primary functions
                    you can perform:
                  </Text>
                </Grid.Col>
              </Grid>
              {/* Description of Links Table */}
              <Grid
                css={{
                  margin: '0px !important',
                  marginLeft: '22px !important',
                }}
              >
                <Text fontWeight={700}>Description of Links</Text>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Table.Container title="Manual Static Table">
                    <Table.TableHeader>
                      <Table.TableHeaderRow>
                        <Table.Column>Category</Table.Column>
                        <Table.Column>Link</Table.Column>
                        <Table.Column> Description</Table.Column>
                      </Table.TableHeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                      <Table.Row>
                        <Table.Cell>Create Request</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Policy Structure
                        </Table.Cell>
                        <Table.Cell>
                          Create custom request based on case structure and
                          dates
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Financial Structure
                        </Table.Cell>
                        <Table.Cell>
                          Create custom request based on case structure and
                          financial tags
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Employee
                        </Table.Cell>
                        <Table.Cell>
                          Create custom request based on SSN
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Payment
                        </Table.Cell>
                        <Table.Cell>
                          Create custom request based on check number
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Suspect Error
                        </Table.Cell>
                        <Table.Cell>
                          Create custom request based on analysis of Suspects
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Inquiry/Excel Download</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry Search (All)
                        </Table.Cell>
                        <Table.Cell>
                          Inquire on existing requests based on various
                          selection criteria. Sends user to ‘View Correction
                          Request’ page.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests Available for Correction (Custom)
                        </Table.Cell>
                        <Table.Cell>
                          Inquire on only requests that are available for
                          correction. Sends user to ‘View Correction Request’
                          page. (Status = "Extracted")
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests on Hold (All)
                        </Table.Cell>
                        <Table.Cell>
                          Inquire on requests that are on Hold. This is because
                          another request is locking one or more transactions.
                          Sends user to ‘View Correction Request’ page. (Status
                          = "Hold")
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests in Progress (Custom)
                        </Table.Cell>
                        <Table.Cell>
                          Inquire on requests with itemized corrections already
                          in progress. Sends user to ‘View Correction Request’
                          page. (Status = "Correction In Progress")
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests Requiring Financial Approval (All)
                        </Table.Cell>
                        <Table.Cell>
                          Inquire on requests that are awaiting financial
                          approval. Sends user to ‘View Correction Request’
                          page. (Status = "Waiting for Approval")
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>View Correction Request</Table.Cell>
                        <Table.Cell>
                          This page displays a list of correction requests based
                          on criteria defined above. The user will be able to: -
                          Download a request to excel, which will send the user
                          to the ‘Excel Download Confirmation’ page. - Review
                          detail about a request, which will send the user to
                          the ‘View Correction Request Detail for Request xxx’
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Excel Download Confirmation</Table.Cell>
                        <Table.Cell>
                          This page will identify the excel file which will
                          contain the request.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          View Correction Request Detail for Request xxx{' '}
                        </Table.Cell>
                        <Table.Cell>
                          This page will contain the original request detail
                          information.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Bulk Corrections
                        </Table.Cell>
                        <Table.Cell>
                          Inquire on the selection and update criteria selected
                          for a bulk correction.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Prelim. Fin. Summary (Non-Bulk)
                        </Table.Cell>
                        <Table.Cell>
                          View a preliminary financial summary for itemized
                          corrections. This will show the offset and re-report
                          transactions currently generated for the request.
                          NOTE: The Funding for the re-report transaction is not
                          available until after the request is sent for
                          financial analysis.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Manage Request</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Apply Itemized Corrections
                        </Table.Cell>
                        <Table.Cell>
                          Allow user to apply itemized corrections for a given
                          request. Sends user to "Apply Itemized New Corrections
                          Setup". (Status = "Extracted" or "In Progress")
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Apply Bulk Corrections
                        </Table.Cell>
                        <Table.Cell>
                          Allow user to apply bulk corrections for a given
                          request. Sends user to "Apply Bulk Corrections Setup"
                          page. (Status = "Bulk Correction")
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          Apply Itemized Corrections for Request Id: xxx New
                          Correction Setup
                        </Table.Cell>
                        <Table.Cell>
                          This page is used to identify 1) transactions to be
                          selected from the request and 2) key correction values
                          required to be applied to the selected transactions.
                          This will send the user to the ‘Apply Itemized
                          Corrections’ page.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Apply Itemized Corrections</Table.Cell>
                        <Table.Cell>
                          Submit Chg and Next Page – applies the itemized
                          corrections immediately which will create offset and
                          re-report transactions for the transaction requested.
                          Then, it will automatically bring up the next set of
                          transactions available for correction. Next - which
                          will bring up the next set of transactions available
                          for correction. New Correction - which will return the
                          user to the Setup page to select new
                          criteria/correction values.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          Apply Aggregate Corrections for Request Id: xxx New
                          Bulk Correction Setup
                        </Table.Cell>
                        <Table.Cell>
                          This page is used to identify 1) transactions to be
                          selected from the request and 2) key correction values
                          required to be applied to the selected transactions.
                          ONLY 1 PER REQUEST. Submit Bulk Rqst – Adds the bulk
                          request values to a table for processing by a
                          mainframe process during the day. The mainframe
                          process will create offset and re-report transactions
                          for the transactions requested. Then, it will
                          automatically send the request through financial
                          review, so the the user can review the financial
                          summary.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Finalize Corrections (Non-Bulk) / Deny Request
                        </Table.Cell>
                        <Table.Cell>
                          The page will be used to finalize the corrections for
                          a request. There are 3 options: Submit to Claim Acctg
                          – send the corrections directly to Claim Accounting
                          that evening, without a financial review. Send for
                          Financial Review- send the corrections through a
                          financial impact analysis, so that it can be reviewed
                          by the user and approved if acceptable Deny Request –
                          essentially ‘back out’ the request for any reason.
                          This can happen at any time, until the request has
                          been submitted to Claim Accounting.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Lock Management</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Unlock Claim Transactions(All)
                        </Table.Cell>
                        <Table.Cell>
                          This page will be used to unlock specific claim
                          transactions for a given request based on customer
                          analysis and priorities. Once these transactions are
                          unlocked, they become part of the other request only
                          and if there are no other locked transactions then
                          their request becomes available for correction.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Logs</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Daily Review(counter + date of run)
                        </Table.Cell>
                        <Table.Cell>
                          The page contains a list of automated requests that
                          failed to be processed from the prior day. The counter
                          should always be 0 and the date of run should be the
                          current day. If not, then click on the link to review
                          the file and contact IT if issues.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.TableBody>
                  </Table.Container>
                </Grid.Col>
              </Grid>
              {/* Request Workflow Table */}
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Text fontWeight={700}>Request Workflow</Text>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>
                    The tables below identify the primary paths that a request
                    can take, based on the type of request and the type of
                    corrections required.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>
                    Custom Request with Itemized Corrections
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Table.Container title="Manual Static Table">
                    <Table.TableHeader>
                      <Table.TableHeaderRow>
                        <Table.Column>Step</Table.Column>
                        <Table.Column>Correction Home Link</Table.Column>
                        <Table.Column> Description</Table.Column>
                        <Table.Column> Request Status</Table.Column>
                        <Table.Column> Special Notes</Table.Column>
                      </Table.TableHeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                      <Table.Row>
                        <Table.Cell>1</Table.Cell>
                        <Table.Cell>Create Custom Request</Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Print Confirmation Report.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Policy Structure
                        </Table.Cell>
                        <Table.Cell>By case structure,dates</Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>{' '}
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Financial Structure
                        </Table.Cell>
                        <Table.Cell>
                          By case structure,financial tags
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Employee
                        </Table.Cell>
                        <Table.Cell>By SSN</Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Payment
                        </Table.Cell>
                        <Table.Cell>By Check</Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Suspect Error
                        </Table.Cell>
                        <Table.Cell>
                          By Suspect Criteria(after research on Suspect Inquiry
                          pages)
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>2</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry/Excel Download
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests Available for Correction
                        </Table.Cell>
                        <Table.Cell>
                          All custom requests that are available for correction
                          ("Extracted" from Galaxy).
                        </Table.Cell>
                        <Table.Cell>"Extracted"</Table.Cell>
                        <Table.Cell>
                          Download for further research, especially if applying
                          itemized corrections.
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests in Progress
                        </Table.Cell>
                        <Table.Cell>
                          All custom request with corrections in progress.
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Inquiry Search(All)</Table.Cell>
                        <Table.Cell>
                          At any time, you can use specific search criteria to
                          view all requests
                        </Table.Cell>
                        <Table.Cell>Any Status</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Manage Request
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>3</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Itemized Corrections
                        </Table.Cell>
                        <Table.Cell>
                          Apply different corrections to selected records within
                          the batch.
                        </Table.Cell>
                        <Table.Cell>"In Progress"</Table.Cell>
                        <Table.Cell>Correction applied immediately</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>3A</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Prelim. Fin. Summary *** OPTIONAL STEP ****{' '}
                        </Table.Cell>
                        <Table.Cell>
                          Perform a preliminary review of the financial impact
                          of the corrections prior to finalizing the request.
                        </Table.Cell>
                        <Table.Cell>
                          "In Progress" or "Bulk Correction"
                        </Table.Cell>
                        <Table.Cell>N/A</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>4</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Finalize Corrections
                        </Table.Cell>
                        <Table.Cell>
                          Finalize request once all corrections have been
                          applied. *** A request can be denied at any point
                          until Submitted ***
                        </Table.Cell>
                        <Table.Cell>
                          Based on button: "Financial Review" or "Submitted" or
                          "Denied"
                        </Table.Cell>
                        <Table.Cell>
                          Financial Review – runs 2x a day Submit to C.A. - runs
                          at night Denied - runs at night
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>5</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Requests Requiring Financial Approval
                        </Table.Cell>
                        <Table.Cell>
                          If "Financial Review" was chosen, then determine
                          whether your request is available.
                        </Table.Cell>
                        <Table.Cell>"Awaiting Approval"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>6</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Approve Financial Summary
                        </Table.Cell>
                        <Table.Cell>
                          Review the financial impact of the corrections, and
                          decide whether to approve.
                        </Table.Cell>
                        <Table.Cell>
                          Based on button: "Submitted" or "Denied"
                        </Table.Cell>
                        <Table.Cell>
                          Submit to C.A. - runs at night Denied - runs at night
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>7</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry Search
                        </Table.Cell>
                        <Table.Cell>
                          View that the request was completed the next day based
                          on option chosen.
                        </Table.Cell>
                        <Table.Cell>
                          If submitted, then should be "Closed".
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.TableBody>
                  </Table.Container>
                </Grid.Col>
              </Grid>
              {/* custom request with bulk collection table */}
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '40px !important',
                  marginLeft: '22px !important',
                  padding: '0px !important',
                }}
              >
                <Text fontWeight={700}>
                  Custom Request with Bulk Corrections
                </Text>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Table.Container title="Manual Static Table">
                    <Table.TableHeader>
                      <Table.TableHeaderRow>
                        <Table.Column>Step</Table.Column>
                        <Table.Column>Correction Home Link</Table.Column>
                        <Table.Column> Description</Table.Column>
                        <Table.Column> Request Status</Table.Column>
                        <Table.Column> Special Notes</Table.Column>
                      </Table.TableHeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                      <Table.Row>
                        <Table.Cell>1</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Create Custom Request
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Print Confirmation Report.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Policy Structure
                        </Table.Cell>
                        <Table.Cell>By case structure,dates</Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>{' '}
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Financial Structure
                        </Table.Cell>
                        <Table.Cell>
                          By case structure,financial tags
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Employee
                        </Table.Cell>
                        <Table.Cell>By SSN</Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Payment
                        </Table.Cell>
                        <Table.Cell>By Check</Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>2</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry/Excel Download
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Requests Available for Correction
                        </Table.Cell>
                        <Table.Cell>
                          All custom requests that are available for correction
                          ("Extracted" from Galaxy).
                        </Table.Cell>
                        <Table.Cell>"Extracted"</Table.Cell>
                        <Table.Cell>
                          Download for further research, especially if applying
                          itemized corrections.
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry Search(All)
                        </Table.Cell>
                        <Table.Cell>
                          At any time, you can use specific search criteria to
                          view all requests
                        </Table.Cell>
                        <Table.Cell>Any Status</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Manage Request
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>3</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Bulk Corrections
                        </Table.Cell>
                        <Table.Cell>
                          Apply different corrections to selected records within
                          the batch.
                        </Table.Cell>
                        <Table.Cell>"Bulk Correction"</Table.Cell>
                        <Table.Cell>
                          Correction thru Financial Impact - runs 2x a day
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>4</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Bulk Correction
                        </Table.Cell>
                        <Table.Cell>
                          View the correction you are requesting to ensure data
                          is okay.
                        </Table.Cell>
                        <Table.Cell>"Bulk Correction"</Table.Cell>
                        <Table.Cell>If needed</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>5</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Requests Requiring Financial Approval
                        </Table.Cell>
                        <Table.Cell>
                          Determine whether your request is ready for financial
                          approval.
                        </Table.Cell>
                        <Table.Cell>"Awaiting Approval"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>6</Table.Cell>
                        <Table.Cell>Approve Financial Summary</Table.Cell>
                        <Table.Cell>
                          Review the financial impact of the corrections, and
                          decide whether to approve.
                        </Table.Cell>
                        <Table.Cell>
                          Based on button: "Submitted" or "Denied"
                        </Table.Cell>
                        <Table.Cell>
                          Submit to C.A. - runs at night Denied - runs at night
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>7</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry Search
                        </Table.Cell>
                        <Table.Cell>
                          View that the request was completed the next day based
                          on option chosen.
                        </Table.Cell>
                        <Table.Cell>
                          If submitted, then should be "Closed".
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.TableBody>
                  </Table.Container>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text color="$secondaryDvz2">
                    *** Note: You can create Bulk Corrections after applying
                    Itemized Corrections first.
                  </Text>
                </Grid.Col>
              </Grid>
              {/* Automated Request Table */}
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '40px !important',
                  marginLeft: '22px !important',
                  padding: '0px !important',
                }}
              >
                <Text fontWeight={700}>Automated Requests</Text>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Table.Container title="Manual Static Table">
                    <Table.TableHeader>
                      <Table.TableHeaderRow>
                        <Table.Column>Step</Table.Column>
                        <Table.Column>Correction Home Link</Table.Column>
                        <Table.Column> Description</Table.Column>
                        <Table.Column> Request Status</Table.Column>
                        <Table.Column>Special Notes</Table.Column>
                      </Table.TableHeaderRow>
                    </Table.TableHeader>
                    <Table.TableBody>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          How the Automated Requests are Created
                        </Table.Cell>
                        <Table.Cell>
                          Once a day, the automated requests will be created
                          based on the events listed below. The user will need
                          to view the ‘Daily Log’ to ensure no errors occurred
                          during the run.{' '}
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Suffix Add Event
                        </Table.Cell>
                        <Table.Cell>
                          Create ‘Policy Structure’ request based on new suffix
                          added to FADB via ‘Set Suffix’ or Autofeed.
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Policy Change Event</Table.Cell>
                        <Table.Cell>
                          Create ‘Suspect’ request based on retroactive policy
                          change of default financial info in FADB via ‘Set
                          Policy’ or Autofeed.
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Suffix Change Event
                        </Table.Cell>
                        <Table.Cell>
                          Create ‘Policy Structure’ request based on retroactive
                          suffix change of key financial info in FADB via ‘Set
                          Suffix’ or Autofeed.
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>2</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Log
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Daily Log(Counter + Date)
                        </Table.Cell>
                        <Table.Cell>
                          Make sure that the error count is 0 and the date of
                          run is correct. If there are errors, review and
                          contact Web Admin if necessary.
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Inquiry/Excel Download</Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>3</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          Inquiry Search(All)
                        </Table.Cell>
                        <Table.Cell>
                          View the automated requests that day by checking
                          "AUTOMATED ONLY" and Request Date From = Current Date
                          and Request Date To = Current Date.
                        </Table.Cell>
                        <Table.Cell>"Ready for Extract"</Table.Cell>
                        <Table.Cell>
                          Determine how many requests have been created & View
                          the Suspect Inquiry for Event Types = Sfx Add and
                          Retro Pol Chg
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>4</Table.Cell>
                        <Table.Cell>
                          View Requests Requiring Financial Approval{' '}
                        </Table.Cell>
                        <Table.Cell>
                          Determine whether any automated requests (sys_gen_ind
                          = ‘Y’) are awaiting financial approval.
                        </Table.Cell>
                        <Table.Cell>"Awaiting Approval"</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>5</Table.Cell>
                        <Table.Cell
                          css={{
                            color: '$interactive1',
                          }}
                        >
                          View Requests On Hold
                        </Table.Cell>
                        <Table.Cell>
                          Determine whether any automated requests are on hold
                          (sys_gen_ind = ‘Y’)
                        </Table.Cell>
                        <Table.Cell>‘On Hold’</Table.Cell>
                        <Table.Cell>
                          This occurs when any automated request has at least
                          one transaction that is locked by another request.
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Manage Request</Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>6*</Table.Cell>
                        <Table.Cell>Approve Financial Summary</Table.Cell>
                        <Table.Cell>
                          Review the financial impact of the corrections, and
                          decide whether to approve.
                        </Table.Cell>
                        <Table.Cell>
                          Based on button: "Submitted" or "Denied"
                        </Table.Cell>
                        <Table.Cell>
                          Submit to C.A. - runs at night Denied - runs at night
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>7</Table.Cell>
                        <Table.Cell>Inquiry Search</Table.Cell>
                        <Table.Cell>
                          View that the request was completed the next day based
                          on option chosen.
                        </Table.Cell>
                        <Table.Cell>
                          If submitted, then should be "Closed".
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.TableBody>
                  </Table.Container>
                </Grid.Col>
              </Grid>

              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>Reconcilation Notes</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>The financial assignment default logic.</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>The default categories are:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>SG+A CAP - HMO vs Non-HMO</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>SG+A non-cap</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text> Subline 47</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text> Administaff</Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>SG+A CAP - HMO vs Non-HMO:</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    Capitation transactions that default to fully insured can be
                    identified by product code not equal to HMO (HMx, x
                    representing any value) and fee code equals A, B, M, O, P,
                    S, T, U.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    All other captitaion transaction should be assigned the
                    funding arrangement from FADB.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    Keep in mind that capitation transactions have a default
                    subline value of 'CP' which I believe will be converted to a
                    medical subline for correction processing.
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>SG+A non-cap:</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    These transaction can be identified by subline values
                    beginning with a '9'. All transactions with sublines
                    beginning with 9 will default to a fully insured funding
                    arrangement.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    Combination of FADB's legal entity and funding arrangement
                    of '1' will derive the appropriate company code.
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>Subline 47:</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    This subline is non correctable. These items may be returned
                    on a galaxy request but will be flagged as non-correctable.
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>Administaff Policy 0701648</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    This policy is set up as mmp on FADB with a funding
                    arrangement of '3'. The assignment process will default
                    funding arrangement code to '2'.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    Any corrections on this policy will need to take into
                    consideration that there will be a funding arrangement of
                    '2' in galaxy and a '3' in FADB.
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>Miscellaneous comments</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    Bulk recovery components, the recovery component of the
                    summary check system payment, will not be correctable. Only
                    the bulk recovery detail from TOPS will be correctable.
                  </Text>
                </Grid.Col>
              </Grid>

              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>Best Practices</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •If creating a "Suspect" request, print out the Suspect
                    Inquiry pages first. Once the request is created, the
                    suspects that match will be changed to a status of
                    "extracted" and will no longer be seen on the Suspect
                    Inquiry screens.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •After creating a custom request, print the confirmation
                    page.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •View the request detail. Save a printed copy since this
                    contains the "Subl Amt" (total subline amount) that was
                    extracted.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •Create an excel download which will contain all the detail
                    organized by base transaction (showing correctable record
                    first and all history). Print the confirmation page which
                    will contain the filename that will be created.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •After financial impact analysis, print a copy of the
                    Financial Approval page.
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>Excel Tips</Label>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>•To keep the headings at the top of the page:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>•Select the heading lines</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •Click on Windows / Freeze Panes (from the menu bar)
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •To reset the sort to match the Itemized Corrections page,
                    you can resort in the following manner:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>
                    •Click on Data/Sort from the Menu bar and select the
                    following:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Correctable Ind(Descending)</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text fontWeight={700}>Trans Id (Ascending)</Text>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  marginLeft: '12px !important',
                  padding: '0px !important',
                }}
              >
                <Label>
                  (the non-blue records will match what is on the itemized
                  correction page)
                </Label>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(Help);
