import {
  UPDATE_INQUIRY_SEARCH,
  UPDATE_INQUIRY_SEARCH_SUCCESS,
  UPDATE_INQUIRY_SEARCH_ERROR,
  SET_POLICY_DATA,
  SET_SUFFIX_DATA,
  SET_ACCOUNT_DATA,
  SET_PAYLOAD_DATA,
  UPDATE_INQUIRY_ELEMENT_SEARCH_SEARCH_ERROR,
  UPDATE_INQUIRY_ELEMENT_SEARCH_SUCCESS,
  UPDATE_INQUIRY_ELEMENT_SEARCH,
} from './inquiryConstants';

export function updateInquirySearch(data) {
  return {
    type: UPDATE_INQUIRY_SEARCH,
    data,
  };
}
export function updateInquirySearchSuccess(data) {
  return {
    type: UPDATE_INQUIRY_SEARCH_SUCCESS,
    data,
  };
}
export function updateInquiryElementSearch() {
  return {
    type: UPDATE_INQUIRY_ELEMENT_SEARCH,
  };
}
export function updateInquiryeElementSearchSuccess(data) {
  return {
    type: UPDATE_INQUIRY_ELEMENT_SEARCH_SUCCESS,
    payload: data,
  };
}
export function updateInquiryElementSearchError(error) {
  return {
    type: UPDATE_INQUIRY_ELEMENT_SEARCH_SEARCH_ERROR,
    payload: error,
  };
}
export const setpolicyData = (policy) => ({
  type: SET_POLICY_DATA,
  policy,
});

export const setSuffixData = (suffix) => ({
  type: SET_SUFFIX_DATA,
  suffix,
});

export const setAccountData = (account) => ({
  type: SET_ACCOUNT_DATA,
  account,
});

export function updateInquirySearchError(error) {
  return {
    type: UPDATE_INQUIRY_SEARCH_ERROR,
    error,
  };
}

export const setPayload = (payloads) => ({
  type: SET_PAYLOAD_DATA,
  payloads,
});
