import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
const api = axios.create({
  baseURL: BASE_URL,
});

export function referenceSuffixApi(config) {
  return api.get(`static/suffxDyanamicData/suffixDyanamicData`, config);
}

export function referenceSuffixDynamicApi(config) {
  return api.get(`static/suffxDrpdwnData/suffixDropdwnData`, config);
}
