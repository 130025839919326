import React, { useState } from 'react';

export const SelectableList = ({ items, selectedItems, onSelectItem }) => {
  return (
    <div
      style={{
        height: '80px',
        overflowY: 'auto',
        border: '1px solid grey',
        borderRadius: '2px',
      }}
    >
      <ul>
        {Array.isArray(items) &&
          items.map((item, index) => (
            <li
              key={index}
              onClick={() => onSelectItem(index)}
              style={{
                cursor: 'pointer',
                backgroundColor: selectedItems.includes(index)
                  ? 'grey'
                  : 'white',
                color: selectedItems.includes(index) ? 'white' : 'black',
                paddingLeft: '5px',
              }}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};
