import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import useAuth from '@src/utils/useAuth';

import withAuth from '@src/auth/withAuth';
import axios from 'axios';

import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Footer } from '@src/components/Footer';
import { DateInput } from '@abyss/web/ui/DateInput';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { convertDateFormat } from '@src/utils/convertDateFormat';
import { BASE_URL, API_BASE_URL } from '@src/utils/endpoints';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
import { Alert } from '@abyss/web/ui/Alert';

const InquirySearch = () => {
  const { readOnly, token } = useAuth();
  const { navigate } = useRouter();
  const path = '/cmdb/correction-management';
  const id = 'inquirySearch';
  const [inquirySearchOptions, setInquirySearchOptions]: any = useState([]);

  const [requestDateFrom, setRequestDateFrom] = useState(null);
  const [requestDateTo, setRequestDateTo] = useState(null);
  const [fromDateError, setFromDateError] = useState(null);

  const [requestId, setRequestId] = useState('');
  const [requestType, setRequestType] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [name, setName] = useState('');
  const [requestStatusRadioButtonValue, setRequestStatusRadioButtonValue] =
    useState('ARO');
  const [checkAutomated, setCheckAutomated] = useState(false);

  const [selectedRequestStatus, setSelectedRequestStatus] = useState('');
  const [dropdown, setDropdown] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const date = new Date();

  useEffect(() => {
    if (dropdown?.length > 0) {
      const transformedData1 = dropdown.map((item) => ({
        value: item['STS_CD'],
        label: item['STS_TXT'],
      }));
      setTransformedData(transformedData);
      setSelectedRequestStatus(transformedData1[0].label);
    }
  }, [dropdown]);
  const handleReset = () => {
    setRequestDateFrom(null);
    setRequestDateTo(null);
    setFromDateError(null);
    setRequestId('');
    setRequestType(null);
    setEventType(null);
    setName('');
    setRequestStatusRadioButtonValue('ARO');
    setCheckAutomated(false);
    setSelectedRequestStatus('');
  };
  const handleDynamicDropdown = async () => {
    try {
      const response = await axios.get(`${BASE_URL}statustype/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setDropdown(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);

  const validate = () => {
    setFromDateError('');
    let validFlag = true;
    if (requestDateFrom !== null && requestDateTo !== null) {
      let to = new Date(requestDateFrom);
      let from = new Date(requestDateTo);
      if (to > from) {
        setFromDateError('From Date is not before To Date.');
        validFlag = false;
      }
    }

    return validFlag;
  };

  const handleSubmit = async () => {
    let requestStatus = true;
    if (requestStatusRadioButtonValue === 'Status' && !selectedRequestStatus) {
      setShowAlert(true);
      requestStatus = false;
    }
    const valid = validate();
    if (valid && requestStatus) {
      try {
        const payload = {
          fromRequestDate: requestDateFrom
            ? convertDateFormat(requestDateFrom)
            : null,
          toRequestDate: requestDateTo
            ? convertDateFormat(requestDateTo)
            : null,
          requestUserName: name,
          automated: checkAutomated ? 'Y' : 'N',
          requestStatus:
            requestStatusRadioButtonValue === 'ARO' ||
            requestStatusRadioButtonValue === 'ALL'
              ? requestStatusRadioButtonValue
              : requestStatusRadioButtonValue === 'Status'
              ? selectedRequestStatus
              : '',
          requestType,
          requestID: requestId,
          eventType,
          screenType: 'I',
          pageNumber: '',
          recordsPerPage: '',
        };
        const response = await axios.post(
          `${BASE_URL}cmdb/inquiry/search `,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data);
        if (response.status === 200) {
          navigate(`${path}/inquiry-result`, { state: response.data });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleBackButton = () => {
    navigate(path);
  };

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}cmdb-service/inquirySearchDropdown/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response && response.data) {
        setInquirySearchOptions(response.data);
      } else {
        console.error('No data found');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) fetchDropdownData();
  }, [token]);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    View Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Selection Criteria
                  </Heading>
                  <Flex justify="end">
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{ margin: '0px !important', marginTop: '20px !important' }}
              >
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <DateInput
                    label="Request Date From"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={requestDateFrom}
                    onChange={(date) => setRequestDateFrom(date)}
                    errorMessage={fromDateError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <DateInput
                    label="Request Date To"
                    subText={' '}
                    isClearable={true}
                    isDisabled={readOnly}
                    value={requestDateTo}
                    onChange={(date) => setRequestDateTo(date)}
                    errorMessage={fromDateError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Request ID"
                    value={requestId}
                    onChange={(e) => setRequestId(e.target.value)}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Rqst User Name"
                    value={name}
                    onChange={(e) => {
                      return setName(e.target.value);
                    }}
                    css={{
                      'abyss-text-input:focus': {
                        boxShadow: '#ff4e00 0px 0px 0px 2px !important ',
                      },
                    }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <RadioGroup
                    label="Request Status"
                    onChange={(e) =>
                      setRequestStatusRadioButtonValue(e.target.value)
                    }
                    value={requestStatusRadioButtonValue}
                    isDisabled={readOnly}
                  >
                    <Card
                      css={{ border: '1px solid #A9A9A9', padding: '12px' }}
                    >
                      <RadioGroup.Radio
                        label="Active Requests Only"
                        value="ARO"
                      />
                      <RadioGroup.Radio
                        label="All (Including History)"
                        value="ALL"
                      />
                      <RadioGroup.Radio label="Select Status" value="Status" />
                      <SelectInput
                        label="Select Request Status"
                        hideLabel
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={
                          requestStatusRadioButtonValue === 'Status'
                            ? false
                            : true
                        }
                        value={selectedRequestStatus}
                        onChange={(e) => setSelectedRequestStatus(e)}
                        options={inquirySearchOptions?.requestStatusDropDown?.map(
                          (col) => ({
                            value: col.value,
                            label: col.label,
                          })
                        )}
                      />
                    </Card>
                    {showAlert ? (
                      <Alert
                        variant="error"
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      >
                        Request Status: Select Status option must have a value.
                      </Alert>
                    ) : null}
                  </RadioGroup>
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Request Type"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={requestType}
                    onChange={(value) => setRequestType(value)}
                    options={inquirySearchOptions?.requestTypeDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Event Type"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={eventType}
                    onChange={(value) => setEventType(value)}
                    options={inquirySearchOptions?.eventTypeDropDown?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <Checkbox
                    label="Automated Only"
                    isChecked={checkAutomated}
                    onChange={(e) => {
                      return setCheckAutomated(e.target.checked);
                    }}
                    css={{ 'abyss-checkbox-root': { paddingTop: '2.1rem' } }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Submit
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleBackButton}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(InquirySearch);
