import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
const api = axios.create({
  baseURL: BASE_URL,
});

export function lookUpPolicyApi(policyNumber, config) {
  return api.get(`policy/${policyNumber}`, config);
}

export function updatePolicyApi(policyData, config) {
  return api.post(`policy/savePolicy`, policyData, config);
}

export function referencePolicyApi(config) {
  console.log(config, 'config');
  return api.get(`static/polDyanamicData/policyDyanamicData`, config);
}

export function referencesDynamicApi(config) {
  return api.get(`static/policyDrpdwnData/policy`, config);
}
