import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import { getEnvironment } from '@src/utils/environmentUtil';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { BASE_URL } from '@src/utils/endpoints';
import { DateInput } from '@abyss/web/ui/DateInput';
const HmoAcct = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      hmoRowNbr: payload['HMO_ROW_NBR'],
      hmoBeginDt: payload['HMOBEGINDT'],
      hmoEndDt: payload['HMOENDDT'],
      hmoLegalEntNbr: payload['HMO_LEGAL_ENT_NBR'],
      hmoLegalEnt: payload['hmoLegalEnt'],
      hmoMktName: payload['HMO_MKT_NAME'],
      hmoMktNbr: payload['HMO_MKT_NBR'],
      hmoProdTyp: payload['HMO_PROD_TYP'],
      hmoAcctDiv: payload['HMO_ACCT_DIV'],
      lstUpdtDt: payload['LSTUPDTDT'],
      lstUpdtTm: payload['LST_UPDT_TM'],
      lstUpdtId: payload['LST_UPDT_ID'],
      updtInd: payload['UPDT_IND'],
      lglEntyGlbuNbr: payload['LGL_ENTY_GLBU_NBR'],
      glLocCd: payload['GL_LOC_CD'],
      hmoPct: payload['HMO_PCT'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/hmoacct/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [hmoRowNbr, setHmoRowNbr] = useState('');
  const [hmoBeginDt, sethmoBeginDt] = useState(null);
  const [hmoEndDt, setHmoEndDt] = useState(null);
  const [hmoLegalEntNbr, setHmoLegalEntNbr] = useState('');
  const [hmoLegalEnt, setHmoLegalEnt] = useState('');
  const [hmoMktName, sethmoMktName] = useState('');
  const [hmoMktNbr, setHmoMktNbr] = useState('');
  const [hmoProdTyp, setHmoProdTyp] = useState('');
  const [hmoAcctDiv, setHmoAcctDiv] = useState('');
  const [lglEntyGlbuNbr, setlglEntyGlbuNbr] = useState('');
  const [glLocCd, setGlLocCd] = useState('');
  const [hmoPct, setHmoPct] = useState('');
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRows(row['original']);
      console.log('deleted row: ', row);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setHideAdd(true);
    setUpdateFlag(true);
    setFlag(true);
    setUpdateRow(row);
    setHmoRowNbr(row?.['HMO_ROW_NBR']);
    sethmoBeginDt(row?.['HMOBEGINDT']);
    setHmoEndDt(row?.['HMOENDDT']);
    setHmoLegalEntNbr(row?.['HMO_LEGAL_ENT_NBR']);
    setHmoLegalEnt(row?.['HMO_LEGAL_ENT']);
    sethmoMktName(row?.['HMO_MKT_NAME']);
    setHmoMktNbr(row?.['HMO_MKT_NBR']);
    setHmoProdTyp(row?.['HMO_PROD_TYP']);
    setHmoAcctDiv(row?.['HMO_ACCT_DIV']);
    setlglEntyGlbuNbr(row?.['LGL_ENTY_GLBU_NBR']);
    setGlLocCd(row?.['GL_LOC_CD']);
    setHmoPct(row?.['HMO_PCT']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
      console.log('upds row: ', row);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const addRow = () => {
    setHideUpdate(true);
    setFlag(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const save = async () => {
    setHideUpdate(false);
    setFlag(false);
    const now = new Date();
    let payload = {
      hmoRowNbr: hmoRowNbr,
      hmoBeginDt: hmoBeginDt,
      hmoEndDt: hmoEndDt,
      hmoLegalEntNbr: hmoLegalEntNbr,
      hmoLegalEnt: hmoLegalEnt,
      hmoMktName: hmoMktName,
      hmoMktNbr: hmoMktNbr,
      hmoProdTyp: hmoProdTyp,
      hmoAcctDiv: hmoAcctDiv,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: userName,
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
      lglEntyGlbuNbr: lglEntyGlbuNbr,
      glLocCd: glLocCd,
      hmoPct: hmoPct,
    };
    setHmoRowNbr('');
    sethmoBeginDt(null);
    setHmoEndDt(null);
    setHmoLegalEntNbr('');
    setHmoLegalEnt('');
    sethmoMktName('');
    setHmoMktNbr('');
    setHmoProdTyp('');
    setHmoAcctDiv('');
    setlglEntyGlbuNbr('');
    setGlLocCd('');
    setHmoPct('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/hmoacct/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    setHideAdd(false);
    setFlag(false);
    const now = new Date();
    let payload = {
      newRecord: {
        hmoRowNbr: hmoRowNbr,
        hmoBeginDt: hmoBeginDt,
        hmoEndDt: hmoEndDt,
        hmoLegalEntNbr: hmoLegalEntNbr,
        hmoLegalEnt: hmoLegalEnt,
        hmoMktName: hmoMktName,
        hmoMktNbr: hmoMktNbr,
        hmoProdTyp: hmoProdTyp,
        hmoAcctDiv: hmoAcctDiv,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: userName,
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
        lglEntyGlbuNbr: lglEntyGlbuNbr,
        glLocCd: glLocCd,
        hmoPct: hmoPct,
        // hideRecord: true
      },
      oldRecord: {
        hmoRowNbr: updateRow['HMO_ROW_NBR'],
        hmoBeginDt: updateRow['HMO_BEGIN_DT'],
        hmoEndDt: updateRow['HMO_END_DT'],
        hmoLegalEntNbr: updateRow['HMO_LEGAL_ENT_NBR'],
        hmoLegalEnt: updateRow['HMO_LEGAL_ENT'],
        hmoMktName: updateRow['HMO_MKT_NAME'],
        hmoMktNbr: updateRow['HMO_MKT_NBR'],
        hmoProdTyp: updateRow['HMO_PROD_TYP'],
        hmoAcctDiv: updateRow['HMO_ACCT_DIV'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: 'Y',
        lglEntyGlbuNbr: updateRow['LGL_ENTY_GLBU_NBR'],
        glLocCd: updateRow['GL_LOC_CD'],
        hmoPct: updateRow['HMO_PCT'],
      },
    };
    setHmoRowNbr('');
    sethmoBeginDt(null);
    setHmoEndDt(null);
    setHmoLegalEntNbr('');
    setHmoLegalEnt('');
    sethmoMktName('');
    setHmoMktNbr('');
    setHmoProdTyp('');
    setHmoAcctDiv('');
    setlglEntyGlbuNbr('');
    setGlLocCd('');
    setHmoPct('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/hmoacct/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setHmoRowNbr('');
    sethmoBeginDt(null);
    setHmoEndDt(null);
    setHmoLegalEntNbr('');
    setHmoLegalEnt('');
    sethmoMktName('');
    setHmoMktNbr('');
    setHmoProdTyp('');
    setHmoAcctDiv('');
    setlglEntyGlbuNbr('');
    setGlLocCd('');
    setHmoPct('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>

              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />
              <Button variant="tertiary" onClick={addRow} isDisabled={hideAdd}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_ROW_NBR"
                        value={hmoRowNbr}
                        onChange={(e) => setHmoRowNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <DateInput
                        label="HMO_BEGIN_DT"
                        value={hmoBeginDt}
                        onChange={(e) => sethmoBeginDt(e)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <DateInput
                        label="HMO_END_DT"
                        value={hmoEndDt}
                        onChange={(e) => setHmoEndDt(e)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_LEGAL_ENT_NBR"
                        value={hmoLegalEntNbr}
                        onChange={(e) => setHmoLegalEntNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_LEGAL_ENT"
                        value={hmoLegalEnt}
                        onChange={(e) => setHmoLegalEnt(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_MKT_NAME"
                        value={hmoMktName}
                        onChange={(e) => sethmoMktName(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_MKT_NBR"
                        value={hmoMktNbr}
                        onChange={(e) => setHmoMktNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_PROD_TYP"
                        value={hmoProdTyp}
                        onChange={(e) => setHmoProdTyp(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_ACCT_DIV"
                        value={hmoAcctDiv}
                        onChange={(e) => setHmoAcctDiv(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="LGL_ENTY_GLBU_NBR"
                        value={lglEntyGlbuNbr}
                        onChange={(e) => setlglEntyGlbuNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GL_LOC_CD"
                        value={glLocCd}
                        onChange={(e) => setGlLocCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_PCT"
                        value={hmoPct}
                        onChange={(e) => setHmoPct(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={updateFlag ? update : save}>Save</Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(HmoAcct);
