import React, { useEffect, useState } from 'react';

import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { Sidebar } from '@src/components/Sidebar';
import { PageHeader } from '@src/components';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import PolicyLevel from './PolicyLevel';
import SuffixLevel from './SuffixLevel';

import { useSelector } from 'react-redux';
import withAuth from '@src/auth/withAuth';

const AutomatedFeedPolicyDetails = () => {
  const { readOnly } = useAuth();
  const { navigate } = useRouter();
  const [policyDetailsData, setPolicyDetailsData] = useState([]);

  const policyDetails = useSelector(
    (state) => state.automatedFeedSearch.policyData
  );
  const payloadData = useSelector((state) => state.automatedFeedSearch.data);
  const { caseInstall } = payloadData && payloadData;

  useEffect(() => {
    console.log('_______________useEffect called', policyDetailsData);
    if (policyDetails) {
      setPolicyDetailsData(policyDetails);
    }
  }, [policyDetails]);
  console.log('_______________useEffect end', policyDetailsData);

  const handleButtonClick = () => {
    navigate('/fadb/inquiry/automated-feeds/automated-feeds-search');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>
                    Transaction Mainanance Details - {caseInstall}
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>FADB Information</Heading>
                </Grid.Col>
              </Grid>
              <Box color="$white">
                {!policyDetailsData && !payloadData ? (
                  <div>Loading ....</div>
                ) : (
                  <Grid>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <SuffixLevel
                        policyDetails={policyDetailsData}
                        caseInstSrc={caseInstall}
                      />
                      <PolicyLevel policyDetails={policyDetailsData} />
                    </Grid.Col>
                  </Grid>
                )}
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={handleButtonClick}
                      isDisabled={readOnly}
                    >
                      Return To List
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(AutomatedFeedPolicyDetails);
