import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import { useDispatch } from 'react-redux';
import {
  referencePolicyApi,
  referencesDynamicApi,
} from '@src/redux/api/FADB/policy/policyApi';
import {
  fetchLegalEntitiesSuccess,
  fetchLegalEntitiesFailure,
  fetchDynamicDropdownSuccess,
  fetchDynamicDropdownFailure,
} from '../../redux/action/FADB/policy/policyActions';
const Suffix = () => {
  const { readOnly, token } = useAuth();
  const dispatch = useDispatch();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [policyNumber, setPolicyNumber] = useState('');
  const [data, setData] = useState('');
  const [errMsg, setErrMsg] = useState({ content: '', visible: false });
  const [showSuffixSection, setShowSuffixSection] = useState(false);
  const [selectedErisa, setSelectedErisa] = useState([
    {
      id: '1',
      label: 'Compliant',
      value: 'E',
    },
    {
      id: '2',
      label: 'Non Compliant',
      value: 'N',
    },
  ]);
  const [selectedTypeOfEffDate, setSelectedTypeOfEffDate] = useState([]);
  const [selectedPolicyType, setSelectedPolicyType] = useState([]);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState([]);
  const [selectedCaseInstallation, setSelectedCaseInstallation] = useState([]);
  const [selectedGLReinsurance, setSelectedGLReinsurance] = useState([]);
  const [selectedSharedArrangement, setselectedSharedArrangement] = useState(
    []
  );
  const [selectedFundingCode, setSelectedFundingCode] = useState([]);
  const [selectedBankCode, setSelectedBankCode] = useState([]);
  const [defaultLegalEntityCode, setDefaultLegalEntityCode] = useState('');
  const [defaultGLReinsurance, setDefaultGLReinsurance] = useState('');
  const [defaultOutOfLegalEntity, setDefaultOutOfLegalEntity] = useState('');
  const [selectedRenewalType, setSelectedRenewalType] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [selectedRenewalMonth, setSelectedRenewalMonth] = useState([]);
  const { navigate } = useRouter();
  const handleChange = (e) => {
    setPolicyNumber(e.target.value.toUpperCase());
  };
  const validatePolicyNumber = () => {
    setErrMsg({ ...errMsg, ...{ content: '', visible: false } });
    if (!policyNumber || policyNumber.trim() === '') {
      setErrMsg({
        ...errMsg,
        ...{ content: 'Policy number is a required field.', visible: true },
      });
    } else if (policyNumber.trim().length > 7) {
      setErrMsg({
        ...errMsg,
        ...{
          content: 'Please enter a 7 byte value for Policy Number.',
          visible: true,
        },
      });
    }
  };
  const convertDate = (dateStrr) => {
    if (!dateStrr.includes('-')) return dateStrr;
    const [year, month, day] = dateStrr.split('-');
    return `${month}/${day}/${year}`;
  };
  const handleSubtmit = (e) => {
    console.log('handleSubmit----', e);
    setErrMsg({ ...errMsg, ...{ content: '', visible: false } });
    validatePolicyNumber();
    e.preventDefault();
    if (!errMsg.visible) {
      axios
        .get(`${BASE_URL}policy/${policyNumber.toUpperCase()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setData(response.data);
          if (response.data === 'Data Not Found') {
            setErrMsg({
              ...errMsg,
              ...{ content: 'Policy not found. Please check.', visible: true },
            });
            return;
          }
          if (response.status === 200) {
            let policyData = response?.data?.[0];
            let payload = {
              disabledFlag: false,
              disabledDropdown: true,
              actPolNbr: policyData?.POL_NBR,
              policyNumber: policyData?.POL_NBR,
              polName: policyData?.POLHLDR_NM,
              typeofeffectiveDate: policyData?.SRVC_ISS_DT_RUL_CD,
              effectiveDate: convertDate(policyData?.POL_EFF_DT),
              caseinstallationSource: policyData?.ADMIN_SYS_SRC_CD,
              policytype: policyData?.RR_TYP_CD,
              erisacode:
                policyData?.ERISA_CD === 'E' ? policyData?.ERISA_CD : 'N',
              attrEffectiveDate: convertDate(policyData?.POL_ATT_EFF_DT),
              deafultlegalEntityCode: policyData?.LEG_ENTY_CD,
              defaultglreinsurance: policyData?.GL_REINS_CD.trim(),
              sharedArrangement: policyData?.SHR_ARNG_CD,
              fundingEffectiveDate: convertDate(policyData?.POL_FNDNG_EFF_DT),
              fundingCode: policyData?.FUND_ARNG_CD,
              bankCode: policyData?.BNK_CD,
              companyCode: policyData?.CO_CD,
              typeofRenewal: policyData?.RENEWAL_CD_TYP,
              firstRenewalMonth: policyData?.REN_MO_NBR?.trim(),
              firstRenewalDay: policyData?.REN_DAY_NBR?.trim(),
              // lastChange: policyData?.POL_CHG_DT,
              // lastChangeBy: msid,
              // typeftdtRule: typeftdtRule,
              // userSortName: msid,
              cancelationDate: convertDate(policyData?.POL_CANC_DT),
            };
            navigate('/fadb/suffix/lookup', {
              state: {
                data: payload,
                selectedTypeOfEffDate: selectedTypeOfEffDate,
                selectedPolicyType: selectedPolicyType,
                selectedCaseInstallation: selectedCaseInstallation,
                selectedErisa: selectedErisa,
                selectedLegalEntity: selectedLegalEntity,
                policyEntId: policyData?.['POL_ENT_ID'],
                selectedGLReinsurance: selectedGLReinsurance,
                selectedFundingCode: selectedFundingCode,
                selectedBankCode: selectedBankCode,
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleFetchEntity = () => {
    referencePolicyApi(config)
      .then((response) => {
        setSelectedLegalEntity(response.data.legalEntity);
        setselectedSharedArrangement(response.data.shrdArrng);
        dispatch(fetchLegalEntitiesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchLegalEntitiesFailure(error));
      });
  };
  const handleDynamicDropdown = () => {
    referencesDynamicApi(config)
      .then((response) => {
        setSelectedFundingCode(response.data.fndCd);
        setSelectedBankCode(response.data.bankCd);
        setSelectedTypeOfEffDate(response.data.typEffDt);
        setSelectedPolicyType(response.data.polTyp);
        setSelectedCaseInstallation(response.data.insTyp);
        setSelectedGLReinsurance(response.data.reInsTyp);
        setDefaultGLReinsurance('');
        setSelectedRenewalType(response.data.renewalType);
        setSelectedRenewalMonth(response.data.renewalMnth);
        setSelectedDay(response.data.day);
        dispatch(fetchDynamicDropdownSuccess(response.data));
        dispatch(fetchDynamicDropdownSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDynamicDropdownFailure(error));
      });
  };
  useEffect(() => {
    if (token) {
      handleFetchEntity();
      handleDynamicDropdown();
    }
  }, [token]);
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Suffix Maintenance</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Policy Number"
                      value={policyNumber}
                      onChange={handleChange}
                      errorMessage={errMsg.content}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <Button
                      css={{
                        'abyss-button-root': { marginTop: '25px !important' },
                      }}
                      onClick={handleSubtmit}
                      isDisabled={readOnly}
                    >
                      Look Up
                    </Button>
                  </Grid.Col>
                </>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(Suffix);
