import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { constantColumns } from './column-constants';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Navigate } from 'react-router-dom';
import { DateInput } from '@abyss/web/ui/DateInput';
import { getEnvironment } from '@src/utils/environmentUtil';
const State = () => {
  const [stCd, setStCd] = useState([]);
  const [glLocNbr, setGlLocNbr] = useState([]);
  const [medSurchrgInd, setMedSurchrgInd] = useState([]);
  const [stAbbrCd, setAbbrCd] = useState([]);
  const [stCdSelected, setStCdSelected] = useState([]);
  const [glLocNbrSelected, setGlLocNbrSelected] = useState([]);
  const [medSurchrgIndSelected, setMedSurchrgIndSelected] = useState([]);
  const [stAbbrCdSelected, setAbbrCdSelected] = useState([]);
  const [payloadText, setPayloadText] = useState('');
  const [stCdForm, setStCdForm] = useState('');
  const [glLocNbrForm, setGlLocNbrForm] = useState('');
  const [effDt, setEffDt] = useState(null);
  const [medSurchrgIndForm, setMedSurchrgIndForm] = useState('');
  const [stNm, setStNm] = useState('');
  const [stAbbrCdForm, setAbbrCdForm] = useState('');
  const [uhicRtAH, setUhicRtAH] = useState('');
  const [uhicRtLf, setUhicRtLf] = useState('');
  const [uhicRtMeds, setUhicMeds] = useState('');
  const [uhicRt4, setUhicRt4] = useState('');
  const [uhicRt5, setUhicRt5] = useState('');
  const [hpTaxRtAh, setHpTaxRtAh] = useState('');
  const [hpTaxRtLf, setHpTaxRtLf] = useState('');
  const [hpTaxRt3, setHpTaxRt3] = useState('');
  const [hpTaxRt4, setHpTaxRt4] = useState('');
  const [hpTaxRt5, setHpTaxRt5] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateRow, setUpdateRow] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [text, setText] = useState('');
  const [json, setJson] = useState({});
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const data = [];
  useEffect(() => {
    if (Object.keys(json).length > 0) {
      let glLocNbr1 = json?.['getGlLocNbrList'].map((item) => ({
        value: item,
        label: item,
      }));
      setGlLocNbr(glLocNbr1);
      let medSurchrgInd1 = json?.['getMedSurchrgIndList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMedSurchrgInd(medSurchrgInd1);
      let stAbbrCd1 = json?.['getStAbbrCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setAbbrCd(stAbbrCd1);
      let stCdList1 = json?.['stCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setStCd(stCdList1);
    }
  }, [json]);
  const handleDynamicDropdown = async () => {
    try {
      let payload = location?.state?.database;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/state/getDropDowns`,
        payload,
        config
      );
      if (response.status === 200) {
        setJson(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  useEffect(() => {
    let text1 =
      (stCdSelected?.length > 0
        ? 'ST_CD - ' + stCdSelected.toString() + '\n'
        : '') +
      (glLocNbrSelected?.length > 0
        ? 'GL_LOC_NBR - ' + glLocNbrSelected.toString() + '\n'
        : '') +
      (medSurchrgIndSelected?.length > 0
        ? 'MED_SURCHRG_IND - ' + medSurchrgIndSelected.toString() + '\n'
        : '') +
      (stAbbrCdSelected?.length > 0
        ? 'ST_ABBR_CD - ' + stAbbrCdSelected.toString() + '\n'
        : '');
    let payload =
      (stCdSelected?.length > 0
        ? 'ST_CD IN (' + stCdSelected.toString() + ') AND'
        : '') +
      (glLocNbrSelected?.length > 0
        ? 'GL_LOC_NBR IN (' + glLocNbrSelected.toString() + ') AND'
        : '') +
      (medSurchrgIndSelected?.length > 0
        ? 'MED_SURCHRG_IND IN (' +
          medSurchrgIndSelected.map((item) => `'${item}'`).join(',') +
          ') AND'
        : '') +
      (stAbbrCdSelected?.length > 0
        ? 'ST_ABBR_CD IN (' +
          stAbbrCdSelected.map((item) => `'${item}'`).join(',') +
          ') AND'
        : '');
    setPayloadText(payload);
    setText(text1);
  }, [stAbbrCdSelected, medSurchrgIndSelected, stCdSelected, glLocNbrSelected]);
  //get column definition
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setHideAdd(true);
    setUpdateRow(row);
    setStCdForm(row['ST_CD']);
    setGlLocNbrForm(row['GL_LOC_NBR']);
    setEffDt(row['EFFDT']);
    setMedSurchrgIndForm(row['MED_SURCHRG_IND']);
    setStNm(row['ST_NM']);
    setAbbrCdForm(row['ST_ABBR_CD']);
    setUhicRtAH(row['UHIC_TAX_RT_AH']);
    setUhicRtLf(row['"UHIC_TAX_RT_LF']);
    setUhicMeds(row['UHIC_TAX_RT_MEDSUP']);
    setUhicRt4(row['UHIC_TAX_RT4']);
    setUhicRt5(row['UHIC_TAX_RT5']);
    setHpTaxRtAh(row['HP_TAX_RT_AH']);
    setHpTaxRtLf(row['HP_TAX_RT_LF']);
    setHpTaxRt3(row['HP_TAX_RT_RT3']);
    setHpTaxRt4(row['HP_TAX_RT_RT4']);
    setHpTaxRt5(row['HP_TAX_RT_RT5']);
  };
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      stCd: payload['ST_CD'],
      glLocNbr: payload['GL_LOC_NBR'],
      effDt: payload['EFFDT'],
      medSurchrgInd: payload['MED_SURCHRG_IND'],
      stNm: payload['ST_NM'],
      stAbbrCd: payload['ST_ABBR_CD'],
      uhicTaxRtAh: payload['UHIC_TAX_RT_AH'],
      uhicTaxRtLf: payload['UHIC_TAX_RT_LF'],
      uhicTaxRtMedsup: payload['UHIC_TAX_RT_MEDSUP'],
      uhicTaxRt4: payload['UHIC_TAX_RT4'],
      uhicTaxRt5: payload['UHIC_TAX_RT5'],
      hpTaxRtAh: payload['HP_TAX_RT_AH'],
      hpTaxRtLf: payload['HP_TAX_RT_LF'],
      hpTaxRtRt3: payload['HP_TAX_RT_RT3'],
      hpTaxRtRt4: payload['HP_TAX_RT_RT4'],
      hpTaxRtRt5: payload['HP_TAX_RT_RT5'],
      lstUpdtDt: payload['LSTUPDTDT'],
      lstUpdtTm: payload['LST_UPDT_TM'],
      lstUpdtId: payload['LST_UPDT_ID'],
      updtInd: payload['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/state/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        getTableData1();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRows(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: data,
    initialColumns: columns,
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const clear = () => {
    setAbbrCdSelected([]);
    setGlLocNbrSelected([]);
    setStCdSelected([]);
    setMedSurchrgIndSelected([]);
    setPayloadText('');
    setText('');
  };
  const setData = async (tableData) => {
    let columnKeys = [];
    let columns = {};
    if (tableData?.length > 0) {
      columnKeys = Object.keys(tableData?.[0]);
      columns = columnKeys.map((key, index) => ({
        Header: key,
        accessor: key,
      }));
    }
    if (Array.isArray(tableData)) {
      getTableState.setColumns(columns, true);
      getTableState.setData(tableData, true);
    }
  };
  const onLookup = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/state/getStateList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/state/getStateList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
        handleDynamicDropdown();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const handleClick = () => {
    navigate(-1);
  };
  const update = async () => {
    const now = new Date();
    setHideAdd(false);
    setFlag(false);
    let payload = {
      oldRecord: {
        stCd: updateRow['ST_CD'],
        glLocNbr: updateRow['GL_LOC_NBR'],
        effDt: updateRow['EFFDT'],
        medSurchrgInd: updateRow['MED_SURCHRG_IND'],
        stNm: updateRow['ST_NM'],
        stAbbrCd: updateRow['ST_ABBR_CD'],
        uhicTaxRtAh: updateRow['UHIC_TAX_RT_AH'],
        uhicTaxRtLf: updateRow['UHIC_TAX_RT_LF'],
        uhicTaxRtMedsup: updateRow['UHIC_TAX_RT_MEDSUP'],
        uhicTaxRt4: updateRow['UHIC_TAX_RT4'],
        uhicTaxRt5: updateRow['UHIC_TAX_RT5'],
        hpTaxRtAh: updateRow['HP_TAX_RT_AH'],
        hpTaxRtLf: updateRow['HP_TAX_RT_LF'],
        hpTaxRtRt3: updateRow['HP_TAX_RT_RT3'],
        hpTaxRtRt4: updateRow['HP_TAX_RT_RT4'],
        hpTaxRtRt5: updateRow['HP_TAX_RT_RT5'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
      newRecord: {
        stCd: stCdForm,
        glLocNbr: glLocNbrForm,
        effDt: effDt,
        medSurchrgInd: medSurchrgIndForm,
        stNm: stNm,
        stAbbrCd: stAbbrCdForm,
        uhicTaxRtAh: uhicRtAH,
        uhicTaxRtLf: uhicRtLf,
        uhicTaxRtMedsup: uhicRtMeds,
        uhicTaxRt4: uhicRt4,
        uhicTaxRt5: uhicRt5,
        hpTaxRtAh: hpTaxRtAh,
        hpTaxRtLf: hpTaxRtLf,
        hpTaxRtRt3: hpTaxRt3,
        hpTaxRtRt4: hpTaxRt4,
        hpTaxRtRt5: hpTaxRt5,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: userName,
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
    };
    setStCdForm('');
    setGlLocNbrForm('');
    setEffDt(null);
    setMedSurchrgIndForm('');
    setStNm('');
    setAbbrCdForm('');
    setUhicRtAH('');
    setUhicRtLf('');
    setUhicMeds('');
    setUhicRt4('');
    setUhicRt5('');
    setHpTaxRtAh('');
    setHpTaxRtLf('');
    setHpTaxRt3('');
    setHpTaxRt4('');
    setHpTaxRt5('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/state/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        getTableData1();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const save = async () => {
    setHideUpdate(false);
    const now = new Date();
    setFlag(false);
    let payload = {
      stCd: stCdForm,
      glLocNbr: glLocNbrForm,
      effDt: effDt,
      medSurchrgInd: medSurchrgIndForm,
      stNm: stNm,
      stAbbrCd: stAbbrCdForm,
      uhicTaxRtAh: uhicRtAH,
      uhicTaxRtLf: uhicRtLf,
      uhicTaxRtMedsup: uhicRtMeds,
      uhicTaxRt4: uhicRt4,
      uhicTaxRt5: uhicRt5,
      hpTaxRtAh: hpTaxRtAh,
      hpTaxRtLf: hpTaxRtLf,
      hpTaxRtRt3: hpTaxRt3,
      hpTaxRtRt4: hpTaxRt4,
      hpTaxRtRt5: hpTaxRt5,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: userName,
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setStCdForm('');
    setGlLocNbrForm('');
    setEffDt(null);
    setMedSurchrgIndForm('');
    setStNm('');
    setAbbrCdForm('');
    setUhicRtAH('');
    setUhicRtLf('');
    setUhicMeds('');
    setUhicRt4('');
    setUhicRt5('');
    setHpTaxRtAh('');
    setHpTaxRtLf('');
    setHpTaxRt3('');
    setHpTaxRt4('');
    setHpTaxRt5('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/state/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        getTableData1();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setStCdForm('');
    setGlLocNbrForm('');
    setEffDt(null);
    setMedSurchrgIndForm('');
    setStNm('');
    setAbbrCdForm('');
    setUhicRtAH('');
    setUhicRtLf('');
    setUhicMeds('');
    setUhicRt4('');
    setUhicRt5('');
    setHpTaxRtAh('');
    setHpTaxRtLf('');
    setHpTaxRt3('');
    setHpTaxRt4('');
    setHpTaxRt5('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '0px',
                  marginBottom: '14px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="ST_CD"
                    options={stCd}
                    placeholder="Select"
                    onChange={(e) => setStCdSelected(e)}
                    value={stCdSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="GL_LOC_NBR"
                    options={glLocNbr}
                    placeholder="Select"
                    onChange={(e) => setGlLocNbrSelected(e)}
                    value={glLocNbrSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="MED_SURCHRG_IND"
                    options={medSurchrgInd}
                    placeholder="Select"
                    onChange={(e) => setMedSurchrgIndSelected(e)}
                    value={medSurchrgIndSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="ST_ABBR_CD"
                    options={stAbbrCd}
                    placeholder="Select"
                    onChange={(e) => setAbbrCdSelected(e)}
                    value={stAbbrCdSelected}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <Button css={{ marginTop: '25px' }} onClick={onLookup}>
                    Lookup
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 10 }}>
                  <TextInputArea value={text} rows={5} />
                </Grid.Col>
                <Grid.Col span={{ xs: 2 }}>
                  <Button css={{ marginTop: '15px' }} onClick={clear}>
                    Clear
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid css={{ marginTop: '15px', marginLeft: '0px' }}>
                <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                  Database:
                </Text>
                <span>
                  {' '}
                  {getEnvironment()} - {location?.state?.database}
                </span>
                <Text
                  css={{
                    fontWeight: 600,
                    fontSize: '18px',
                    marginLeft: '20px',
                  }}
                >
                  Table:
                </Text>
                <span>{location?.state?.systemSelectedValue}</span>
                <br />
                <span style={{ marginLeft: '12px' }}>
                  {location?.state?.data?.length} Records
                </span>
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={hideAdd}
                  css={{ marginTop: '-10px' }}
                >
                  + Add Row
                </Button>
                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ST_CD"
                          value={stCdForm}
                          onChange={(e) => setStCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="GL_LOC_NBR"
                          value={glLocNbrForm}
                          onChange={(e) => setGlLocNbrForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <DateInput
                          label="EFF_DT"
                          value={effDt}
                          onChange={(e) => setEffDt(e)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="MED_SURCHRG_IND"
                          value={medSurchrgIndForm}
                          onChange={(e) => setMedSurchrgIndForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ST_NM"
                          value={stNm}
                          onChange={(e) => setStNm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ST_ABBR_CD"
                          value={stAbbrCdForm}
                          onChange={(e) => setAbbrCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT_AH"
                          value={uhicRtAH}
                          onChange={(e) => setUhicRtAH(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT_LF"
                          value={uhicRtLf}
                          onChange={(e) => setUhicRtLf(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT_MEDSUP"
                          value={uhicRtMeds}
                          onChange={(e) => setUhicMeds(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT4"
                          value={uhicRt4}
                          onChange={(e) => setUhicRt4(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="UHIC_TAX_RT5"
                          value={uhicRt5}
                          onChange={(e) => setUhicRt5(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_AH"
                          value={hpTaxRtAh}
                          onChange={(e) => setHpTaxRtAh(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_LF"
                          value={hpTaxRtLf}
                          onChange={(e) => setHpTaxRtLf(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_RT3"
                          value={hpTaxRt3}
                          onChange={(e) => setHpTaxRt3(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_RT4"
                          value={hpTaxRt4}
                          onChange={(e) => setHpTaxRt4(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HP_TAX_RT_RT5"
                          value={hpTaxRt5}
                          onChange={(e) => setHpTaxRt5(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button onClick={updateFlag ? update : save}>
                          Save
                        </Button>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button onClick={close}>Cancel</Button>
                      </Grid.Col>
                    </Grid>
                  </>
                )}
              </Grid>
              <span style={{ marginLeft: '0px' }}>
                {location?.state?.data?.length || 0} Records
              </span>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default State;
