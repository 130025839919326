import React, { useEffect, useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { DateInput } from '@abyss/web/ui/DateInput';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';

const PolicyStructure = () => {
  const { readOnly, msid, token } = useAuth();
  const { navigate } = useRouter();
  const [options, setOptions]: any = useState([]);
  const inquirySearchId = 'cmdbPolicyStructure';
  const date = new Date();

  const years = [];
  for (let year = 1990; year <= 2029; year++) {
    years.push({ id: year, value: year, label: year.toString() });
  }

  const [formValues, setFormValues] = useState({
    bookMonthFrom: '',
    bookMonthTo: '',
    bookYearFrom: '',
    bookYearTo: '',
    policyNumber: '',
    policyAccountNumber: '',
    suffixCode: '',
    issueDateFrom: '',
    issueDateTo: '',
    chargeDateFrom: '',
    chargeDateTo: '',
    transferDateFrom: '',
    transferDateTo: '',
  });

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const regEx = /^\d{2}[A-Za-z0-9]\d{4}$/;
  const checkPolicyAccount = (polAcc) => {
    const pol: RegExp = /^[0-9]+$/;
    if (polAcc.match(pol)) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (value, fieldId) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [fieldId]: value,
    }));
  };

  const handleValidation = () => {
    const errors = {};
    let isValid = true;

    if (!formValues.bookMonthFrom) {
      errors.bookMonthFrom = 'Book From Month is Required.';
      isValid = false;
    }

    if (!formValues.bookMonthTo) {
      errors.bookMonthTo = 'Book To Month is Required.';
      isValid = false;
    }

    if (!formValues.bookYearFrom) {
      errors.bookYearFrom = 'Book From Year is required';
      isValid = false;
    }

    if (!formValues.bookYearTo) {
      errors.bookYearTo = 'Book To Year is required';
      isValid = false;
    }

    if (
      null === formValues.policyNumber ||
      formValues.policyNumber === undefined ||
      formValues.policyNumber === '' ||
      formValues.policyNumber.trim() === ''
    ) {
      errors.policyNumber = 'Policy Number is required';
      isValid = false;
    }
    if (formValues.policyNumber.length !== 7) {
      errors.policyNumber = 'Policy Number must have exactly 7 digits';
      isValid = false;
    } else if (!regEx.test(formValues.policyNumber)) {
      errors.policyNumber =
        'Policy Number must be numeric, Except for the 3rd position.';
      isValid = false;
    }

    if (
      formValues.policyAccountNumber != null &&
      formValues.policyAccountNumber !== undefined &&
      formValues.policyAccountNumber != ''
    ) {
      if (formValues.policyAccountNumber.length !== 3) {
        errors.policyAccountNumber =
          'Policy Account Number must have a length of 3.';
        isValid = false;
      }
      if (!checkPolicyAccount(formValues.policyAccountNumber)) {
        errors.policyAccountNumber =
          'Policy Account Number must be a whole number.';
        isValid = false;
      }
    }
    if (formValues.bookYearTo < formValues.bookYearFrom) {
      errors.bookYearFrom = 'Book Year From is not before To Year.';
      isValid = false;
    }
    if (formValues.bookYearTo === formValues.bookYearFrom) {
      if (formValues.bookMonthTo < formValues.bookMonthFrom)
        errors.bookMonthFrom = 'Book From Month is not before To Month.';
      isValid = false;
    }

    if (formValues.issueDateFrom !== null && formValues.issueDateTo !== null) {
      if (formValues.issueDateTo < formValues.issueDateFrom) {
        errors.issueDateFrom = 'Issue Date From is after Issue To Date.';

        isValid = false;
      }
    }

    if (
      formValues.chargeDateFrom !== null &&
      formValues.chargeDateTo !== null
    ) {
      if (formValues.chargeDateTo < formValues.chargeDateFrom) {
        errors.chargeDateFrom = 'Charge From Date is after Charge To Date.';

        isValid = false;
      }
    }

    if (
      formValues.transferDateFrom !== null &&
      formValues.transferDateTo !== null
    ) {
      if (formValues.transferDateTo < formValues.transferDateFrom) {
        errors.transferDateFrom =
          'Trans Fst From Service Date is after Trans Fst To Service Date.';

        isValid = false;
      }
    }
    setErrors(errors);
    setIsValid(isValid);
    return isValid;
  };

  const payload = {
    fromBookMonth: formValues.bookYearFrom + formValues.bookMonthFrom,
    toBookMonth: formValues.bookYearTo + formValues.bookMonthTo,
    policyNumber: formValues.policyNumber.toLocaleUpperCase(),
    policyAccountNumber: formValues.policyAccountNumber,
    suffixCode: formValues.suffixCode.toLocaleUpperCase(),
    chargeFromDate: formValues.chargeDateFrom,
    chargeToDate: formValues.chargeDateTo,
    issueFromDate: formValues.issueDateFrom,
    issueToDate: formValues.issueDateTo,
    transFstServiceFromDate: formValues.transferDateFrom,
    transFstServiceToDate: formValues.transferDateTo,
    usrShortName: msid,
  };

  const apiUrl = `${BASE_URL}cmdb-service/add-policy-structure-data`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      axios
        .post(apiUrl, payload, config)
        .then((response) => {
          navigate(
            '/cmdb/correction-management/policy-structure/policy-structure-submit',
            {
              state: {
                data: response.data,
                requestDetails: payload,
              },
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleReset = () => {
    setFormValues({
      bookMonthFrom: '',
      bookMonthTo: '',
      bookYearFrom: '',
      bookYearTo: '',
      policyNumber: '',
      policyAccountNumber: '',
      suffixCode: '',
      issueDateFrom: '',
      issueDateTo: '',
      chargeDateFrom: '',
      chargeDateTo: '',
      transferDateFrom: '',
      transferDateTo: '',
    });

    setErrors('')
  };

  const backToHome = () => {
    navigate('/cmdb/correction-management');
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/polStrucDropdownData/${inquirySearchId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setOptions(response.data.monthList);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Custom Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Policy Structure
                  </Heading>
                  <Flex justify="end">
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{ margin: '0px !important', marginTop: '20px !important' }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Required Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month:</Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="From Month"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={options.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.bookMonthFrom}
                    onChange={(event) => handleChange(event, 'bookMonthFrom')}
                    errorMessage={errors.bookMonthFrom}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="From Year"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={years}
                    isDisabled={readOnly}
                    value={formValues.bookYearFrom}
                    onChange={(event) => handleChange(event, 'bookYearFrom')}
                    errorMessage={errors.bookYearFrom}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}></Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="To Month"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={options.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.bookMonthTo}
                    onChange={(event) => handleChange(event, 'bookMonthTo')}
                    errorMessage={errors.bookMonthTo}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="To Year"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={years}
                    isDisabled={readOnly}
                    value={formValues.bookYearTo}
                    onChange={(event) => handleChange(event, 'bookYearTo')}
                    errorMessage={errors.bookYearTo}
                  />
                </Grid.Col>
              </Grid>
              <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                <TextInput
                  label="Policy Number"
                  isDisabled={readOnly}
                  value={formValues.policyNumber}
                  onChange={(event) =>
                    handleChange(event.target.value, 'policyNumber')
                  }
                  errorMessage={errors.policyNumber}
                />
              </Grid.Col>

              <Grid
                css={{ margin: '0px !important', marginTop: '20px !important' }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Other Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Policy Account Number:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    isDisabled={readOnly}
                    value={formValues.policyAccountNumber}
                    errorMessage={errors.policyAccountNumber}
                    onChange={(event) =>
                      handleChange(event.target.value, 'policyAccountNumber')
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Suffix Code:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label=""
                    isDisabled={readOnly}
                    value={formValues.suffixCode}
                    onChange={(event) =>
                      handleChange(event.target.value, 'suffixCode')
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Issue Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    helper=""
                    subText=""
                    isDisabled={readOnly}
                    value={formValues.issueDateFrom}
                    onChange={(event) => handleChange(event, 'issueDateFrom')}
                    errorMessage={errors.issueDateFrom}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    helper=""
                    subText=""
                    isDisabled={readOnly}
                    value={formValues.issueDateTo}
                    onChange={(event) => handleChange(event, 'issueDateTo')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Charge Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    helper=""
                    subText=""
                    isDisabled={readOnly}
                    value={formValues.chargeDateFrom}
                    onChange={(event) => handleChange(event, 'chargeDateFrom')}
                    errorMessage={errors.chargeDateFrom}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    helper=""
                    subText=""
                    isDisabled={readOnly}
                    value={formValues.chargeDateTo}
                    onChange={(event) => handleChange(event, 'chargeDateTo')}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Trans Fst Service Date:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>From:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    helper=""
                    subText=""
                    isDisabled={readOnly}
                    value={formValues.transferDateFrom}
                    onChange={(event) =>
                      handleChange(event, 'transferDateFrom')
                    }
                    errorMessage={errors.transferDateFrom}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>To:</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label=""
                    helper=""
                    subText=""
                    isDisabled={readOnly}
                    value={formValues.transferDateTo}
                    onChange={(event) => handleChange(event, 'transferDateTo')}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Submit
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={backToHome}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(PolicyStructure);
