import React, { useEffect, useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Text } from '@abyss/web/ui/Text';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import { BASE_URL, API_BASE_URL } from '@src/utils/endpoints';
import { WrapperDataTable } from './../../../../common/WrapperDataTable/WrapperDataTable';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import { Link } from '@abyss/web/ui/Link';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';

const RequestAvailableForCorrectionCustom = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { readOnly, token } = useAuth();
  const { navigate } = useRouter();
  const path = '/cmdb/correction-management';
  const date = new Date();

  useEffect(() => {
    const payload = {
      screenType: 'C',
      pageNumber: 1,
      recordsPerPage: 10,
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}cmdb/inquiry/search`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data === 'No Records Found') setTableData([]);
        else setTableData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (token) fetchData();
  }, [token]);

  const LinkCellRenderer = ({ value }) => {
    return (
      <Link
        href={`/cmdb/correction-management/view-correction-request-detail/${value}`}
      >
        {value}
      </Link>
    );
  };

  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Rqst Id',
        accessor: 'RQST_ID',
        Cell: ({ value }) => <LinkCellRenderer value={value} />,
      },
      {
        Header: 'Rqst Date		',
        accessor: 'RQST_DT',
      },
      {
        Header: 'Pol Nbr	',
        accessor: 'POL_NBR',
      },
      {
        Header: 'Sufx Code	',
        accessor: 'SUFX_CD',
      },
      {
        Header: 'Status',
        accessor: 'STS_TXT',
      },
      {
        Header: 'Message		',
        accessor: 'MESSAGE_TXT',
      },
      {
        Header: '# Records Extracted',
        accessor: 'RQST_TRAN_CNT',
      },
      {
        Header: 'Rqst User Nm',
        accessor: 'RQST_USER_SHORT_NM',
      },
      {
        Header: 'Request Type',
        accessor: 'RQST_TXT',
      },
      {
        Header: 'Event Type',
        accessor: 'EVENT_TXT',
      },
      {
        Header: 'Last Uptd User Nm',
        accessor: 'LAST_USER_SHORT_NM',
      },
      {
        Header: 'Last Uptd Date',
        accessor: 'LAST_UPDT_DT',
      },
      {
        Header: 'Auto Gen',
        accessor: 'SYS_AUTO_PATH_IND',
      },
    ],

    []
  );
  const searchData = useSelector((state) => state.inquiry.data);

  console.log(searchData.pageItems, 'totalData');
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(searchData?.pageItems);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Suffix_Level_Results.xlsx');
  };

  const tableConfig = {
    showPagination: true,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: true,
    initialData: [],
    showTopPagination: true,
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
  };

  const handleClick = () => {
    navigate(`${path}/inquiry-search`);
  };
  const handleBackButton = () => {
    navigate(path);
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    View Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Requests Available For Correction(Custom)
                  </Heading>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text>Click on Request Id to view detail</Text>
                  <Flex justify="end">
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  borderTop: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <span>{tableData?.length || 0} Records</span>
                  {isLoading && <WrapperLoader />}
                  {!isLoading && (
                    <WrapperDataTable
                      title="View Correction Request"
                      data={tableData}
                      columns={columns}
                      fileName="request-available-for-correction-custom.xlsx"
                    />
                  )}
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Modify Search
                  </Button>
                  <Button
                    onClick={handleBackButton}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(RequestAvailableForCorrectionCustom);
