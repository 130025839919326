import {
  UPDATE_INQUIRY_SEARCH,
  UPDATE_INQUIRY_SEARCH_SUCCESS,
  UPDATE_INQUIRY_SEARCH_ERROR,
  SET_POLICY_DATA,
  SET_ACCOUNT_DATA,
  SET_SUFFIX_DATA,
  SET_PAYLOAD_DATA,
  UPDATE_INQUIRY_ELEMENT_SEARCH,
  UPDATE_INQUIRY_ELEMENT_SEARCH_SEARCH_ERROR,
  UPDATE_INQUIRY_ELEMENT_SEARCH_SUCCESS,
} from '@src/redux/action/CMDB/suspectErrror/inquiry/inquiryConstants';

const initialState = {
  error: null,
  loading: false,
  data: [],
  policy: [],
  suffix: [],
  account: [],
  payloads: {},
  policyNumberData: {},
};

export default function inquiryReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INQUIRY_SEARCH:
      return { ...state, data: action.data };
    case UPDATE_INQUIRY_SEARCH_SUCCESS:
      return { ...state, data: action.data };
    case UPDATE_INQUIRY_SEARCH_ERROR:
      return { ...state, error: action.error };
    case UPDATE_INQUIRY_ELEMENT_SEARCH:
      return { ...state, loading: true };
    case UPDATE_INQUIRY_ELEMENT_SEARCH_SUCCESS:
      return { ...state, policyNumberData: action.payload };
    case UPDATE_INQUIRY_ELEMENT_SEARCH_SEARCH_ERROR:
      return { loading: false, policyNumberData: {}, error: action.payload };
    case SET_POLICY_DATA:
      return {
        ...state,
        policy: action.policy,
      };
    case SET_SUFFIX_DATA:
      return {
        ...state,
        suffix: action.suffix,
      };
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        account: action.account,
      };
    case SET_PAYLOAD_DATA:
      return {
        ...state,
        payloads: action.payloads,
      };

    default:
      return state;
  }
}
