import React, { useState } from 'react';

import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';

import axios from 'axios';

import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';

import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { Alert } from '@abyss/web/ui/Alert';

const Account = () => {
  const { navigate } = useRouter();

  const { readOnly, token } = useAuth();
  const [policyNumber, setPolicyNumber] = useState('');
  const [suffix, setSuffix] = useState('');

  const [errMsg, setErrMsg] = useState({ content: '' });
  const [suffixErrMsg, setSuffixErrMsg] = useState({
    content: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [alerMsg, setAlertMsg] = useState();

  const validatePolicyNumber = () => {
    let policyValidate = true;
    setErrMsg({ ...errMsg, ...{ content: '' } });
    setIsOpen(false);

    if (
      null === policyNumber ||
      policyNumber === undefined ||
      policyNumber === '' ||
      policyNumber.trim() === ''
    ) {
      setErrMsg({
        ...errMsg,
        ...{ content: 'Policy number is a required field.' },
      });
      policyValidate = false;
    } else if (policyNumber.length !== 7) {
      setErrMsg({
        ...errMsg,
        ...{
          content: 'Please enter a 7 byte value for Policy Number.',
        },
      });
      policyValidate = false;
    }
    return policyValidate;
  };
  const validateSuffix = () => {
    let suffixValidate = true;
    setSuffixErrMsg({ ...suffixErrMsg, ...{ content: '' } });
    if (!suffix || suffix.trim() === '') {
      setSuffixErrMsg({
        ...suffixErrMsg,
        ...{ content: 'Suffix is a required field.' },
      });
      suffixValidate = false;
    } else if (suffix.length !== 2) {
      setSuffixErrMsg({
        ...suffixErrMsg,
        ...{
          content: 'Please enter a 2 byte value for Suffix Code.',
        },
      });
      suffixValidate = false;
    }
    return suffixValidate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const policyValidate = validatePolicyNumber();
    const suffixValidate = validateSuffix();

    if (policyValidate && suffixValidate) {
      try {
        const policyResponse = await Promise.all([
          axios.get(`${BASE_URL}policy/${policyNumber.toLocaleUpperCase()}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        if (policyResponse[0].status === 200) {
          if (policyResponse[0].data !== 'Data Not Found') {
            const suffixResponse = await Promise.all([
              axios.get(
                `${BASE_URL}suffix/${
                  policyResponse[0]?.data[0]['POL_ENT_ID']
                }/${suffix.toLocaleUpperCase()}/`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);
            if (suffixResponse[0].status === 200) {
              if (suffixResponse[0].data !== 'Not available') {
                const policyData = policyResponse[0].data;
                const suffixData = suffixResponse[0].data;

                navigate('/fadb/account/lookup', {
                  state: {
                    policyData,
                    suffixData,
                  },
                });
              } else {
                setIsOpen(true);
                setAlertMsg('Suffix Code is not valid. Please check.');
              }
            }
          } else {
            setIsOpen(true);
            setAlertMsg('Policy Number is not valid. Please check.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Account Maintenance</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Policy Number"
                      value={policyNumber}
                      onChange={(e) => {
                        setPolicyNumber(e.target.value.toUpperCase());
                      }}
                      errorMessage={errMsg.content}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Suffix"
                      value={suffix}
                      onChange={(e) => setSuffix(e.target.value.toUpperCase())}
                      errorMessage={suffixErrMsg.content}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <Button
                      css={{
                        'abyss-button-root': { marginTop: '25px !important' },
                      }}
                      onClick={handleSubmit}
                      isDisabled={readOnly}
                    >
                      Look Up
                    </Button>
                  </Grid.Col>
                  {isOpen ? (
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <Alert
                        isVisible={isOpen}
                        css={{
                          'abyss-alert-root': {
                            alignItems: 'center',
                          },
                        }}
                        onClose={() => setIsOpen(false)}
                      >
                        {alerMsg}
                      </Alert>
                    </Grid.Col>
                  ) : (
                    ''
                  )}
                </>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(Account);
