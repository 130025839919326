import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';

const FADB = () => {
  const { navigate } = useRouter();
  const tables = [
    {
      id: 1,
      label:
        'FADB' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'UCAS Health Plan Site' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'HEALTH_PLAN_SITE',
      value: 'HEALTH_PLAN_SITE',
    },
    {
      id: 2,
      label:
        'FADB Security' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'Security User' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'SEC_USER',
      value: 'SEC_USER',
    },
    {
      id: 1,
      label:
        'FADB Security' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'USER GROUP MEMBER' +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        'USER_GROUP_MEMBER',
      value: 'USER_GROUP_MEMBER',
    },
  ];
  const [databaseDropdown, setDatabaseDropdown] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [database, setSelectedDatabase] = useState('');
  const { token } = useAuth();
  const handleDynamicDropdown = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}tableMaintainance/listDbNames/5252F/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { queryString: '5065' },
        }
      );
      if (response.status === 200) {
        setDatabaseDropdown(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDynamicDropdown2 = async () => {
    if (systemSelectedValue === 'HEALTH_PLAN_SITE') {
      try {
        const response = await axios.get(
          `${BASE_URL}tableMaintainance/listDbNames/5252F/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { queryString: '5065' },
          }
        );
        if (response.status === 200) {
          setDatabaseDropdown(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await axios.get(
          `${BASE_URL}tableMaintainance/listDbNames/5252S/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { queryString: '5065' },
          }
        );
        if (response.status === 200) {
          setDatabaseDropdown(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  useEffect(() => {
    if (databaseDropdown?.length > 0) {
      const transformedData1 = databaseDropdown.map((item) => ({
        value: item['NAME'],
        label: item['NAME'],
      }));
      setTransformedData(transformedData1);
      setSelectedDatabase(transformedData1[0].label);
    }
  }, [databaseDropdown]);
  const [systemSelectedValue, setSystemSelectedValue] = useState(
    tables[0].value
  );
  const getTableData1 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/get/healthPlanSite/${database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData2 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/sec/get/secUser/${database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData3 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/sec/get/userGroupMember/${database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const handleSelect = async () => {
    if (systemSelectedValue === 'HEALTH_PLAN_SITE') {
      let tableData = await getTableData1();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/fadb/health-plan-site1', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'SEC_USER') {
      let tableData = await getTableData2();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/fadb/sec-user', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    } else if (systemSelectedValue === 'USER_GROUP_MEMBER') {
      let tableData = await getTableData3();
      let columnKeys = [];
      let columns = {};
      if (tableData?.length > 0) {
        columnKeys = Object.keys(tableData?.[0]);
        columns = columnKeys.map((key, index) => ({
          Header: key,
          accessor: key,
        }));
      }
      navigate('/ftmf/fadb/user-group-member', {
        state: {
          database: database,
          systemSelectedValue: systemSelectedValue,
          columns: columns,
          data: tableData,
        },
      });
    }
  };
  useEffect(() => {
    if (token) handleDynamicDropdown2();
  }, [systemSelectedValue, token]);
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white" css={{ padding: '2rem' }}>
              <Grid css={{ marginTop: '40px', marginLeft: '20px' }}>
                <Heading offset={5}>
                  Financial Tables Table Selection Screen
                </Heading>
                <SelectInput
                  label={
                    'System\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Screen\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Table'
                  }
                  placeholder="Pick one"
                  isClearable
                  isSearchable
                  value={systemSelectedValue}
                  onChange={(e) => setSystemSelectedValue(e)}
                  options={tables}
                />
                <SelectInput
                  label="Database"
                  placeholder="Pick one"
                  isClearable
                  isSearchable
                  value={database}
                  onChange={(e) => setSelectedDatabase(e)}
                  options={transformedData}
                />
                {/* <Link href="/ftmf/fadb/health-plan-site"> */}
                <Button
                  css={{
                    'abyss-button-root': { marginTop: '25px !important' },
                  }}
                  onClick={handleSelect}
                >
                  Select
                </Button>
                {/* </Link> */}
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(FADB);
