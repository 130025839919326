import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';
import { Link } from '@abyss/web/ui/Link';
import { values } from 'lodash';
import axios from 'axios';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Modal } from '@abyss/web/ui/Modal';
import { Layout } from '@abyss/web/ui/Layout';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import { useToast } from '@abyss/web/hooks/useToast';
import { Alert } from '@abyss/web/ui/Alert';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';

const InquiryPolicyDetails = () => {
  const { navigate } = useRouter();

  const { loading, error, policyNumberData } = useSelector(
    (state) => state.inquiry
  );

  const [subTypeRowNo, setSubTypeRowNo] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState({});
  const { toast } = useToast();
  const [showAlert, setShowAlert] = useState(false);
  const [showValidationCheck, setshowValidationCheck] = useState(false);
  const { readOnly, msid, token } = useAuth();
  const transacInfo = policyNumberData?.pageItems[subTypeRowNo].ND_D_TRANS_ID;
  const sublineSeqNo =
    policyNumberData?.pageItems[subTypeRowNo].ND_D_SUBL_SEQ_NBR;
  const deleteRow = policyNumberData?.pageItems[subTypeRowNo];

  const handleClick = () => {
    navigate('/cmdb/suspect-errors/inquiry');
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleClick,
    },
  ];

  const columns = React.useMemo(
    () => [
      { Header: 'POL NBR', accessor: 'POLICY_NBR' },
      { Header: 'SUF CD', accessor: 'SFFX_CODE' },
      { Header: 'ACCT CD', accessor: 'ACCT_CODE' },
      { Header: 'SD', accessor: 'SD' },
      { Header: 'CHK NBR', accessor: 'CHECK' },
      { Header: 'CPS#', accessor: 'CPS' },
      {
        Header: 'SUB TY CD',
        accessor: 'SUBLINE_TYPE_CODE',
        Cell: ({ value, row, column }) => {
          const rowValues = row.values;
          setSubTypeRowNo(row.id);
          return (
            <React.Fragment>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/transac-info', {
                    state: { rowValues, transacInfo, sublineSeqNo },
                  });
                }}
              >
                {value}
              </Link>
            </React.Fragment>
          );
        },
      },
      { Header: 'DOL AMT', accessor: 'DOLLAR_AMOUNT' },
      { Header: 'SBSCR ID', accessor: 'SUBSCR_ID' },
      { Header: 'CHARGE DATE', accessor: 'CHARGE_DATE' },
      { Header: 'ISSUE DATE', accessor: 'ISSUE_DATE' },
      { Header: 'SERVICE DATE', accessor: 'SERVICE_DATE' },
      { Header: 'FNDNG ARNG', accessor: 'FNDNG_ARNG' },
      { Header: 'LEGAL ENTITY', accessor: 'LEGAL_ENTITY' },
      { Header: 'CS FND ARG', accessor: 'CASE_FUND_ARNG' },
      { Header: 'CLM SRC CD', accessor: 'CLAIM_SOURCE_CODE' },
      { Header: 'WK STAT', accessor: 'WORKING_STATUS' },
      { Header: 'MD ELG', accessor: 'MED_ELIG' },
      { Header: 'SUS STAT', accessor: 'SUSPECT_STATUS' },
    ],

    []
  );

  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData:
      policyNumberData?.pageItems === 'Not available' ||
      policyNumberData?.pageItems === 'Data not found'
        ? []
        : policyNumberData?.pageItems,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: true,
    customHeaderButtons,
    showSelection: true,
  });

  const deletePolicydata = async (key) => {
    const deleteRow = policyNumberData?.pageItems[key];
    try {
      await axios.post(
        `${BASE_URL}cmdb-service/update-susp-err-subline/${msid}`,

        deleteRow,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error deleting policy:', error);
    }
  };

  const openModal = () => {
    if (Object.keys(dataTablePropsPagination.state.selectedRowIds).length > 0) {
      setShowModal(true);
      setIsOpen(true);
    } else {
      setshowValidationCheck(true);
    }
  };

  const handleDelete = async () => {
    const keys = Object.keys(dataTablePropsPagination.state.selectedRowIds);
    keys.forEach(async (key) => {
      await deletePolicydata(key);

      setIsOpen(false);
      setShowAlert(true);
    });
  };

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    padding: '0px !important',
                  }}
                >
                  <Text fontWeight={700}>Search Criteria Used:</Text>

                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={700}>
                      Policy Number: {policyNumberData?.pageItems?.POLICY_NBR}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',

                    padding: '0px !important',

                    textAlign: 'end',
                  }}
                >
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={700}>Display Level:</Text>
                  </Grid.Col>
                </Grid>
                <Grid css={{ margin: '0px !important' }}>
                  {/* {loading && <WrapperLoader />} */}
                  {policyNumberData?.pageItems && (
                    <DataTable
                      title=""
                      hideTitleHeader
                      tableState={dataTablePropsPagination}
                    />
                  )}
                </Grid>

                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'left',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          borderRadius: '0px',
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={openModal}
                    >
                      Delete
                    </Button>
                    {showModal ? (
                      <React.Fragment>
                        <Modal
                          title="Confirm Delete Record"
                          isOpen={isOpen}
                          onClose={() => setIsOpen(false)}
                          titleAlign="center"
                        >
                          <Modal.Section titleAlign="center">
                            <Layout.Group alignLayout="center">
                              Are you sure you want to delete this record?
                            </Layout.Group>
                          </Modal.Section>
                          <Modal.Section>
                            <Layout.Group alignLayout="center">
                              <Button onClick={handleDelete}>OK</Button>
                              <Button onClick={() => setIsOpen(false)}>
                                Cancel
                              </Button>
                            </Layout.Group>
                          </Modal.Section>
                        </Modal>
                      </React.Fragment>
                    ) : null}
                    {showAlert ? (
                      <Alert
                        title="Error occured due to deletion of record"
                        variant="error"
                        onClose={() => setShowAlert(false)}
                      ></Alert>
                    ) : null}
                    {showValidationCheck ? (
                      <Alert
                        title="Please select a record to delete"
                        variant="error"
                        onClose={() => setshowValidationCheck(false)}
                      ></Alert>
                    ) : null}
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default InquiryPolicyDetails;
