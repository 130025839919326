import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { getEnvironment } from '@src/utils/environmentUtil';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { Modal } from '@abyss/web/ui/Modal';
import { Alert } from '@abyss/web/ui/Alert';
import { TextInput } from '@abyss/web/ui/TextInput';
const SecurityUser = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState({});
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async (payload) => {
    setShowModal(false);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/delete/secUser/${location?.state?.database}`,
        row,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        setIsVisible(true);
        setAlertMsg('You have successfully deleted the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [userShortNm, setuserShortNm] = useState('');
  const [fstNm, setFstNm] = useState('');
  const [lstNm, setLstNm] = useState('');
  const [depNm, setDepNm] = useState('');
  const [userNtId, setUserNtId] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const getTableData1 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/sec/get/secUser/${location?.state?.database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setUpdateRow(row);
    setuserShortNm(row?.['userShortNm']);
    setFstNm(row?.['fstNm']);
    setLstNm(row?.['lstNm']);
    setDepNm(row?.['depNm']);
    setUserNtId(row?.['userNtId']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'sec_user.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: [updateLinkButton, deleteLinkButton],
  });
  const addRow = () => {
    setFlag(true);
  };
  const handleClick = () => {
    window.location.href = '/fmtf/fadb';
  };
  const save = async () => {
    setFlag(false);
    let payload = {
      userShortNm: userShortNm,
      fstNm: fstNm,
      lstNm: lstNm,
      depNm: depNm,
      userNtId: userNtId,
    };
    setuserShortNm('');
    setFstNm('');
    setLstNm('');
    setDepNm('');
    setUserNtId('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/add/secUser/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        setIsVisible(true);
        setAlertMsg('You have successfully added record to table');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    setFlag(false);
    let payload = {
      newRecord: {
        userShortNm: userShortNm,
        fstNm: fstNm,
        lstNm: lstNm,
        depNm: depNm,
        lstUpdtId: userName,
        userNtId: userNtId,
      },
      oldRecord: {
        userShortNm: updateRow['userShortNm'],
        fstNm: updateRow['fstNm'],
        lstNm: updateRow['lstNm'],
        depNm: updateRow['depNm'],
        userNtId: updateRow['userNtId'],
      },
    };
    setuserShortNm('');
    setFstNm('');
    setLstNm('');
    setDepNm('');
    setUserNtId('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/sec/update/secUser/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        setIsVisible(true);
        setAlertMsg('You have successfully updated the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setuserShortNm('');
    setFstNm('');
    setLstNm('');
    setDepNm('');
    setUserNtId('');
  };
  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            <b>
              USER_SHORT_NM: {row?.['userShortNm']}
              <br />
              FST_NM: {row?.['fstNm']}
              <br />
              LST_NM: {row?.['lstNm']}
              <br />
              DEP_NM: {row?.['depNm']}
              <br />
              USER_NT_ID: {row?.['userNtId']}
              <br />
            </b>
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => {
                  setIsVisible(false);
                }}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />
              <Button variant="tertiary" onClick={addRow}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                    span={{ xs: 8 }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="USER_SHORT_NM"
                        value={userShortNm}
                        onChange={(e) => setuserShortNm(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="FST_NM"
                        value={fstNm}
                        onChange={(e) => setFstNm(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="LST_NM"
                        value={lstNm}
                        onChange={(e) => setLstNm(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="DEP_NM"
                        value={depNm}
                        onChange={(e) => setDepNm(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="USER_NT_ID"
                        value={userNtId}
                        onChange={(e) => setUserNtId(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={updateFlag ? update : save}>Save</Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(SecurityUser);
