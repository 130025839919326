import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SubNavigationMenu } from '@abyss/web/ui/SubNavigationMenu';
import useAuth from '@src/utils/useAuth';
import env from '../env.json';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const SidebarContainer = styled.div`
  display: flex;
  flexdirection: column;
  width: 200px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  boxsizing: border-box;
  backgroundcolor: '#f8f8f8';
  padding: 16px 0;
  margin-top: 30px;
  // box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28) !important;
  font-size: 12px;
`;

const CustomSubNavigationMenu = styled(SubNavigationMenu)`
.abyss-sub-navigation-dropdown-title{
  font-size: 16px;
}
.abyss-sub-navigation-header-container {
  position: relative;
}
.abyss-sub-navigation-header-text {
  '&:after': {
    content: '';
    position: absolute;
    borderBottom: '1px solid $gray5';
    width: '100%';
    left: 0;
    bottom: '-8px';
  }
`;

export const Sidebar = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  console.log('====is authenticated===', isAuthenticated);
  const [isFADBReadOnly, setIsFADBReadOnly] = useState(false);
  console.log('env-----', env.FADB_Read_Access);
  const { userRolesGroup } = useAuth();
  console.log('userRolesGroup---------', userRolesGroup);

  useEffect(() => {
    const hasReadAccess = env.FADB_Read_Access.some((role) => {
      return userRolesGroup?.includes(role);
    });
    setIsFADBReadOnly(!hasReadAccess);

    console.log('hasReadAccess------', hasReadAccess, isFADBReadOnly);
  }, [userRolesGroup]);
  const sideBarMenuItems = [
    {
      title: 'FADB',
      position: 'right',
      isDisabled: !isFADBReadOnly,
      css: {
        paddingLeft: '0px !important',
      },

      subItems: [
        {
          title: 'Policy',
          href: '/fadb/policy',
        },
        {
          title: 'Suffix',
          href: '/fadb/suffix',
        },
        {
          title: 'Account',
          href: '/fadb/account',
        },
        {
          title: 'Inquiry',
          position: 'right',

          css: {
            paddingRight: '0px !important',
          },
          subItems: [
            {
              title: 'Case Structure',
              href: '/fadb/inquiry/case-structure',
            },
            {
              title: 'Automated Feeds',
              href: '/fadb/inquiry/automated-feeds',
            },
          ],
        },
      ],
    },

    {
      title: 'CMDB',
      subItems: [
        {
          title: 'Correction Management',
          href: '/cmdb/correction-management',
        },
        {
          title: 'Suspect Error',
          subItems: [
            {
              title: 'Errors Download',
              href: '/cmdb/suspect-errors/errors-download',
            },
            {
              title: 'Inquiry',
              href: '/cmdb/suspect-errors/inquiry',
            },
          ],
        },
      ],
    },
    {
      title: 'Table Maintenance',

      subItems: [
        {
          title: 'CTS',
          href: '/fmtf/cts',
        },
        {
          title: 'FADB',
          href: '/fmtf/fadb',
        },
        {
          title: 'UCAS',
          href: '/fmtf/ucas',
        },
      ],
    },
  ];

  return (
    <>
      {isAuthenticated && (
        <SidebarContainer>
          <CustomSubNavigationMenu
            navItems={sideBarMenuItems}
            position="left"
            css={{
              'abyss-sub-navigation-menu-root': {
                paddingTop: '2rem !important',
                backgroundColor: '#2d3436',
                backgroundImage:
                  'linear-gradient(315deg, #BFBFBF 0%, #d3d3d3 50%)',
              },
              'abyss-sub-navigation-dropdown-title': {
                color: '#fff !important',
              },
              'abyss-sub-navigation-menu-link': { color: '#ff4e00 !important' },
            }}
          />
        </SidebarContainer>
      )}
    </>
  );
};
