import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

export default function GeneralInfoSection({ data, columnsData }) {
  console.log('GeneralInfoSection---------', data);
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>General Information</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Policy Number :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {columnsData === 'P' ? data?.POL_NBR : data.POLICY_NUMBER}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Policy Name :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.POLHLDR_NM}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Type Of Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>Service</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.POL_EFF_DT}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Case Installation Source :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>PRIME</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Policy Type :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>Other</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>ERISA :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.ERISA_CD}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Default Legal Entity :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.LEG_ENTY_CD}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Attributes Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.POL_ATT_EFF_DT}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Default OFN Legal Entity :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.LEG_ENTY_OFN_CD}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Default GL Reinsurance :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.GL_REINS_CD === '' ? `None` : data?.GL_REINS_CD}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Shared Arrangement :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.SHR_ARNG_CD}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
}
