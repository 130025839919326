import {
  FETCH_SUFFIX_TYPE_REQUEST,
  FETCH_SUFFIX_TYPE_SUCCESS,
  FETCH_SUFFIX_TYPE_FAILURE,
  FETCH_SUFFIX_DYNAMIC_REQUEST,
  FETCH_SUFFIX_DYNAMIC_SUCCESS,
  FETCH_SUFFIX_DYNAMIC_FAILURE,
} from '../../../action/FADB/suffix/suffixConstants';

const initialState = {
  // policyData: {},
  // dropdownData1: [],
  error: null,
};

export default function suffixReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUFFIX_TYPE_REQUEST:
      return { ...state, loading: true };
    case FETCH_SUFFIX_TYPE_SUCCESS:
      return { ...state, legalEntities: action.data, loading: false };
    case FETCH_SUFFIX_TYPE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case FETCH_SUFFIX_DYNAMIC_REQUEST:
      return { ...state, loading: true };
    case FETCH_SUFFIX_DYNAMIC_SUCCESS:
      return { ...state, legalEntities: action.data, loading: false };
    case FETCH_SUFFIX_DYNAMIC_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
}
