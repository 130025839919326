import React from 'react';

import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { handleBackButton } from './finalizeCorrectionHandler';
import { useRouter } from '@abyss/web/hooks/useRouter';

export const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  desc,
  handleAction,
}) => {
  const path = '/cmdb/correction-management';
  const { navigate } = useRouter();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Section>
        <Flex
          justify="center"
          alignItems="center"
          alignContent="center"
          direction="column"
        >
          <Text fontWeight={'bold'}> {title}</Text>
          <Text>{desc}</Text>
        </Flex>
        <Grid>
          <Grid.Col
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
            css={{
              'abyss-grid-col': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          >
            <Button
              css={{
                'abyss-button-root': {
                  marginTop: '25px !important',
                  margin: '0.5rem',
                },
              }}
              onClick={() =>
                // handleBackButton(path, navigate)
                handleAction()
              }
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                onClose();
              }}
              css={{
                'abyss-button-root': {
                  marginTop: '25px !important',
                  margin: '0.5rem',
                },
              }}
            >
              No
            </Button>
          </Grid.Col>
        </Grid>
      </Modal.Section>
    </Modal>
  );
};
