import React, { useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import { Layout } from '@abyss/web/ui/Layout';
import withAuth from '@src/auth/withAuth';
import { dateFormatter } from '../../../lib/utils';

import {
  handleBackButton,
  handleDenyClick,
  handleSendClick,
  handleSubmitClick,
} from './finalizeCorrectionHandler';
import { ConfirmationModal } from './confrimationModal';
import { Alert } from '@abyss/web/ui/Alert';
import useAuth from '@src/utils/useAuth';
const FinalizeCorrection = () => {
  const { navigate, getLocation } = useRouter();
  const { token, msid } = useAuth();

  let location = getLocation();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const data = location?.state?.data;
  const requestId = location?.state?.requestId;

  const path = '/cmdb/correction-management';
  const handleModalState = (title, desc) => {
    setIsOpen(true);
    setTitle(title);
    setDescription(desc);
  };

  const handleAlertState = (alertMsg) => {
    setIsAlertOpen(true);
    setAlertMsg(alertMsg);
  };
  return (
    <Layout.Group>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid css={{ margin: '0px !important' }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading
                      offset={5}
                      css={{ textAlign: 'center', marginTop: '20px' }}
                    >
                      {`Finalize Corrections for Request: ${requestId}`}
                    </Heading>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    borderTop: '1px solid #A9A9A9',
                  }}
                >
                  <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                    <Alert
                      isVisible={isAlertOpen}
                      css={{
                        'abyss-alert-root': {
                          alignItems: 'center',
                        },
                      }}
                      onClose={() => setIsAlertOpen(!isAlertOpen)}
                    >
                      {alertMsg}
                    </Alert>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                      Request Info:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Request Date :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{dateFormatter(data?.rqstDt)}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Last Updated Date :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{dateFormatter(data?.lastUpdtDt)}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Request User Name :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.rqstUserShortNm}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Last User Name :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.lastUserShortNm}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Request Type:</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.rqstTxt}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Request Status :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.stsTxt}</Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={() =>
                        handleSendClick(
                          requestId,
                          handleModalState,
                          handleAlertState,
                          config
                        )
                      }
                    >
                      Send for Financial Review
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={() =>
                        handleSubmitClick(
                          'S',
                          requestId,
                          handleModalState,
                          handleAlertState,
                          config,
                          msid
                        )
                      }
                    >
                      Submit to Claim Acctg
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={(e) =>
                        handleDenyClick(
                          'D',
                          requestId,
                          handleModalState,
                          handleAlertState,
                          config,
                          msid
                        )
                      }
                    >
                      Deny Request
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={() => handleBackButton(path, navigate)}
                    >
                      Correction Home
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={title}
          desc={description}
          handleAction={() => handleBackButton(path, navigate)}
        />
      )}
    </Layout.Group>
  );
};
export default withAuth(FinalizeCorrection);
