import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { usePrint } from '@abyss/web/hooks/usePrint';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { BASE_URL } from '@src/utils/endpoints';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Layout } from '@abyss/web/ui/Layout';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
const RequestDetailView = () => {
  const { readOnly, token } = useAuth();
  const { navigate, getLocation } = useRouter();
  const location = getLocation();
  const [data, setData] = useState({});
  const [sublineAmt, setSublineAmt] = useState('');
  const [lockedTransactionData, setLockedTransactionData] = useState([]);
  const print = usePrint();
  const url = window.location.pathname;
  const requestId = url.split('/').pop();

  const getLockedTransactionData = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}manageRequest/lockTrans/${requestId}`,
        config
      );
      if (response.status === 200) {
        if (response?.data === 'No Record available') {
          setLockedTransactionData([]);
        } else {
          setLockedTransactionData(response?.data);
          getTableState.setData(response?.data);
        }
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getData = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}manageRequest/get-request-detail/${requestId}`,
        config
      );
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getSublineData = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}manageRequest/get-SublAmt/${requestId}`,
        config
      );
      if (response.status === 200) {
        setSublineAmt(response.data.totalAmt);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const path = '/cmdb/correction-management';
  useEffect(() => {
    if (token) {
      getData();
      getSublineData();
      getLockedTransactionData();
    }
  }, [token]);
  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'User Locking Transaction',
        accessor: 'userLockingTransaction',
      },
      {
        Header: 'Charge Date',
        accessor: 'chargeDate',
      },
      {
        Header: 'Transaction ID	',
        accessor: 'transId',
      },
      {
        Header: 'Subline Sequence Number	',
        accessor: 'sublineSeqNbr',
      },
    ],

    []
  );

  const getTableState = useDataTable({
    showPagination: true,
    initialColumns: columns,
    pageSizeOptions: [5, 10, 20, 30],
  });

  const handleClick = () => {
    navigate(-1);
  };
  const handleBackButton = () => {
    navigate(path);
  };
  return (
    <Layout.Group>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
                // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
              }}
            >
              <style>{`
              @media print{
                .printing {
                margin-top:150px;
                margin-left:100px}
              }`}</style>
              <Box color="$white" className="printing">
                <Grid css={{ margin: '0px !important' }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>
                      {/* TODO: get Id from url */}
                      {`View Correction Request Detail for Request ID: ${requestId}`}
                    </Heading>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    borderTop: '1px solid #A9A9A9',
                  }}
                >
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                      High Level Request Information:
                    </Text>
                  </Grid.Col>
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '2' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '4') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Book Month From :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['fromBkMo']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '2' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '4') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Book Month To :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['toBkMo']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {data?.['rqstTypCd'] === '3' && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>
                          Subscriber ID (SSN):
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['subscrSSN']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '2' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '5') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Policy Number :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['polNbr']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '2' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '5') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Suffix Code :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['sufxCd']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '1') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>
                          Policy Acct Number :
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['polAcctNbr']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '5') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>
                          Charge Date From :
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['fromChrgDt']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '5') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Charge Date To :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['toChrgDt']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {data?.['rqstTypCd'] === '1' && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Issue Date From:</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['fromChkIssDt']}</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Issue Date To:</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['toChkIssDt']}</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>
                          Trns Fst Srvc From:
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['fromTransSvcDt']}</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Trns Fst Srvc To:</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['toTransSvcDt']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {(data?.['rqstTypCd'] === '1' ||
                    data?.['rqstTypCd'] === '2' ||
                    data?.['rqstTypCd'] === '3' ||
                    data?.['rqstTypCd'] === '4' ||
                    data?.['rqstTypCd'] === '5') && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Status :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['stsTxt']}</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Request Type :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['rqstTxt']}</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Event Type :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{data?.['eventTxt']}</Text>
                      </Grid.Col>
                    </>
                  )}
                  {data?.['stsCd'] === 'E' && (
                    <>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text css={{ fontWeight: 600 }}>Subl Amt :</Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3 }}>
                        <Text>{sublineAmt}</Text>
                      </Grid.Col>
                    </>
                  )}
                </Grid>

                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    borderTop: '1px solid #A9A9A9',
                  }}
                >
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                      Locked Transaction Information:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {/* TODO: get locked transactions */}
                    <Text>
                      {lockedTransactionData.length === 0 && (
                        <>(There are no transactions locked for this request)</>
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <span>{lockedTransactionData.length || 0} Records</span>
                    <DataTable
                      title="View Correction Request"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      onClick={handleClick}
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => {
                        print.printPage();
                      }}
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                    >
                      Print
                    </Button>
                    <Button
                      onClick={handleBackButton}
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                    >
                      Correction Home
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </Layout.Group>
  );
};

export default withAuth(RequestDetailView);
