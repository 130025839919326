import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Footer } from '@src/components/Footer';
import { Sidebar } from '@src/components/Sidebar';
import { DateInput } from '@abyss/web/ui/DateInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { PageHeader } from '@src/components';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { formateDate } from '@src/components/DateUtils';
import { Heading } from '@abyss/web/ui/Heading';
import { useDispatch } from 'react-redux';
import { referenceSuffixApi } from '@src/redux/api/FADB/suffix/suffixApi';
import {
  fetchSuffixTypeSuccess,
  fetchSuffixTypeFailure,
} from '../../../redux/action/FADB/suffix/suffixActions';
import { Alert } from '@abyss/web/ui/Alert';
import { clear } from 'console';
const SuffixLookup = ({ data }) => {
  const { navigate } = useRouter();
  const { token, readOnly } = useAuth();
  const { getLocation } = useRouter();
  const location = getLocation();
  const [suffix, setSuffix] = useState('');
  const [isHighLight, setIsHighLight] = useState(false);
  const [suffixErr, setSuffixErr] = useState('');
  const [policyNumber, setPolicyNumber] = useState(
    location?.state?.data?.policyNumber
  );
  const [selectedTypeOfEffDate, setSelectedTypeOfEffDate] = useState(
    location?.state?.selectedTypeOfEffDate
  );
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(
    location?.state?.selectedLegalEntity
  );
  const [selectedGLReinsurance, setSelectedGLReinsurance] = useState(
    location?.state?.selectedGLReinsurance
  );
  const [selectedFundingCode, setSelectedFundingCode] = useState(
    location?.state?.selectedFundingCode
  );
  const [selectedBankCode, setSelectedBankCode] = useState(
    location?.state?.selectedBankCode
  );
  const [name, setName] = useState(location?.state?.data?.polName);
  const [defaultTypeOfEffectiveDate, setDefaultTypeOfEffectiveDate] = useState(
    location?.state?.data?.typeofeffectiveDate
  );
  const [defaultEffectiveDate, setDefaultEffectiveDate] = useState(
    location?.state?.data?.effectiveDate
  );
  const [defaultCancellationDate, setDefaultCancellationDate] = useState(
    location?.state?.data?.cancelationDate
  );
  const [defaultAttributeEffectiveDate, setDefaultAttributeEffectiveDate] =
    useState(location?.state?.data?.attrEffectiveDate);
  const [defaultLegalEntityCode, setDefaultLegalEntityCode] = useState(
    location?.state?.data?.deafultlegalEntityCode
  );
  const [defaultGLReinsurance, setDefaultGLReinsurance] = useState(
    location?.state?.data?.defaultglreinsurance
  );
  const [defaultFundingEffectiveDate, setDefaultFundingEffectiveDate] =
    useState(location?.state?.data?.fundingEffectiveDate);
  const [defaultFundingCode, setDefaultFundingCode] = useState(
    location?.state?.data?.fundingCode
  );
  const [defaultBankCode, setDefaultBankCode] = useState(
    location?.state?.data?.bankCode
  );
  const [suffixData, setSuffixData] = useState({});
  const [suffixType, setSuffixType] = useState('O ');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [typeOfEffectiveDate, setTypeOfEffectiveDate] = useState('S');
  const [attributeEffectiveDate, setAttributeEffectiveDate] = useState('');
  const [legalEntity, setLegalEntity] = useState('');
  const [gLReinsurance, setGLReinsurance] = useState('');
  const [outOfNetworkLegalEntity, setOutOfNetworkLegalEntity] = useState('');
  const [extendedCoverage, setExtendedCoverage] = useState('');
  const [cancellationDate, setCancellationDate] = useState('');
  const [suffixLastChanged, setSuffixLastChanged] = useState('');
  const [suffixLastChangedBy, setSuffixLastChangedBy] = useState('');
  const [selectedSuffixType, setSelectedSuffixType] = useState([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [fundingEffectiveDate, setFundingEffectiveDate] = useState('');
  const [fundingCode, setFundingCode] = useState('');
  const [companyCode, setCompanyCode] = useState(
    location?.state?.data?.companyCode
  );
  const [bankCode, setBankCode] = useState('');
  const [lastChangedBy, setLastChangedBy] = useState('');
  const [lastChanged, setLastChanged] = useState('');
  const dispatch = useDispatch();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const handleFetchSuffixType = () => {
    referenceSuffixApi(config)
      .then((response) => {
        setSelectedSuffixType(response.data.sufxTyp);
        setSelectedLegalEntity(response.data.legalEntity);
        dispatch(fetchSuffixTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSuffixTypeFailure(error));
      });
  };
  const fecthDynamicData = async () => {
    await axios
      .get(`${BASE_URL}fadb-service/sufx-inq-dyanmic-data/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDynamicData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearErrorFields = () => {
    setSuffixErr('');
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    clearErrorFields();
    if (suffix === '') {
      setSuffixErr('Suffix is required field');
    } else if (suffix.length !== 2) {
      setSuffixErr('Please enter a 2 byte value for Suffix Code');
    } else {
      axios
        .get(
          `${BASE_URL}suffix/${
            location?.state?.policyEntId
          }/${suffix.toUpperCase()}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSuffix(suffix.toUpperCase());
          if (response?.data === 'Not available') {
            setSuffixType('O ');
            setIsHighLight(true);
            setEffectiveDate('12/01/2018');
            setAttributeEffectiveDate('12/01/2018');
            setCancellationDate('03/01/2020');
            setFundingEffectiveDate('12/01/2018');
          } else {
            setIsHighLight(false);
            setSuffixData(response?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const cancel = () => {
    clearErrorFields();
    setSuffix('');
    setTypeOfEffectiveDate('S');
    setEffectiveDate('');
    setAttributeEffectiveDate('');
    setGLReinsurance('');
    setLegalEntity('');
    setOutOfNetworkLegalEntity('');
    setCancellationDate('');
    setSuffixLastChanged('');
    setSuffixLastChangedBy('');
    setFundingEffectiveDate('');
  };
  const lookup = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}policy/cocd-inq/${legalEntity}/${suffixData?.[0]?.GL_REINS_CD}/${fundingCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        if (
          response.data === 'Not available' ||
          response.data === 'Data Not Found'
        ) {
          setAlertText('Company Code not found');
          setShowAlert(true);
          setCompanyCode('');
        } else {
          setCompanyCode(response.data?.CO_CD);
        }
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  useEffect(() => {
    if (token) {
      fecthDynamicData();
      handleFetchSuffixType();
    }
  }, [token]);
  useEffect(() => {
    if (suffixData && suffixData?.length > 0) {
      setSuffixType(suffixData?.[0]?.SUFX_TYP_CD),
        // setTypeOfEffectiveDate(suffixData?.[0]?.SUFX_EFF_DT),
        setEffectiveDate(suffixData?.[0]?.SUFX_EFF_DT),
        setAttributeEffectiveDate(suffixData?.[0]?.ATT_EFF_DT),
        setLegalEntity(suffixData?.[0]?.LEG_ENTY_CD),
        setGLReinsurance(suffixData?.[0]?.GL_REINS_CD?.trim()),
        setOutOfNetworkLegalEntity(suffixData?.[0]?.LEG_ENTY_OFN_CD),
        setCancellationDate(suffixData?.[0]?.SUFX_CANC_DT),
        setExtendedCoverage(suffixData?.[0]?.EXT_BEN_MO_NBR),
        setSuffixLastChanged(suffixData?.[0]?.SUFX_CHG_DT),
        setSuffixLastChangedBy(suffixData?.[0]?.PSH_USER_SHORT_NM);
      setLastChangedBy(suffixData?.[0]?.PFH_USER_SHORT_NM),
        setLastChanged(suffixData?.[0]?.FNDNG_CHG_DT);
      setBankCode(suffixData?.[0]?.BNK_CD);
      setCompanyCode(suffixData?.[0]?.CO_CD || companyCode);
      setFundingCode(suffixData?.[0]?.FUND_ARNG_CD);
      setFundingEffectiveDate(suffixData?.[0]?.FNDNG_EFF_DT);
    }
  }, [suffixData]);
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid.Col span={12} css={{ paddingBottom: '2.5rem' }}>
                <Grid>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>Policy</Heading>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Policy Number"
                        value={policyNumber}
                        isDisabled
                        onChange={(e) => {
                          return setPolicyNumber(e.target.value);
                        }}
                        css={{
                          'abyss-text-input:focus': {
                            boxShadow: '#ff4e00 0px 0px 0px 2px !important ',
                          },
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Name"
                        value={name}
                        isDisabled
                        onChange={(e) => {
                          return setName(e.target.value);
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Type Of Effective Date"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled
                        value={defaultTypeOfEffectiveDate}
                        onChange={setDefaultTypeOfEffectiveDate}
                        options={selectedTypeOfEffDate.map(
                          (typeofeffdt: any) => ({
                            value: typeofeffdt.value,
                            label: typeofeffdt.label,
                          })
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Effective Date"
                        value={formateDate(defaultEffectiveDate)}
                        isDisabled
                        onChange={setDefaultEffectiveDate}
                        subText={' '}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Cancellation Date"
                        value={formateDate(defaultCancellationDate)}
                        isDisabled
                        onChange={setDefaultCancellationDate}
                        subText={' '}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Default Attribute Effective Date"
                        value={formateDate(defaultAttributeEffectiveDate)}
                        isDisabled
                        onChange={setDefaultAttributeEffectiveDate}
                        subText={' '}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default Legal Entiy Code"
                        placeholder="Pick one"
                        isClearable
                        isDisabled
                        isSearchable
                        value={defaultLegalEntityCode}
                        onChange={setDefaultLegalEntityCode}
                        options={selectedLegalEntity.map((entity) => ({
                          value: entity,
                          label: entity,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default GL Reinsurance"
                        placeholder="Pick one"
                        isClearable
                        isDisabled
                        isSearchable
                        value={defaultGLReinsurance}
                        onChange={setDefaultGLReinsurance}
                        options={selectedGLReinsurance.map((reins: any) => ({
                          value: reins.value,
                          label: reins.label,
                        }))}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Default Funding Effective Date"
                        value={formateDate(defaultFundingEffectiveDate)}
                        isDisabled
                        onChange={setDefaultFundingEffectiveDate}
                        subText={' '}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default Funding Code"
                        placeholder="Pick one"
                        isClearable
                        isDisabled
                        isSearchable
                        value={defaultFundingCode}
                        onChange={setDefaultFundingCode}
                        options={selectedFundingCode.map((fndCode: any) => ({
                          value: fndCode.value,
                          label: fndCode.label,
                        }))}
                      />
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Default Bank Code"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled
                        value={defaultBankCode}
                        onChange={setDefaultBankCode}
                        options={selectedBankCode.map((bnkCd: any) => ({
                          value: bnkCd.value,
                          label: bnkCd.label,
                        }))}
                      />
                    </Grid.Col>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>Suffix</Heading>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Suffix"
                        value={suffix}
                        onChange={(e) => {
                          return setSuffix(e.target.value.toUpperCase());
                        }}
                        isDisabled={readOnly}
                        errorMessage={suffixErr}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <Button
                        css={{
                          'abyss-button-root': { marginTop: '25px !important' },
                        }}
                        onClick={handleSubmit}
                      >
                        Look Up
                      </Button>
                    </Grid.Col>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>General Information</Heading>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Suffix Type "
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={suffixType}
                        onChange={(value) => setSuffixType(value)}
                        options={selectedSuffixType?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Type Of Effective Date"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={typeOfEffectiveDate}
                        isDisabled={readOnly}
                        onChange={(value) => setTypeOfEffectiveDate(value)}
                        options={selectedTypeOfEffDate?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Effective Date"
                        value={effectiveDate ? formateDate(effectiveDate) : ''}
                        onChange={(value) => setEffectiveDate(value)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Attribute Effective Date"
                        value={
                          attributeEffectiveDate
                            ? formateDate(attributeEffectiveDate)
                            : ''
                        }
                        onChange={(value) => setAttributeEffectiveDate(value)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Legal Entity"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={legalEntity}
                        onChange={(value) => setLegalEntity(value)}
                        options={selectedLegalEntity?.map((item: any) => ({
                          value: item,
                          label: item,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="GL Reinsurance"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={gLReinsurance}
                        onChange={(value) => setGLReinsurance(value)}
                        options={selectedGLReinsurance?.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Out of Network Legal Entity"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={outOfNetworkLegalEntity}
                        onChange={(value) => setOutOfNetworkLegalEntity(value)}
                        options={selectedLegalEntity?.map((item: any) => ({
                          value: item,
                          label: item,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Cancellation Date"
                        value={
                          cancellationDate ? formateDate(cancellationDate) : ''
                        }
                        onChange={(value) => setCancellationDate(value)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Extended Coverage (Months)"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        isDisabled={readOnly}
                        value={extendedCoverage}
                        onChange={(value) => setExtendedCoverage(value)}
                        options={[
                          { value: 0, label: '0' },
                          { value: '1', label: '1' },
                          { value: '2', label: '2' },
                          { value: '3', label: '3' },
                        ]}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Last Changed"
                        value={suffixLastChanged}
                        onChange={(value) => setSuffixLastChanged(value)}
                        subText={' '}
                        isDisabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="By"
                        placeholder="Pick one"
                        value={suffixLastChangedBy}
                        onChange={(value) => setSuffixLastChangedBy(value)}
                        isDisabled={true}
                      />
                    </Grid.Col>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Heading offset={5}>Default Funding and Banking</Heading>
                      <Alert
                        title={alertText}
                        variant="error"
                        isVisible={showAlert}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <DateInput
                        label="Funding Effective Date"
                        value={
                          fundingEffectiveDate === ''
                            ? ''
                            : formateDate(fundingEffectiveDate)
                        }
                        onChange={(date) => setFundingEffectiveDate(date)}
                        subText={' '}
                        isDisabled={readOnly}
                        highlighted={isHighLight}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Funding Code"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={fundingCode}
                        isDisabled={readOnly}
                        onChange={(value) => setFundingCode(value)}
                        options={selectedFundingCode?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Company Code"
                        value={companyCode}
                        isDisabled={true}
                        onChange={(e) => setCompanyCode(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="Bank Code"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        value={bankCode}
                        isDisabled={readOnly}
                        onChange={(value) => setBankCode(value)}
                        options={selectedBankCode?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <Button
                        css={{
                          'abyss-button-root': { marginTop: '25px !important' },
                        }}
                        isDisabled={readOnly}
                        onClick={lookup}
                      >
                        Look Up
                      </Button>
                    </Grid.Col>
                    <Grid.Col></Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Last Changed"
                        value={lastChanged}
                        onChange={(date) => setLastChanged(date)}
                        subText={' '}
                        isDisabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="By"
                        placeholder="Pick one"
                        value={lastChangedBy}
                        isDisabled={true}
                      />
                    </Grid.Col>
                  </React.Fragment>
                </Grid>
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      // onClick={() => handlePageSubmit('accountPage')}
                      // isDisabled={readOnly}
                    >
                      Submit & Account Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      // isDisabled={readOnly}
                      // onClick={() => handlePageSubmit('suffixPage')}
                    >
                      Submit & Suffix Page
                    </Button>

                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      // isDisabled={readOnly}
                      // onClick={() => handlePageSubmit('policyPage')}
                    >
                      Submit & Policy Page
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      // isDisabled={readOnly}
                      onClick={cancel}
                    >
                      Cancel
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(SuffixLookup);
