import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

export default function DefaultFundingAndBankingSection({ data }) {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>Default Funding & Banking </Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Funding Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.POL_FNDNG_EFF_DT}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Bank Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {data?.BNK_CD === 'F'
              ? 'Fleet'
              : data?.BNK_CD === 'C'
              ? 'Chase'
              : data?.BNK_CD === 'W'
              ? 'Wells Fargo'
              : data?.BNK_CD}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Company Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.CO_CD}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Funding Arrangement Code :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>
            {data?.FUND_ARNG_CD === '1'
              ? 'Fully Insured'
              : data?.FUND_ARNG_CD === '2'
              ? 'MP-Minimum Premium'
              : data?.FUND_ARNG_CD === '3'
              ? 'MMP-Member Minimum Premium'
              : data?.FUND_ARNG_CD === '4'
              ? 'ASO'
              : data?.FUND_ARNG_CD === '5'
              ? 'SC/SL 100'
              : data?.FUND_ARNG_CD === '7'
              ? 'PRIME ASO'
              : data?.FUND_ARNG_CD}
          </Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
}
