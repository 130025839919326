import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

export default function Suffix_GeneralInfo() {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>General Information</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>BW</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Type Of Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>Service</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Suffix Type :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>SMALL GROUP NON HMO</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Attribute Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>12/01/2018</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Effective Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>12/01/2018</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>GL Reinsurance :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>None</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Legal Entity :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>30400</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Extended Coverage (Months) :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>0</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>OFN Legal Entity :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>-</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>By :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>PRIMEUPD</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Cancellation Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>12/01/2018</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Last Changed :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>12/01/2018</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  )
}
