import { access } from 'fs';

export const constantColumns = {
  ATS_CONTR: [
    {
      Header: 'CUST_CONTR_ID',
      accessor: 'col1',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col2',
    },
    {
      Header: 'CHG_DT',
      accessor: 'col3',
    },
    {
      Header: 'SHR_ARNG_CD',
      accessor: 'col4',
    },
    {
      Header: 'TERM_DT',
      accessor: 'col5',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'col6',
    },
    {
      Header: 'PRTN_PAR_ID',
      accessor: 'col7',
    },
    {
      Header: 'LDPRTN_LGL_ENTY_CD',
      accessor: 'col8',
    },
    {
      Header: 'ADMIN_SRC_SYS_CD',
      accessor: 'col9',
    },
    {
      Header: 'CUST_ID',
      accessor: 'col10',
    },
  ],
  ATS_CUST: [
    {
      Header: 'PRTN_ID',
      accessor: 'col1',
    },
    {
      Header: 'ADMIN_SRC_SYS_ID',
      accessor: 'col2',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col3',
    },
    {
      Header: 'CHG_DT',
      accessor: 'col4',
    },
    {
      Header: 'TERM_DT',
      accessor: 'col5',
    },
    {
      Header: 'ERISA_IND',
      accessor: 'col6',
    },
    {
      Header: 'FINC_MKT_SEG_CD',
      accessor: 'col7',
    },
    {
      Header: 'MGRT_SRC_CD',
      accessor: 'col8',
    },
    {
      Header: 'SALE_OFC_CD',
      accessor: 'col9',
    },
    {
      Header: 'CUST_ADDR_ZIP_CD',
      accessor: 'col10',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'col11',
    },
    {
      Header: 'BIL_PLTFM_IND',
      accessor: 'col12',
    },
  ],
  ATS_PLN: [
    {
      Header: 'PRTN_PAR_ID',
      accessor: 'col1',
    },
    {
      Header: 'PRTN_CHILD_ID',
      accessor: 'col2',
    },
    {
      Header: 'COV_TYP_CD',
      accessor: 'col3',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col4',
    },
    {
      Header: 'CHG_DT',
      accessor: 'col5',
    },
    {
      Header: 'TERM_DT',
      accessor: 'col6',
    },
    {
      Header: 'OBLIG_ID',
      accessor: 'col7',
    },
    {
      Header: 'LGL_ENTY_CD',
      accessor: 'col8',
    },
    {
      Header: 'FUND_TYP_CD',
      accessor: 'col9',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'col10',
    },
    {
      Header: 'UNET_PRDT_CD',
      accessor: 'col11',
    },
    {
      Header: 'PRDT_CLSS_ID',
      accessor: 'col12',
    },
    {
      Header: 'ADMIN_SRC_SYS_CD',
      accessor: 'col13',
    },
    {
      Header: 'CUST_CONTR_ID',
      accessor: 'col14',
    },
    {
      Header: 'CUST_PLN_ID',
      accessor: 'col15',
    },
    {
      Header: 'FRANCH_CD',
      accessor: 'col16',
    },
    {
      Header: 'OON_LGL_ENT_CD',
      accessor: 'col17',
    },
    {
      Header: 'UHC_ST_ISS_CD',
      accessor: 'col18',
    },
  ],
  ATS_PVRC: [
    {
      Header: 'CUST_CONTR_ID',
      accessor: 'col1',
    },
    {
      Header: 'PLN_VAR_CD_NBR',
      accessor: 'col2',
    },
    {
      Header: 'RPT_CD_NBR',
      accessor: 'col3',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col4',
    },
    {
      Header: 'CHG_DT',
      accessor: 'col5',
    },
    {
      Header: 'CUST_PLN_ID',
      accessor: 'col6',
    },
    {
      Header: 'TERM_DT',
      accessor: 'col7',
    },
    {
      Header: 'PRTN_CHLD_DTTM',
      accessor: 'col8',
    },
    {
      Header: 'LST_CHG_DTTM',
      accessor: 'col9',
    },
    {
      Header: 'ADMIN_SRC_SYS_ID',
      accessor: 'col10',
    },
    {
      Header: 'SUFX_CD',
      accessor: 'col11',
    },
    {
      Header: 'HMO_ACCT_DIV',
      accessor: 'col12',
    },
    {
      Header: 'MKT_CD',
      accessor: 'col13',
    },
  ],
  Header: [
    {
      Header: 'POL_NBR',
      accessor: 'col1',
    },
    {
      Header: 'ORIGIN_CD',
      accessor: 'col2',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col3',
    },
    {
      Header: 'UPDT_DT',
      accessor: 'col4',
    },
    {
      Header: 'CUST_NBR',
      accessor: 'col5',
    },
    {
      Header: 'EXP_NBR',
      accessor: 'col6',
    },
    {
      Header: 'FRZN_MKT_SGMT',
      accessor: 'col7',
    },
    {
      Header: 'GEO_MKT',
      accessor: 'col8',
    },
    {
      Header: 'RETRO_IND',
      accessor: 'col9',
    },
    {
      Header: 'CANC_DT',
      accessor: 'col10',
    },
    {
      Header: 'ROW_END_DT',
      accessor: 'col11',
    },
    {
      Header: 'SHR_ARNG_CD',
      accessor: 'col12',
    },
    {
      Header: 'BUS_INS_TYP_CD',
      accessor: 'col13',
    },
  ],
  FINC_POL_MKT_SEG: [
    {
      Header: 'POL_NBR',
      accessor: 'col1',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col2',
    },
    {
      Header: 'TERM_DT',
      accessor: 'col3',
    },
    {
      Header: 'MKT_SEG_CD',
      accessor: 'col4',
    },
    {
      Header: 'POLHLDR_NM',
      accessor: 'col5',
    },
    {
      Header: 'ORIG_SRC_NM',
      accessor: 'col6',
    },
    {
      Header: 'UW_LOC_NM',
      accessor: 'col7',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'col8',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'col9',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'col10',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'col11',
    },
  ],
  MARKET: [
    {
      Header: 'MARKET_NBR',
      accessor: 'col1',
    },
    {
      Header: 'MARKET_NBR_PRFX',
      accessor: 'col2',
    },
    {
      Header: 'MARKET_NBR_STATE',
      accessor: 'col3',
    },
    {
      Header: 'MARKET_NBR_SITE',
      accessor: 'col4',
    },
    {
      Header: 'SITE_NBR_1995',
      accessor: 'col5',
    },
    {
      Header: 'HCN_1995',
      accessor: 'col6',
    },
    {
      Header: 'MARKET_NAME',
      accessor: 'col7',
    },
    {
      Header: 'ORIGIN_CD',
      accessor: 'col8',
    },
    {
      Header: 'HMO_ACCT_DIV',
      accessor: 'col9',
    },
    {
      Header: 'LEGAL_ENTITY',
      accessor: 'col10',
    },
    {
      Header: 'LOCATION_CD',
      accessor: 'col11',
    },
    {
      Header: 'MAJOR_MARKET',
      accessor: 'col12',
    },
    {
      Header: 'HMO_PCT',
      accessor: 'col13',
    },
    {
      Header: 'MAJ_MARKET_NM',
      accessor: 'col14',
    },
    {
      Header: 'SALE_OFC_CD',
      accessor: 'col15',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'col16',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'col17',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'col18',
    },
    {
      HEADER: 'UPDT_IND',
      accessor: 'col19',
    },
  ],
  STATE: [
    {
      Header: 'ST_CD',
      accessor: 'col1',
    },
    {
      Header: 'FL_LOC_NBR',
      accessor: 'col2',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col3',
    },
    {
      Header: 'MED_SURCHRG_IND',
      accessor: 'col4',
    },
    {
      Header: 'ST_NM',
      accessor: 'col5',
    },
    {
      Header: 'ST_ABBR_CD',
      accessor: 'col6',
    },
    {
      Header: 'UHIC_TAX_RT_AH',
      accessor: 'col7',
    },
    {
      Header: 'UHIC_TAX_RT_LF',
      accessor: 'col8',
    },
    {
      Header: 'UHIC_TAX_RT_MEDSUP',
      accessor: 'col9',
    },
    {
      Header: 'UHIC_TAX_RT4',
      accessor: 'col10',
    },
    {
      Header: 'UHIC_TAX_RT5',
      accessor: 'col11',
    },
    {
      Header: 'HP_TAX_RT5',
      accessor: 'col12',
    },
    {
      Header: 'HP_TAX_RT_AH',
      accessor: 'col13',
    },
    {
      Header: 'HP_TAX_RT_LF',
      accessor: 'col14',
    },
    {
      Header: 'HP_TAX_RT_RT3',
      accessor: 'col15',
    },
    {
      Header: 'HP_TAX_RT_RT4',
      accessor: 'col16',
    },
    {
      Header: 'HP_TAX_RT_RT5',
      accessor: 'col17',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'col18',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'col19',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'col20',
    },
    {
      HEADER: 'UPDT_IND',
      accessor: 'col21',
    },
  ],
  PRODUCT: [
    {
      Header: 'UNET_PRDCT_CD',
      accessor: 'col1',
    },
    {
      Header: 'PRDCT_CLSS_ID',
      accessor: 'col2',
    },
    {
      Header: 'COV_TYP_CD',
      accessor: 'col3',
    },
    {
      Header: 'GL_PRDCT_CD',
      accessor: 'col4',
    },
    {
      Header: 'NET_IND',
      accessor: 'col5',
    },
    {
      Header: 'DUAL_CONTR_IND',
      accessor: 'col6',
    },
    {
      Header: 'GATE_IND',
      accessor: 'col7',
    },
    {
      Header: 'PRDCT_TXT',
      accessor: 'col8',
    },
    {
      Header: 'MBR_MAJ_PRDCT_CD',
      accessor: 'col9',
    },
    {
      Header: 'SEL_CHO_CD',
      accessor: 'col10',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'col11',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'col12',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'col13',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'col14',
    },
  ],
  HMOACCT: [
    {
      Header: 'HMO_ROW_NBR',
      accessor: 'col1',
    },
    {
      Header: 'HMO_BEGIN_DT',
      accessor: 'col2',
    },
    {
      Header: 'HMO_END_DT',
      accessor: 'col3',
    },
    {
      Header: 'HMO_LEGAL_ENT_NBR',
      accessor: 'col4',
    },
    {
      Header: 'HMO_LEGAL_ENT',
      accessor: 'col5',
    },
    {
      Header: 'HMO_MKT_NAME',
      accessor: 'col6',
    },
    {
      Header: 'HMO_MKT_NBR',
      accessor: 'col7',
    },
    {
      Header: 'HMO_PROD_TYP',
      accessor: 'col8',
    },
    {
      Header: 'HMO_ACCT_DIV',
      accessor: 'col9',
    },
    {
      Header: 'LST_UPDT_DT',
      accessor: 'col10',
    },
    {
      Header: 'LST_UPDT_TM',
      accessor: 'col11',
    },
    {
      Header: 'LST_UPDT_ID',
      accessor: 'col12',
    },
    {
      Header: 'UPDT_IND',
      accessor: 'col13',
    },
    {
      Header: 'LGL_ENTY_GLBU_NBR',
      accessor: 'col14',
    },
    {
      Header: 'GL_LOC_CD',
      accessor: 'col15',
    },
    {
      Header: 'HMO_PCT',
      accessor: 'col16',
    },
  ],
  RPT_POLICY: [
    {
      Header: 'RPT_GRP_POL_NBR',
      accessor: 'col1',
    },
    {
      Header: 'COSMOS_DIV',
      accessor: 'col2',
    },
    {
      Header: 'POLHLDR_NM',
      accessor: 'col3',
    },
    {
      Header: 'CONT_ISS_ST',
      accessor: 'col4',
    },
    {
      Header: 'ADMIN_SYS_CD',
      accessor: 'col5',
    },
    {
      Header: 'EFF_DT',
      accessor: 'col6',
    },
    {
      Header: 'CANC_DT',
      accessor: 'col7',
    },
  ],
  GL_ACCT: [
    { Header: 'GL_ACCT_CD', accessor: 'glAcctCd' },
    { Header: 'GL_ACCT_TXT', accessor: 'glAcctTxt' },
    { Header: 'GL_ACCT_LNG_TXT', accessor: 'glAcctLngTxt' },
    { Header: 'EFF_STS_CD', accessor: 'effStsCd' },
  ],
  LABEL: [
    { Header: 'POL_NBR', accessor: 'polNbr' },
    { Header: 'ORIGIN_CD', accessor: 'originCd' },
    { Header: 'EFF_DT', accessor: 'effDt' },
    { Header: 'UPDT_DT', accessor: 'updtDt' },
    { Header: 'CUST_NBR', accessor: 'custNbr' },
    { Header: 'EXP_NBR', accessor: 'expNbr' },
    { Header: 'FRZN_MKT_SGMT', accessor: 'frznMktSgmt' },
    { Header: 'GEO_MKT', accessor: 'geoMkt' },
    { Header: 'RETRO_IND', accessor: 'retroInd' },
    { Header: 'CANC_DT', accessor: 'cancDt' },
    { Header: 'ROW_END_DT', accessor: 'rowEndDt' },
    { Header: 'SHR_ARNG_CD', accessor: 'shrArngCd' },
    { Header: 'BUS_INS_TYP_CD', accessor: 'busInsTypCd' },
  ],
};
