export const UPDATE_AUTOMATED_FEED_SEARCH = 'UPDATE_AUTOMATED_FEED_SEARCH';
export const UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS =
  'UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS';
export const UPDATE_AUTOMATED_FEED_SEARCH_ERROR =
  'UPDATE_AUTOMATED_FEED_SEARCH_ERROR';

export const UPDATE_AUTOMATED_FEED_POLICY_DETAILS =
  'UPDATE_AUTOMATED_FEED_POLICY_DETAILS';
export const UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS =
  'UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS';
export const UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR =
  'UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR';

export const UPDATE_AUTOMATED_FEED_ERROR_CORRECTION =
  'UPDATE_AUTOMATED_FEED_ERROR_CORRECTION';
export const UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS =
  'UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS';
export const UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR =
  'UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR';

export const SET_PAYLOAD_POLICY_DETAILS = 'SET_PAYLOAD_POLICY_DETAILS';
export const SET_FILE_DATA = 'SET_FILE_DATA';
export const SET_TRANS_DATA = 'SET_TRANS_DATA';
