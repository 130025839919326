import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { dateFormatter } from '../../../../lib/utils';

export default function RenewalCancellationSection({ data }) {
  return (
    <React.Fragment>
      <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Heading offset={5}>Renewal/Cancellation</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Type of Renewal :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>Once a Year</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>First Renewal Month :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.REN_MO_NBR}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Cancellation Date :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>12/01/2018</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Day :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.REN_DAY_NBR}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>Last Changed :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{dateFormatter(data?.POL_CHG_DT)}</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text css={{ fontWeight: 600 }}>By :</Text>
        </Grid.Col>
        <Grid.Col span={{ xs: 3 }}>
          <Text>{data?.USER_SHORT_NM}</Text>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
}
