import React, { useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useDataTable } from '@abyss/web/hooks/useDataTable';

import withAuth from '@src/auth/withAuth';

import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Layout } from '@abyss/web/ui/Layout';

import {
  handleBackButton,
  handleDenyClick,
  handleSubmitClick,
  submitData,
} from './finalizeCorrectionHandler';
import { Alert } from '@abyss/web/ui/Alert';
import { ConfirmationModal } from './confrimationModal';
import useAuth from '@src/utils/useAuth';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';
const ApproveFinancialSummary = () => {
  const { navigate, getLocation } = useRouter();
  let location = getLocation();
  const { token, msid } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = location?.state?.data;
  const requestId = location?.state?.requestId;
  const path = '/cmdb/correction-management';
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [action, setAction] = useState('');
  const date = new Date();

  const handleModalState = (title, desc, action = '') => {
    setIsOpen(true);
    setTitle(title);
    setDescription(desc);
    setAction(action);
  };

  const handleAction = () => {
    switch (action) {
      case 'D':
        handleDenyClick(
          'D',
          requestId,
          handleModalState,
          handleAlertState,
          config,
          msid,
          handleCompletion
        );
        break;

      case 'S':
        submitData(
          'S',
          requestId,
          handleModalState,
          handleAlertState,
          config,
          msid,
          handleCompletion
        );
        break;

      default:
        break;
    }
  };

  const handleCompletion = () => {
    handleBackButton(path, navigate);
  };
  const handleAlertState = (alertMsg) => {
    setIsAlertOpen(true);
    setAlertMsg(alertMsg);
  };
  const CellRenderer = ({ row }) => {
    switch (row.original.fundArngCd) {
      case '1':
        return 'Fully Insured';
      case '2':
        return 'MP-Minimum Premium';
      case '3':
        return 'MMP-Member Minimum Premium';
      case '4':
        return 'ASO';
      case '5':
        return 'SC/SL 100';
      case '7':
        return 'PRIME ASO';
      default:
        return '(blank)';
    }
  };
  //get column definition
  const columns = React.useMemo(
    () => [
      {
        Header: 'Policy Number',
        accessor: 'polNbr',
      },
      {
        Header: 'Suffix Code',
        accessor: 'sufxCd',
      },
      {
        Header: 'Subscriber Number',
        accessor: 'subscrSsn',
      },
      {
        Header: 'Correction Error Code',
        accessor: 'errCd',
      },
      {
        Header: 'Funding Arrng Code',
        accessor: 'fundArngCd',
        Cell: ({ row }) => <CellRenderer row={row} />,
      },
      {
        Header: 'Total Dollar Amount',
        accessor: 'sublSrvcAmt',
        Cell: ({ value }) => {
          if (value < 0) {
            return `$(${value})`;
          }
          return `$${value}`;
        },
      },
    ],

    []
  );

  const getTableState = useDataTable({
    showPagination: true,
    initialColumns: columns,
    initialData: data
      ? data
      : data === 'Not available' || data === 'Data not found'
      ? []
      : [],

    pageSizeOptions: [5, 10, 20, 30],
  });

  return (
    <Layout.Group>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 11, md: 11, lg: 11, xl: 11 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
              marginLeft: '200px',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid css={{ margin: '0px !important' }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading
                      offset={5}
                      css={{ marginTop: '20px' }}
                      textAlign="center"
                    >
                      {`Approve Financial Summary for Request ID: ${requestId}`}
                      <Flex justify="end">
                        <Label size="md" id="first name">
                          {currentDateFormat(date)}
                        </Label>
                      </Flex>
                    </Heading>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    borderTop: '1px solid #A9A9A9',
                  }}
                >
                  <Grid.Col span={{ xs: 6, md: 6, lg: 6, xl: 6 }}>
                    <Alert
                      isVisible={isAlertOpen}
                      css={{
                        'abyss-alert-root': {
                          alignItems: 'center',
                        },
                      }}
                      onClose={() => setIsAlertOpen(!isAlertOpen)}
                    >
                      {alertMsg}
                    </Alert>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <span> {data?.length || 0} records</span>
                    <DataTable
                      title="View Correction Request"
                      tableState={getTableState}
                      hideTitleHeader
                    />
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      onClick={() =>
                        handleSubmitClick(
                          'S',
                          requestId,
                          handleModalState,
                          handleAlertState,
                          config,
                          msid,
                          handleCompletion
                        )
                      }
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                    >
                      Submit to Claim Acctg
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={
                        (e) =>
                          handleModalState(
                            `Deny Request Confirmation`,
                            `Are you sure you want to send Request ID: ${requestId} for Financial Review?`,
                            'D'
                          )
                        // handleDenyClick(
                        //   'D',
                        //   requestId,
                        //   handleModalState,
                        //   handleAlertState,
                        //   config,
                        //   msid
                        // )
                      }
                    >
                      Deny Request
                    </Button>
                    <Button
                      onClick={() => handleBackButton(path, navigate)}
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                    >
                      Correction Home
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={title}
          desc={description}
          handleAction={handleAction}
        />
      )}
    </Layout.Group>
  );
};

export default withAuth(ApproveFinancialSummary);
