import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { usePrint } from '@abyss/web/hooks/usePrint';
import withAuth from '@src/auth/withAuth';
import { Layout } from '@abyss/web/ui/Layout';

const SuspectErrorSubmit = () => {
  const { readOnly } = useAuth();
  const { navigate, getLocation } = useRouter();
  const path = '/cmdb/correction-management';
  const print = usePrint();
  let location = getLocation();
  let data = location?.state;
  return (
    <Layout.Group>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
                // boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
              }}
            >
              <style>{`
              @media print{
                .printing {
                margin-top:150px;
                margin-left:100px}
              }`}</style>
              <Box color="$white" className="printing">
                <Grid css={{ margin: '0px !important' }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Correction Request Processor</Heading>
                  </Grid.Col>
                </Grid>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Request ID : </Text>
                  <Text>{data?.requestId}</Text>
                </Grid.Col>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    borderBottom: '1px solid #A9A9A9',
                  }}
                >
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Request Detail</Heading>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                  }}
                >
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Policy Number :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.policyNumber}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Suffix Code :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data.suffixCode}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Charge Date From :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.fromChargeDate}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Charge Date To :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.toChargeDate}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Error Code :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.spctErrTypCd}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Series Designator :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.seriesDesignator}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Claim Source Code :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.claimSourceCode}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Check Number :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.checkNumber}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>
                      Funding Arrg Group Code:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.fundArrGrpCode}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>CPS# :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>{data?.cps}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text css={{ fontWeight: 600 }}>Request Type :</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3 }}>
                    <Text>Suspect Error</Text>
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={() => {
                        print.printPage();
                      }}
                    >
                      Print
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={() => navigate(path)}
                    >
                      Correction Home
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </Layout.Group>
  );
};

export default withAuth(SuspectErrorSubmit);
