import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Modal } from '@abyss/web/ui/Modal';
import { Alert } from '@abyss/web/ui/Alert';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { getEnvironment } from '@src/utils/environmentUtil';
import { json } from 'stream/consumers';
const HealthPlanSite = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const [hideUpdate, setHideUpdate] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [hideAdd, setHideAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [row, setRow] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [jsonData, setJsonData] = useState(location?.state?.data);
  const deleteRowModal = (row) => {
    setRow(row);
    setShowModal(true);
  };
  const deleteRows = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setShowModal(false);
    try {
      const response = await axios.post(
        `${BASE_URL}fadb/delete/healthPlanSite/${location?.state?.database}`,
        row,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        setIsVisible(true);
        setAlertMsg('You have successfully deleted the record');
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
        setJsonData(tableData);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [hmoAcctDiv, sethmoAcctDiv] = useState('');
  const [legEntyCd, setLegEntyCd] = useState('');
  const [hmoAcctDivErr, sethmoAcctDivErr] = useState('');
  const [legEntyCdErr, setLegEntyCdErr] = useState('');
  const [admTxt, setAdmTxt] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const getTableData1 = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}fadb/get/healthPlanSite/${location?.state?.database}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRowModal(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    clearFields();
    setUpdateFlag(true);
    setHideAdd(true);
    setFlag(true);
    setUpdateRow(row);
    sethmoAcctDiv(row?.['hmoAcctDivAdmCd']);
    setLegEntyCd(row?.['legalEntityCd']);
    setAdmTxt(row?.['hmoActDivAdmTxt']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'health_plan_site.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const addRow = () => {
    clearFields();
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    window.location.href = '/fmtf/fadb';
  };
  const validateSave = () => {
    let valid = true;
    if (hmoAcctDiv === '' || hmoAcctDiv.length !== 3) {
      sethmoAcctDivErr('HMO_ACCTDIV_ADM_CD must be 3 characters long');
      valid = false;
    } else if (isNaN(hmoAcctDiv)) {
      sethmoAcctDivErr('HMO_ACCTDIV_ADM_CD must be numeric');
      valid = false;
    } else if (legEntyCd.length !== 5) {
      setLegEntyCdErr('LEG_ENTY_CD must be 5 characters long');
      valid = false;
    } else if (isNaN(legEntyCd)) {
      setLegEntyCdErr('LEG_ENTY_CD must be numeric');
      valid = false;
    } else if (admTxt === '') {
      setErrorMessage('HMO_ACCTDIVADM_TXT cannot be blank');
      valid = false;
    } else if (admTxt.length > 20) {
      setErrorMessage(
        'HMO_ACCTDIVADM_TXT cannot be more than 20 characters long'
      );
      valid = false;
    } else if (jsonData.some((item) => item.hmoAcctDivAdmCd === hmoAcctDiv)) {
      sethmoAcctDivErr(
        'Data with HMO_ACCTDIV_ADM_CD = ' + hmoAcctDiv + ' already exists'
      );
      valid = false;
    }
    return valid;
  };
  const save = async () => {
    clearFields();
    setSaveModal(false);
    setHideUpdate(false);
    if (validateSave()) {
      setFlag(false);
      let payload = {
        hmoAcctDivAdmCd: hmoAcctDiv,
        hmoAcctDivAdmTxt: admTxt,
        legalEntityCd: legEntyCd,
        lstUpdtId: userName,
      };
      sethmoAcctDiv('');
      setAdmTxt('');
      setLegEntyCd('');
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${BASE_URL}fadb/add/healthPlanSite/${location?.state?.database}`,
          payload,
          config
        );
        if (response.status === 200) {
          console.log(response.data);
          setIsVisible(true);
          setAlertMsg('You have successfully added record to table');
          let tableData = await getTableData1();
          getTableState.setData(tableData || [], true);
          setJsonData(tableData);
        }
      } catch (error) {
        console.error(error);
        return {};
      }
    }
  };
  const validate = () => {
    let valid = true;
    if (admTxt?.length === 0) {
      setErrorMessage('HMO_ACCTDIVADM_TXT cannot be blank');
      valid = false;
    } else if (admTxt.length > 20) {
      setErrorMessage(
        'HMO_ACCTDIVADM_TXT cannot be more than 20 characters long'
      );
      valid = false;
    }
    return valid;
  };
  const clearFields = () => {
    setErrorMessage('');
    sethmoAcctDivErr('');
    setLegEntyCdErr('');
  };
  const update = async () => {
    setHideAdd(false);
    setUpdateModal(false);
    setUpdateConfirmModal(false);
    setUpdateFlag(false);
    clearFields();
    if (validate()) {
      setFlag(false);
      let payload = {
        newRecord: {
          hmoAcctDivAdmCd: hmoAcctDiv,
          hmoActDivAdmTxt: admTxt,
          legalEntityCd: legEntyCd,
          lstUpdtId: userName,
        },
        oldRecord: {
          hmoAcctDivAdmCd: updateRow['hmoAcctDivAdmCd'],
          hmoActDivAdmTxt: updateRow['hmoActDivAdmTxt'],
          legalEntityCd: updateRow['legalEntityCd'],
        },
      };
      sethmoAcctDiv('');
      setAdmTxt('');
      setLegEntyCd('');
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${BASE_URL}fadb/update/healthPlanSite/${location?.state?.database}`,
          payload,
          config
        );
        if (response.status === 200) {
          console.log(response.data);
          setIsVisible(true);
          setAlertMsg('You have successfully updated the record');
          let tableData = await getTableData1();
          getTableState.setData(tableData || [], true);
          setJsonData(tableData);
        }
      } catch (error) {
        console.error(error);
        return {};
      }
    }
  };
  const close = () => {
    setHideUpdate(false);
    setHideAdd(false);
    setFlag(false);
    sethmoAcctDiv('');
    setAdmTxt('');
    setLegEntyCd('');
  };
  const updateClick = () => {
    setUpdateConfirmModal(true);
    setUpdateModal(false);
  };
  return (
    <>
      <React.Fragment>
        <Modal
          title="Confirm Update Record"
          isOpen={updateConfirmModal}
          onClose={() => setUpdateConfirmModal(false)}
        >
          <Modal.Section>
            Are you sure you want to update this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={update}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Delete Record"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Section>
            Are you sure you want to delete this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={deleteRows}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Add Record"
          isOpen={saveModal}
          onClose={() => setSaveModal(false)}
        >
          <Modal.Section>
            Are you sure you want to add this record?
            <br />
            <Button css={{ margin: '10px' }} onClick={save}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setSaveModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Modal
          title="Confirm Update"
          isOpen={updateModal}
          onClose={() => setUpdateModal(false)}
        >
          <Modal.Section>
            <b>Please review fields to be updated</b>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text css={{ width: '50%' }}>Original Value</Text>
              <Text>Updated Value</Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_ACCTDIV_ADM_CD:</b>
                {updateRow['hmoAcctDivAdmCd']}
              </span>
              <span>
                <b>HMO_ACCTDIV_ADM_CD: </b>
                {hmoAcctDiv}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>LEG_ENTY_CD:</b>
                {updateRow['legalEntityCd']}
              </span>
              <span>
                <b>LEG_ENTY_CD: </b>
                {legEntyCd}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <span style={{ width: '50%' }}>
                <b>HMO_ACCTDIVADM_TXT:</b>
                {updateRow['hmoActDivAdmTxt']}
              </span>
              <span>
                <b>HMO_ACCTDIVADM_TXT: </b>
                {admTxt}
              </span>
            </div>
            <Button css={{ margin: '10px' }} onClick={updateClick}>
              Ok
            </Button>
            <Button
              css={{ margin: '10px' }}
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
          </Modal.Section>
        </Modal>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Alert
                title={alertMsg}
                variant="success"
                isVisible={isVisible}
                onClose={() => {
                  setIsVisible(false);
                }}
              />
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />
              <Button variant="tertiary" onClick={addRow} isDisabled={hideAdd}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                    span={{ xs: 8 }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_ACCTDIV_ADM_CD"
                        value={hmoAcctDiv}
                        isDisabled={hideAdd}
                        errorMessage={hmoAcctDivErr}
                        onChange={(e) => sethmoAcctDiv(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="LEG_ENTY_CD"
                        value={legEntyCd}
                        isDisabled={hideAdd}
                        errorMessage={legEntyCdErr}
                        onChange={(e) => setLegEntyCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="HMO_ACCTDIVADM_TXT"
                        value={admTxt}
                        onChange={(e) => setAdmTxt(e.target.value)}
                        errorMessage={errorMessage}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button
                        onClick={
                          updateFlag
                            ? () => setUpdateModal(true)
                            : () => setSaveModal(true)
                        }
                      >
                        Save
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(HealthPlanSite);
