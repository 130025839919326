import { combineReducers } from 'redux';
import policyReducer from './FADB/policy/policyReducer';
import searchReducer from './FADB/inquiry/automatedFeeds/searchAPI';
import suffixReducer from './FADB/suffix/suffixReducer';
import caseStructureReducer from './FADB/inquiry/caseStructure/caseStructure';
import inquiryReducer from './CMDB/suspectError/inquiry/inquiry';
// Import other reducers here...

const rootReducer = combineReducers({
  policy: policyReducer,
  automatedFeedSearch: searchReducer,
  suffix: suffixReducer,
  caseStructure: caseStructureReducer,
  inquiry: inquiryReducer

  // Add other reducers here...
});

export default rootReducer;
