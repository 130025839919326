import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';
import { currentDateFormat } from '@src/components/DateUtils';
import { Label } from '@abyss/web/ui/Label';
import { Flex } from '@abyss/web/ui/Flex';

const Payment = () => {
  const { readOnly, token, msid } = useAuth();
  const [seriesDesignator, setSeriesDesignator] = useState('');
  const [sdError, setSdError] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [cnError, setCnError] = useState('');
  const [cps, setCps] = useState('');
  const [cpsError, setCpsError] = useState('');
  const [fromBookMonth, setFromBookMonth] = useState('');
  const [fromBookYear, setFromBookYear] = useState('');
  const [fbmError, setFbmError] = useState('');
  const [fbyError, setFbyError] = useState('');
  const [tbyError, setTbyError] = useState('');
  const [toBookMonth, setToBookMonth] = useState('');
  const [toBookYear, setToBookYear] = useState('');
  const [tbmError, setTbmError] = useState('');
  const path = '/cmdb/correction-management';
  const [paymentOptions, setPaymentOptions]: any = useState([]);
  const id = 'cmdbPolicyStructure';
  const years = [];
  for (let year = 1990; year <= 2029; year++) {
    years.push({ id: year, value: year, label: year.toString() });
  }
  const { navigate } = useRouter();
  const handleBackButton = () => {
    navigate(path);
  };
  const checkNumeric = (chknbr) => {
    const numbers: RegExp = /^[0-9]+$/;
    if (chknbr.match(numbers)) {
      return false;
    } else {
      return true;
    }
  };
  const checkSeriesDesignator = (seriesDesignator) => {
    const sd: RegExp = /^[0-9a-zA-Z]+$/;
    if (seriesDesignator.match(sd)) {
      return true;
    } else {
      return false;
    }
  };
  const isSrsDesgLocked = (seriesDesignator) => {
    let isLocked = false;
    switch (seriesDesignator) {
      case 'CU':
      case 'CV':
      case 'CW':
      case 'EN':
      case 'FN':
      case 'KN':
      case 'MN':
      case 'N1':
      case 'N2':
      case 'N3':
      case 'N4':
      case 'N5':
      case 'N6':
      case 'NN':
      case 'NO':
      case 'NQ':
      case 'NY':
      case 'NZ':
      case 'OB':
      case 'OC':
      case 'PB':
      case 'PC':
      case 'PD':
      case 'PE':
      case 'PR':
      case 'QN':
      case 'RS':
      case 'UN':
      case 'VZ':
      case 'W1':
      case 'W2':
      case 'M8':
      case 'M9':
      case 'LU':
      case 'LV':
        isLocked = true;
        break;

      default:
        isLocked = false;
        break;
    }
    return isLocked;
  };
  const resetErrorFields = () => {
    setCnError('');
    setSdError('');
    setCpsError('');
    setTbmError('');
    setFbmError('');
    setFbyError('');
    setTbyError('');
  };
  const validate = () => {
    resetErrorFields();
    let validFlag = true;
    if (
      fromBookMonth === null ||
      fromBookMonth === undefined ||
      fromBookMonth === '' ||
      fromBookYear === null ||
      fromBookYear === undefined ||
      fromBookYear === ''
    ) {
      setFbmError('Book From Month is Required.');
      validFlag = false;
    }
    if (
      toBookMonth === null ||
      toBookMonth === undefined ||
      toBookMonth === '' ||
      toBookYear === null ||
      toBookYear === undefined ||
      toBookYear === ''
    ) {
      setTbmError('Book To Month is Required.');
      validFlag = false;
    }

  
    if (
      fromBookYear === null ||
      fromBookYear === undefined ||
      fromBookYear === ''
    ) {
      setFbyError('Book From Year is Required.');
      validFlag = false;
    }
    if (
      toBookYear === null ||
      toBookYear === undefined ||
      toBookYear === ''
    ) {
      setTbyError('Book To Year is Required.');
      validFlag = false;
    }






    if (
      fromBookMonth !== null &&
      toBookMonth !== null &&
      fromBookMonth !== null &&
      toBookMonth !== null
    ) {
      if (toBookYear < fromBookYear) {
        setTbmError('From Date is not before To Date.');
        setFbmError('From Date is not before To Date.');
        validFlag = false;
      }
      if (toBookYear === fromBookYear) {
        if (toBookMonth < fromBookMonth) {
          setTbmError('From Date is not before To Date.');
          setFbmError('From Date is not before To Date.');
          validFlag = false;
        }
      }
    }
    if (
      seriesDesignator === null ||
      seriesDesignator === undefined ||
      seriesDesignator === '' ||
      seriesDesignator.trim() === ''
    ) {
      setSdError('Series Designator is required.');
      validFlag = false;
    } else if (seriesDesignator.trim().length !== 2) {
      setSdError('Series Designator must be 2 characters.');
      validFlag = false;
    } else if (!checkSeriesDesignator(seriesDesignator)) {
      setSdError('Enter a valid Series Designator');
      validFlag = false;
    } else if (isSrsDesgLocked(seriesDesignator.toUpperCase())) {
      setSdError(
        'This Series Designator Code is locked out, Please try another.'
      );
      validFlag = false;
    }
    if (
      fromBookMonth !== null &&
      toBookMonth !== null &&
      seriesDesignator !== null
    ) {
      if (
        (checkNumber === null ||
          checkNumber === undefined ||
          checkNumber === '' ||
          checkNumber.trim() === '') &&
        (cps === null || cps === undefined || cps === '' || cps.trim() === '')
      ) {
        setCnError(
          'Please Enter Either Check Number or Claim Payment Sequence Number'
        );
        setCpsError(
          'Please Enter Either Check Number or Claim Payment Sequence Number'
        );
        validFlag = false;
      }
    }
    if (
      checkNumber !== null &&
      checkNumber !== undefined &&
      checkNumber !== ''
    ) {
      if (checkNumber.length !== 8) {
        setCnError('Check number must be 8 numbers long.');
        validFlag = false;
      } else if (checkNumeric(checkNumber)) {
        setCnError('Please enter a valid Check Number.');
        validFlag = false;
      }
    }
    if (cps !== null && cps !== undefined && cps !== '' && cps.trim() !== '') {
      if (
        checkNumber === null ||
        checkNumber === undefined ||
        checkNumber === ''
      ) {
        setCnError('Please Enter the Check Number.');
        validFlag = false;
      } else if (cps.length !== 8) {
        setCpsError('Claim Payment Sequence Number must be 8 characters');
        validFlag = false;
      } else if (checkNumeric(cps)) {
        setCpsError('Please enter a valid Claim Payment Sequence Number.');
        validFlag = false;
      }
    }
    return validFlag;
  };
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    return `${year}${month}`;
  };
  const handleClick = async () => {
    const valid = validate();
    if (valid) {
      try {
        let data = {
          fromBookMonth: fromBookYear + '' + fromBookMonth,
          toBookMonth: toBookYear + '' + toBookMonth,
          seriesDesignator: seriesDesignator,
          checkNumber: checkNumber || cps,
          usrShortName: msid,
        };
        const response = await axios
          .post(`${BASE_URL}cmdb-service/add-payment-data`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              let hash =
                response.data +
                '?' +
                formatDateString(fromBookMonth) +
                '?' +
                formatDateString(toBookMonth) +
                '?' +
                seriesDesignator +
                '?' +
                checkNumber +
                '?' +
                cps;
              navigate('/cmdb/correction-management/payment/payment-submit', {
                state: {
                  requestId: response.data,
                  fromBookMonth: fromBookMonth + '/' + fromBookYear,
                  toBookMonth: toBookMonth + '/' + toBookYear,
                  seriesDesignator: seriesDesignator,
                  cps: cps,
                  checkNumber: checkNumber,
                },
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const reset = () => {
    setCheckNumber('');
    setCnError('');
    setSeriesDesignator('');
    setSdError('');
    setCps('');
    setCpsError('');
    setFromBookMonth('');
    setFromBookYear('');
    setFbmError('');
    setToBookMonth('');
    setFromBookYear('');
    setTbmError('');
    setFbyError('');
    setTbyError('');
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/polStrucDropdownData/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setPaymentOptions(response.data);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (token) fetchDropdownData();
  }, [token]);
  const date = new Date();
  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Custom Correction Request
                  </Heading>
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  borderBottom: '1px solid #A9A9A9',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5} textAlign="center">
                    Payment Request
                  </Heading>
                  <Flex justify="end">
                    <Label size="md" id="first name">
                      {currentDateFormat(date)}
                    </Label>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid
                css={{ margin: '0px !important', marginTop: '20px !important' }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Required Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600 }}>Book Month:</Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>From Month</Text>
                  <SelectInput
                    label="From Month"
                    isClearable
                    isDisabled={readOnly}
                    value={fromBookMonth}
                    options={paymentOptions?.monthList?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    hideLabel
                    errorMessage={fbmError}
                    onChange={(date) => setFromBookMonth(date)}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <Text css={{ fontWeight: 600 }}>From Year</Text>

                  <SelectInput
                    label="From Year"
                    isClearable
                    isDisabled={readOnly}
                    value={fromBookYear}
                    options={years}
                    hideLabel
                    errorMessage={fbyError}
                    onChange={(date) => setFromBookYear(date)}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="To Month"
                    value={toBookMonth}
                    options={paymentOptions?.monthList?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    onChange={(date) => setToBookMonth(date)}
                    isClearable
                    isDisabled={readOnly}
                    errorMessage={tbmError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <SelectInput
                    label="To Year"
                    value={toBookYear}
                    options={years}
                    onChange={(date) => setToBookYear(date)}
                    isClearable
                    isDisabled={readOnly}
                    errorMessage={tbyError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Check Number"
                    value={checkNumber}
                    onChange={(e) => setCheckNumber(e.target.value)}
                    errorMessage={cnError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="CPS#"
                    value={cps}
                    onChange={(e) => setCps(e.target.value)}
                    errorMessage={cpsError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Series Designator"
                    value={seriesDesignator}
                    onChange={(e: any) => setSeriesDesignator(e.target.value)}
                    errorMessage={sdError}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Submit
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={reset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleBackButton}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(Payment);
