import { useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import * as XLSX from 'xlsx';
import { useRouter } from '@abyss/web/hooks/useRouter';

export const WrapperDataTable = ({ title, data, columns, fileName }) => {
  const { navigate } = useRouter();
  const handleModifySearch = () => {
    navigate('/cmdb/correction-management/inquiry-search');
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleModifySearch,
    },
  ];
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };

  const tableState = useDataTable({
    showColumnSort: true,
    showTopPagination: false,
    initialColumns: columns,
    showDownloadButton: true,
    showPagination: true,
    initialData:
      data === 'Not available' ||
      data === 'Data not found' ||
      data === 'No records found'
        ? []
        : data,

    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    customHeaderButtons,
  });

  useEffect(() => {
    if (data.length) {
      tableState.setData(data || [], true);
    }
  }, [data]);

  return <DataTable title={title} tableState={tableState} hideTitleHeader />;
};
