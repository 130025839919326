import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { getEnvironment } from '@src/utils/environmentUtil';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { constantColumns } from './column-constants';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { BASE_URL } from '@src/utils/endpoints';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
const MarketLocation = () => {
  const [updateRow, setUpdateRow] = useState({});
  const [marketNbr, setMarketNbr] = useState([]);
  const [marketNbrState, setMarketNbrState] = useState([]);
  const [originCd, setOriginCd] = useState([]);
  const [hmoAcctDiv, setHmoActDiv] = useState([]);
  const [legalEntity, setLegalEntity] = useState([]);
  const [locationCd, setLocationCd] = useState([]);
  const [majorMarket, setMajorMarket] = useState([]);
  const [saleOfcCd, setSaleOfcCd] = useState([]);
  const [marketNbrSelected, setMarketNbrSelected] = useState([]);
  const [marketNbrStateSelected, setMarketNbrStateSelected] = useState([]);
  const [originCdSelected, setOriginCdSelected] = useState([]);
  const [hmoAcctDivSelected, setHmoActDivSelected] = useState([]);
  const [legalEntitySelected, setLegalEntitySelected] = useState([]);
  const [locationCdSelected, setLocationCdSelected] = useState([]);
  const [majorMarketSelected, setMajorMarketSelected] = useState([]);
  const [saleOfcCdSelected, setSaleOfcCdSelected] = useState([]);
  const [payloadText, setPayloadText] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [text, setText] = useState('');
  const [json, setJson] = useState({});
  const [errorMsg, setErrorMsg] = useState('');

  const [marketNbrForm, setMarketNbrForm] = useState('');
  const [marketNbrPrfx, setMarketNbrPrfx] = useState('');
  const [marketNbrStateForm, setMarketNbrStateForm] = useState('');
  const [marketNbrSite, setMarketNbrSite] = useState('');
  const [siteNbr, setSiteNbr] = useState('');
  const [hcn, setHcn] = useState('');
  const [marketNameForm, setMarketNameForm] = useState('');
  const [originCdForm, setOriginCdForm] = useState('');
  const [hmoAcctDivForm, setHmoActDivForm] = useState('');
  const [legalEntityForm, setLegalEntityForm] = useState('');
  const [locationCdForm, setLocationCdForm] = useState('');
  const [majorMarketForm, setMajorMarketForm] = useState('');
  const [hmoPct, setHmoPct] = useState('');
  const [majMarketNm, setMajMarketNm] = useState('');

  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const data = [];

  const handleDynamicDropdown = async () => {
    try {
      let payload = [location?.state?.database];
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/market-location/getDropDowns`,
        payload,
        config
      );
      if (response.status === 200) {
        setJson(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (Object.keys(json).length > 0) {
      let marketNbr1 = json?.['marketNbrList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMarketNbr(marketNbr1);
      let marketNbrState1 = json?.['marketNbrStateList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMarketNbrState(marketNbrState1);
      let originCd1 = json?.['originCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setOriginCd(originCd1);
      let hmoacc = json?.['hmoAcctDivList'].map((item) => ({
        value: item,
        label: item,
      }));
      setHmoActDiv(hmoacc);
      let legalEntity1 = json?.['legalEntityList'].map((item) => ({
        value: item,
        label: item,
      }));
      setLegalEntity(legalEntity1);
      let locationcd1 = json?.['locationCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setLocationCd(locationcd1);
      let majorMarket1 = json?.['majorMarketList'].map((item) => ({
        value: item,
        label: item,
      }));
      setMajorMarket(majorMarket1);
      let sale = json?.['saleOfcCdList'].map((item) => ({
        value: item,
        label: item,
      }));
      setSaleOfcCd(sale);
    }
  }, [json]);
  useEffect(() => {
    let text1 =
      (marketNbrSelected?.length > 0
        ? 'MARKET_NBR - ' + marketNbrSelected.toString() + '\n'
        : '') +
      (marketNbrStateSelected?.length > 0
        ? 'MARKET_NBR_STATE - ' + marketNbrStateSelected.toString() + '\n'
        : '') +
      (originCdSelected?.length > 0
        ? 'ORIGIN_CD - ' + originCdSelected.toString() + '\n'
        : '') +
      (hmoAcctDivSelected?.length > 0
        ? 'HMO_ACCT_DIV - ' + hmoAcctDivSelected.toString() + '\n'
        : '') +
      (legalEntitySelected?.length > 0
        ? 'LEGAL_ENTITY - ' + legalEntitySelected.toString() + '\n'
        : '') +
      (locationCdSelected?.length > 0
        ? 'LOCATION_CD - ' + locationCdSelected.toString() + '\n'
        : '') +
      (majorMarketSelected?.length > 0
        ? 'MAJOR_MARKET - ' + majorMarketSelected.toString() + '\n'
        : '') +
      (saleOfcCdSelected?.length > 0
        ? 'SALE_OFC_CD - ' + saleOfcCdSelected.toString() + '\n'
        : '');
    let payload =
      (marketNbrSelected?.length > 0
        ? 'MARKET_NBR IN (' + marketNbrSelected.toString() + ') AND '
        : '') +
      (marketNbrStateSelected?.length > 0
        ? 'MARKET_NBR_STATE IN (' + marketNbrStateSelected.toString() + ') AND '
        : '') +
      (originCdSelected?.length > 0
        ? 'ORIGIN_CD IN (' +
          originCdSelected.map((item) => `'${item}'`).join(',') +
          ') AND '
        : '') +
      (hmoAcctDivSelected?.length > 0
        ? 'HMO_ACCT_DIV IN (' + hmoAcctDivSelected.toString() + ') AND '
        : '') +
      (legalEntitySelected?.length > 0
        ? 'LEGAL_ENTITY IN (' + legalEntitySelected.toString() + ') AND '
        : '') +
      (locationCdSelected?.length > 0
        ? 'LOCATION_CD IN (' + locationCdSelected.toString() + ') AND '
        : '') +
      (majorMarketSelected?.length > 0
        ? 'MAJOR_MARKET IN (' + majorMarketSelected.toString() + ') AND '
        : '') +
      (saleOfcCdSelected?.length > 0
        ? 'SALE_OFC_CD IN (' + saleOfcCdSelected.toString() + ') AND '
        : '');
    setPayloadText(payload);
    setText(text1);
  }, [
    marketNbrSelected,
    marketNbrStateSelected,
    originCdSelected,
    hmoAcctDivSelected,
    legalEntitySelected,
    locationCdSelected,
    majorMarketSelected,
    saleOfcCdSelected,
  ]);
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  //get column definition
  const columns = React.useMemo(
    () => constantColumns[location?.state?.systemSelectedValue],
    []
  );
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setHideAdd(true);
    setUpdateRow(row);
    setMarketNbrForm(row['MARKETNBR']);
    setMarketNbrPrfx(row['MARKETNBRPRFX']);
    setMarketNbrStateForm(row['MARKETNBRSTATE']);
    setMarketNbrSite(row['MARKETNBRSITE']);
    setSiteNbr(row['SITENBR1995']);
    setHcn(row['HCN1995']);
    setMarketNameForm(row['MARKETNAME']);
    setOriginCdForm(row['ORIGINCD']);
    setHmoActDivForm(row['HMOACCTDIV']);
    setLegalEntityForm(row['LEGALENTITY']);
    setLocationCdForm(row['LOCATIONCD']);
    setMajorMarketForm(row['MAJORMARKET']);
    setHmoPct(row['HMOPCT']);
    setMajMarketNm(row['MAJMARKETNM']);
  };
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      marketNbr: payload['MARKETNBR'],
      marketNbrPrfx: payload['MARKETNBRPRFX'],
      marketNbrState: payload['MARKETNBRSTATE'],
      marketNbrSite: payload['"MARKETNBRSITE'],
      siteNbr1995: payload['SITENBR1995'],
      hcn1995: payload['HCN1995'],
      marketName: payload['MARKETNAME'],
      originCd: payload['ORIGINCD'],
      hmoAcctDiv: payload['HMOACCTDIV'],
      legalEntity: payload['LEGALENTITY'],
      locationCd: payload['LOCATIONCD'],
      majorMarket: payload['MAJORMARKET'],
      hmoPct: payload['HMOPCT'],
      majMarketNm: payload['MAJMARKETNM'],
      saleOfcCd: payload['SALEOFCCD'],
      lstUpdtDt: payload['LSTUPDTDT'],
      lstUpdtTm: payload['LSTUPDTTM'],
      lstUpdtId: payload['LSTUPDTID'],
      updtInd: payload['UPDTIND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/market/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        getTableData1();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRows(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: data,
    initialColumns: columns,
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const setData = async (tableData) => {
    let columnKeys = [];
    let columns = {};
    if (tableData?.length > 0) {
      columnKeys = Object.keys(tableData?.[0]);
      columns = columnKeys.map((key, index) => ({
        Header: key,
        accessor: key,
      }));
    }
    if (Array.isArray(tableData)) {
      getTableState.setColumns(columns, true);
      getTableState.setData(tableData, true);
    }
  };
  const onLookup = async () => {
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/market/getMarketList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = {
      selectedValues: payloadText.substring(0, payloadText.length - 4),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/market/getMarketList/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
        handleDynamicDropdown();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const handleClick = () => {
    navigate(-1);
  };
  const allMarketData = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload = [location?.state?.database];
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/market/getallMarketList/`,
        payload,
        config
      );
      if (response.status === 200) {
        await setData(response.data);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const clear = async () => {
    setMarketNbrSelected([]);
    setMarketNbrStateSelected([]);
    setOriginCdSelected([]);
    setHmoActDivSelected([]);
    setLegalEntitySelected([]);
    setLocationCdSelected([]);
    setMajorMarketSelected([]);
    setSaleOfcCdSelected([]);
    setText('');
    setPayloadText('');
    await allMarketData();
  };
  const update = async () => {
    const now = new Date();
    setHideAdd(false);
    setFlag(false);
    let payload = {
      oldRecord: {
        marketNbr: updateRow['MARKETNBR'],
        marketNbrPrfx: updateRow['MARKETNBRPRFX'],
        marketNbrState: updateRow['MARKETNBRSTATE'],
        marketNbrSite: updateRow['MARKETNBRSITE'],
        siteNbr1995: updateRow['SITENBR1995'],
        hcn1995: updateRow['HCN1995'],
        marketName: updateRow['MARKETNAME'],
        originCd: updateRow['ORIGINCD'],
        hmoAcctDiv: updateRow['HMOACCTDIV'],
        legalEntity: updateRow['LEGALENTITY'],
        locationCd: updateRow['LOCATIONCD'],
        majorMarket: updateRow['MAJORMARKET'],
        hmoPct: updateRow['HMOPCT'],
        majMarketNm: updateRow['MAJMARKETNM'],
        saleOfcCd: updateRow['SALEOFCCD'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LSTUPDTTM'],
        lstUpdtId: updateRow['LSTUPDTID'],
        updtInd: updateRow['UPDTIND'],
      },
      newRecord: {
        marketNbr: marketNbrForm,
        marketNbrPrfx: marketNbrPrfx,
        marketNbrState: marketNbrStateForm,
        marketNbrSite: marketNbrSite,
        siteNbr1995: siteNbr,
        hcn1995: hcn,
        marketName: marketNameForm,
        originCd: originCdForm,
        hmoAcctDiv: hmoAcctDivForm,
        legalEntity: legalEntityForm,
        locationCd: locationCdForm,
        majorMarket: majorMarketForm,
        hmoPct: hmoPct,
        majMarketNm: majMarketNm,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: userName,
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
    };
    setMarketNbrForm('');
    setMarketNbrPrfx('');
    setMarketNbrStateForm('');
    setMarketNbrSite('');
    setSiteNbr('');
    setHcn('');
    setMarketNameForm('');
    setOriginCdForm('');
    setHmoActDivForm('');
    setLegalEntityForm('');
    setLocationCdForm('');
    setMajorMarketForm('');
    setHmoPct('');
    setMajMarketNm('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/market/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        getTableData1();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const save = async () => {
    setHideUpdate(false);
    const now = new Date();
    setFlag(false);
    let payload = {
      marketNbr: marketNbrForm,
      marketNbrPrfx: marketNbrPrfx,
      marketNbrState: marketNbrStateForm,
      marketNbrSite: marketNbrSite,
      siteNbr1995: siteNbr,
      hcn1995: hcn,
      marketName: marketNameForm,
      originCd: originCdForm,
      hmoAcctDiv: hmoAcctDivForm,
      legalEntity: legalEntityForm,
      locationCd: locationCdForm,
      majorMarket: majorMarketForm,
      hmoPct: hmoPct,
      majMarketNm: majMarketNm,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: userName,
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setMarketNbrForm('');
    setMarketNbrPrfx('');
    setMarketNbrStateForm('');
    setMarketNbrSite('');
    setSiteNbr('');
    setHcn('');
    setMarketNameForm('');
    setOriginCdForm('');
    setHmoActDivForm('');
    setLegalEntityForm('');
    setLocationCdForm('');
    setMajorMarketForm('');
    setHmoPct('');
    setMajMarketNm('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/market/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        getTableData1();
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setMarketNbrForm('');
    setMarketNbrPrfx('');
    setMarketNbrStateForm('');
    setMarketNbrSite('');
    setSiteNbr('');
    setHcn('');
    setMarketNameForm('');
    setOriginCdForm('');
    setHmoActDivForm('');
    setLegalEntityForm('');
    setLocationCdForm('');
    setMajorMarketForm('');
    setHmoPct('');
    setMajMarketNm('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '0px',
                  marginBottom: '14px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Grid>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Market_Nbr"
                    value={marketNbrSelected}
                    onChange={(e) => setMarketNbrSelected(e)}
                    options={marketNbr}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Market_Nbr_State"
                    value={marketNbrStateSelected}
                    onChange={(e) => setMarketNbrStateSelected(e)}
                    options={marketNbrState}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Origin_Cd"
                    value={originCdSelected}
                    onChange={(e) => setOriginCdSelected(e)}
                    options={originCd}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Hmo_Acct_Div"
                    value={hmoAcctDivSelected}
                    onChange={(e) => setHmoActDivSelected(e)}
                    options={hmoAcctDiv}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Legal_Entity"
                    value={legalEntitySelected}
                    onChange={(e) => setLegalEntitySelected(e)}
                    options={legalEntity}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Location_Cd"
                    value={locationCdSelected}
                    onChange={(e) => setLocationCdSelected(e)}
                    options={locationCd}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Major_Market"
                    value={majorMarketSelected}
                    onChange={(e) => setMajorMarketSelected(e)}
                    options={majorMarket}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <SelectInputMulti
                    label="Sale_Ofc_Cd"
                    value={saleOfcCdSelected}
                    onChange={(e) => setSaleOfcCdSelected(e)}
                    options={saleOfcCd}
                    placeholder="Select"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4 }}>
                  <Button css={{ marginTop: '25px' }} onClick={onLookup}>
                    Lookup
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 10 }}>
                  <TextInputArea value={text} rows={8} />
                </Grid.Col>
                <Grid.Col span={{ xs: 2 }}>
                  <Button css={{ marginTop: '15px' }} onClick={clear}>
                    Clear
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid css={{ marginTop: '15px', marginLeft: '0px' }}>
                <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                  Database:
                </Text>
                <span>
                  {getEnvironment()} - {location?.state?.database}
                </span>

                <Text
                  css={{
                    fontWeight: 600,
                    fontSize: '18px',
                    marginLeft: '20px',
                  }}
                >
                  Table:
                </Text>
                <span>{location?.state?.systemSelectedValue}</span>
                <Button
                  variant="tertiary"
                  onClick={addRow}
                  isDisabled={hideAdd}
                  css={{ marginTop: '-10px' }}
                >
                  + Add Row
                </Button>

                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr"
                          value={marketNbrForm}
                          onChange={(e) => setMarketNbrForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr_Prfx"
                          value={marketNbrPrfx}
                          onChange={(e) => setMarketNbrPrfx(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr_State"
                          value={marketNbrStateForm}
                          onChange={(e) =>
                            setMarketNbrStateForm(e.target.value)
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Nbr_Site"
                          value={marketNbrSite}
                          onChange={(e) => setMarketNbrSite(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Site_Nbr_1995"
                          value={siteNbr}
                          onChange={(e) => setSiteNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Hcn_1995"
                          value={hcn}
                          onChange={(e) => setHcn(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Market_Name"
                          value={marketNameForm}
                          onChange={(e) => setMarketNameForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Origin_Cd"
                          value={originCdForm}
                          onChange={(e) => setOriginCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Hmo_Acct_Div"
                          value={hmoAcctDivForm}
                          onChange={(e) => setHmoActDivForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Legal_Entity"
                          value={legalEntityForm}
                          onChange={(e) => setLegalEntityForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Location_Cd"
                          value={locationCdForm}
                          onChange={(e) => setLocationCdForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Major_Market"
                          value={majorMarketForm}
                          onChange={(e) => setMajorMarketForm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Hmo_Pct"
                          value={hmoPct}
                          onChange={(e) => setHmoPct(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="Maj_Market_Nm"
                          value={majMarketNm}
                          onChange={(e) => setMajMarketNm(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button onClick={updateFlag ? update : save}>
                          Save
                        </Button>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                        <Button onClick={close}>Cancel</Button>
                      </Grid.Col>
                    </Grid>
                  </>
                )}
              </Grid>
              <br />
              <span style={{ marginLeft: '0px' }}>
                {location?.state?.data?.length || 0} Records
              </span>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default MarketLocation;
