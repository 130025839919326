import {
  SET_FILE_DATA,
  SET_PAYLOAD_POLICY_DETAILS,
  SET_TRANS_DATA,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS,
  UPDATE_AUTOMATED_FEED_SEARCH,
  UPDATE_AUTOMATED_FEED_SEARCH_ERROR,
  UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS,
} from '@src/redux/action/FADB/inquiry/automatedFeeds/automatedConstants';

const initialState = {
  feedData: [],

  error: null,
  data: {},
  policyData: {},
  findData: {},
  fileData: [],
  transData: [],
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AUTOMATED_FEED_SEARCH:
      return { ...state, feedData: action.feedData };
    case UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS:
      return { ...state, feedData: action.feedData };
    case UPDATE_AUTOMATED_FEED_SEARCH_ERROR:
      return { ...state, error: action.error };
    case UPDATE_AUTOMATED_FEED_POLICY_DETAILS:
      return { ...state, policyData: action.policyData };
    case UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS:
      return { ...state, policyData: action.policyData };
    case UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR:
      return { ...state, error: action.error };
    case UPDATE_AUTOMATED_FEED_ERROR_CORRECTION:
      return { ...state, findData: action.findData };
    case UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS:
      return { ...state, findData: action.findData };
    case UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR:
      return { ...state, error: action.error };
    case SET_PAYLOAD_POLICY_DETAILS:
      return {
        ...state,
        data: action.data,
      };
    case SET_FILE_DATA:
      return {
        ...state,
        fileData: action.fileData,
      };
    case SET_TRANS_DATA:
      return {
        ...state,
        transData: action.transData,
      };
    default:
      return state;
  }
}
