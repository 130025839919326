import { BASE_URL , API_BASE_URL} from '@src/utils/endpoints';
import axios from 'axios';
const api = axios.create({
  baseURL: BASE_URL,
});

export function automatedFeedSearchApi(feedObject, config) {
  return api.post(`fadb-service/auto-feed/search`, feedObject, config);
}
export function automatedFeedPolicyApi(payload, config) {
  return api.post('fadb-service/auto-feed/policy-details', payload, config);
}
export function automatedFeedErrorCorrectionApi(findData, config) {
  return api.post(`fadb-service/auto-feed/error-correction`, findData, config);
}
export function automatedFeedDynamicApi(config) {
  return api.get(`static/autoFeedsData/automatedFeedStaticData`, config);
}
