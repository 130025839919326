import { takeLatest, put, call } from 'redux-saga/effects';
import {
  LOOK_UP_POLICY,
  LOOK_UP_POLICY_SUCCESS,
  LOOK_UP_POLICY_ERROR,
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_ERROR,
  FETCH_LEGAL_ENTITIES_REQUEST,
  FETCH_LEGAL_ENTITIES_SUCCESS,
  FETCH_LEGAL_ENTITIES_FAILURE,
  FETCH_DYNAMIC_DROPDOWN_REQUEST,
  FETCH_DYNAMIC_DROPDOWN_SUCCESS,
  FETCH_DYNAMIC_DROPDOWN_FAILURE,
} from '../../../action/FADB/policy/policyConstants';

import {
  lookUpPolicyApi,
  updatePolicyApi,
  referencePolicyApi,
  referencesDynamicApi,
} from '../../../api/FADB/policy/policyApi';
import { take } from 'lodash';

function* lookUpPolicySaga(action) {
  try {
    const policyData = yield call(lookUpPolicyApi, action.policyNumber);
    yield put({ type: LOOK_UP_POLICY_SUCCESS, policyData });
  } catch (error) {
    yield put({ type: LOOK_UP_POLICY_ERROR, error });
  }
}

function* updatePolicySaga(action) {
  try {
    const policyData = yield call(updatePolicyApi, action.policyData);
    yield put({ type: UPDATE_POLICY_SUCCESS, policyData });
  } catch (error) {
    yield put({ type: UPDATE_POLICY_ERROR, error });
  }
}

function* fetchLegalEntitiesSaga(action) {
  try {
    const legalEntities = yield call(referencePolicyApi);
    yield put({ type: FETCH_LEGAL_ENTITIES_SUCCESS, legalEntities });
  } catch (error) {
    yield put({ type: FETCH_LEGAL_ENTITIES_FAILURE, error });
  }
}

function* fetchDynamicDropdownSaga(action) {
  try {
    const dynamicData = yield call(referencesDynamicApi);
    yield put({ type: FETCH_DYNAMIC_DROPDOWN_SUCCESS, dynamicData });
  } catch (error) {
    yield put({ type: FETCH_DYNAMIC_DROPDOWN_FAILURE, error });
  }
}

export default function* policySaga() {
  yield takeLatest(LOOK_UP_POLICY, lookUpPolicySaga);
  yield takeLatest(UPDATE_POLICY, updatePolicySaga);
  yield takeLatest(FETCH_LEGAL_ENTITIES_REQUEST, fetchLegalEntitiesSaga);
  yield takeLatest(FETCH_DYNAMIC_DROPDOWN_REQUEST, fetchDynamicDropdownSaga);
}
