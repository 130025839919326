// withAuth.js
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useRouter } from '@abyss/web/hooks/useRouter';

const withAuth = (WrappedComponent) => {
  const AuthenticatedComponent = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { navigate } = useRouter();

    if (!isAuthenticated) {
      navigate('/');
      return null;
    }

    return <WrappedComponent />;
  };

  return AuthenticatedComponent;
};

export default withAuth;
