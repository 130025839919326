import React, { useState, useEffect, useSyncExternalStore } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Footer } from '@src/components/Footer';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Heading } from '@abyss/web/ui/Heading';
import { DateInput } from '@abyss/web/ui/DateInput';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import { formateDate } from '@src/utils/dateUtil';

import {
  updateInquirySearch,
  updateInquirySearchError,
  updateInquirySearchSuccess,
  setPayload,
} from '@src/redux/action/CMDB/suspectErrror/inquiry/inquiryAPI';
import { inquirySearchApi } from '@src/redux/api/CMDB/suspectError/inquiry/inquiryAPI';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectableList } from './SelectableList';

const Inquiry = () => {
  const dispatch = useDispatch();
  const { token, readOnly } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { navigate } = useRouter();

  const [reportType, setReportType] = useState('');
  const [displayLevel, setDisplayLevel] = useState('');
  const [statusRadio, setStatusRadio] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [suffixCode, setSuffixCode] = useState('');
  const [accountCode, setAccountCode] = useState('');
  const [addedPolicyNumber, setAddedPolicyNumber]: any = useState([]);
  const [addedSuffixCode, setAddedSuffixCode]: any = useState([]);
  const [addedAccountCode, setAddedAccountCode]: any = useState([]);
  const [claimSourceCode, setClaimSourceCode] = useState();
  const [errorCode, setErrorCode] = useState('');
  const [priorityLevel, setPriorityLevel] = useState('');
  const [dollarInd, setDollarInd] = useState('');
  const [dollarAmount, setDollarAmount] = useState('');
  const [seriesDesignator, setSeriesDesignator] = useState('');
  const [chargeDate, setChargeDate] = useState('');
  const [chargeDateFrom, setChargeDateFrom] = useState('');
  const [chargeDateTo, setChargeDateTo] = useState('');
  const [subscriberId, setSubscriberId] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [addedSubscriberId, setAddedSubscriberId]: any = useState([]);
  const [addedSeriesDesignator, setAddedSeriesDesignator]: any = useState([]);
  const [csp, setCsp] = useState('');
  const [legalEntity, setLegalEntity] = useState('');
  const [glReinsurance, setGlReinsurance] = useState('');
  const [legalEntityGroup, setLegalEntityGroup] = useState('');
  const [fundingArrangment, setFundingArrangement] = useState('');
  const [toDateChangeErr, setToDateChangeErr] = useState('');
  const [inquiryDropdownOptions, setInquiryDropDownOptions]: any = useState([]);
  const id = 'suspectErrorDropdown';

  const [errors, setErrors]: any = useState({});
  const [isValid, setIsValid]: any = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenSuffix, setIsopenSuffix] = useState(false);
  const [isOpenAccount, setISOpenAccount] = useState(false);
  const [selectedPolicyNumbers, setSelectedPolicyNumbers] = useState<number[]>(
    []
  );
  const [selectedSuffixCode, setSelectedSuffixCode] = useState<number[]>([]);
  const [selectedAccountCode, setSelectedAccountCode] = useState<number[]>([]);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState<number[]>(
    []
  );
  const [selectedSeriesDesignator, setSelectedSeriesDesignator] = useState<
    number[]
  >([]);

  const handleChange = (name, value) => {
    switch (name) {
      case 'reportType':
        setReportType(value);
        break;
      case 'displayLevel':
        setDisplayLevel(value);
        break;
      case 'statusRadio':
        setStatusRadio(value);
        break;

      case 'claimSourceCode':
        setClaimSourceCode(value);
        break;

      case 'errorCode':
        setErrorCode(value);
        break;
      case 'priorityLevel':
        setPriorityLevel(value);
        break;
      case 'dollarInd':
        setDollarInd(value);
        break;
      case 'dollarAmount':
        setDollarAmount(value);
        break;

      case 'chargeDateFrom':
        setChargeDateFrom(value);
        break;

      case 'chargeDateTo':
        setChargeDateTo(value);
        break;

      case 'checkNumber':
        setCheckNumber(value);
        break;

      case 'csp':
        setCsp(value);
        break;

      case 'legalEntity':
        setLegalEntity(value);
        break;
      case 'glReinsurance':
        setGlReinsurance(value);
        break;

      case 'legalEntityGroup':
        setLegalEntityGroup(value);
        break;

      case 'fundingArrangment':
        setFundingArrangement(value);
        break;

      default:
        break;
    }
  };

  const chargedDate = { from: chargeDateFrom, to: chargeDateTo };

  const payload = {
    policyNumber: addedPolicyNumber.join(','),
    suffixCode: addedSuffixCode.join(','),
    accountNumber: addedAccountCode.join(','),
    reportType: reportType,
    displayType: displayLevel,
    errorCode: errorCode,
    prorityLvl: priorityLevel,
    dollarAmount: dollarAmount,
    dollarInd: dollarInd,
    claimSrcCode: claimSourceCode,
    claimSrcCodedis: '',
    subscriberId: subscriberId,
    seriesDesg: addedSeriesDesignator.join(','),
    chargedDate,
    checkNumber: checkNumber,
    csp: csp,
    lglEntity: legalEntity,
    glReInsurance: null,
    lglEntityGrp: legalEntityGroup,
    fundingArrangement: null,
    fndgInsType: null,
    type: 'O',
  };

  const handleValidation = () => {
    const errors: any = {};
    setToDateChangeErr('');
    let isValid = true;

    if (chargeDateFrom != null && chargeDateTo != null) {
      if (formateDate(chargeDateFrom) > formateDate(chargeDateTo)) {
        setToDateChangeErr('From Charge Date not before To Date.');
        isValid = false;
      }
    }
    if (!reportType) {
      errors.reportType = 'ReportType is a required';
      isValid = false;
    }

    if (!displayLevel) {
      errors.displayLevel = 'Display Level is a required';
      isValid = false;
    }
    if (
      !(
        errorCode ||
        addedPolicyNumber.length !== 0 ||
        addedSuffixCode.length !== 0 ||
        addedAccountCode.length !== 0 ||
        priorityLevel ||
        dollarAmount ||
        claimSourceCode ||
        chargeDate ||
        addedSubscriberId.length !== 0 ||
        checkNumber ||
        legalEntity ||
        glReinsurance ||
        legalEntityGroup ||
        fundingArrangment
      )
    ) {
      setIsOpen(true);
      isValid = false;
    }
    if (
      (reportType == 'CS' &&
        displayLevel == 'S' &&
        addedSuffixCode.length > 0 &&
        addedAccountCode.length > 0) ||
      (reportType == 'EC' &&
        displayLevel == 'S' &&
        addedSuffixCode.length > 0 &&
        addedAccountCode.length > 0) ||
      (reportType == 'CLS' &&
        displayLevel == 'S' &&
        addedSuffixCode.length > 0 &&
        addedAccountCode.length > 0) ||
      (reportType == 'CS' &&
        displayLevel == 'P' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length > 0) ||
      (reportType == 'CS' &&
        displayLevel == 'A' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length > 0) ||
      (reportType == 'EC' &&
        displayLevel == 'P' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length > 0) ||
      (reportType == 'CLS' &&
        displayLevel == 'P' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length > 0) ||
      (reportType == 'CLS' &&
        displayLevel == 'A' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length > 0) ||
      (reportType === 'CS' &&
        displayLevel === 'S' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'CS' &&
        displayLevel === 'P' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'CS' &&
        displayLevel === 'A' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'EC' &&
        displayLevel === 'P' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'EC' &&
        displayLevel === 'S' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'EC' &&
        displayLevel === 'A' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'CLS' &&
        displayLevel === 'P' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'CLS' &&
        displayLevel === 'S' &&
        addedSuffixCode.length > 0) ||
      (reportType === 'CLS' &&
        displayLevel === 'A' &&
        addedSuffixCode.length > 0)
    ) {
      if (!(addedPolicyNumber.length !== 0 || errorCode)) {
        setIsopenSuffix(true);
        isValid = false;
      }
    }

    if (
      (reportType == 'CS' &&
        displayLevel == 'P' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'EC' &&
        displayLevel == 'A' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'EC' &&
        displayLevel == 'P' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'EC' &&
        displayLevel == 'S' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'CLS' &&
        displayLevel == 'A' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'CLS' &&
        displayLevel == 'P' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'CLS' &&
        displayLevel == 'S' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'CS' &&
        displayLevel == 'A' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0) ||
      (reportType == 'CS' &&
        displayLevel == 'S' &&
        addedAccountCode.length > 0 &&
        addedSuffixCode.length === 0)
    ) {
      if (
        !(
          addedPolicyNumber.length !== 0 ||
          errorCode ||
          addedSubscriberId.length !== 0 ||
          legalEntityGroup ||
          fundingArrangment
        )
      ) {
        setISOpenAccount(true);
        isValid = false;
      }
    }

    setErrors(errors);
    setIsValid(isValid);
    return isValid;
  };

  const handlePageSubmit = async () => {
    if (handleValidation()) {
      try {
        dispatch(setPayload(payload));
        dispatch(updateInquirySearch(payload));
        const response = await inquirySearchApi(payload, config);
        if (response.status === 200) {
          dispatch(updateInquirySearchSuccess(response.data));
          navigate('/cmdb/suspect-errors/inquiry-search');
        }
      } catch (error) {
        dispatch(updateInquirySearchError(error));
      }
    }
  };

  const handleCancel = () => {
    setReportType(''),
      setFundingArrangement(''),
      setLegalEntityGroup(''),
      setGlReinsurance(''),
      setLegalEntity(''),
      setCsp(''),
      setCheckNumber(''),
      setChargeDateTo(''),
      setChargeDateFrom(''),
      setDollarAmount(''),
      setDollarInd(''),
      setPriorityLevel(''),
      setErrorCode(''),
      setClaimSourceCode(undefined),
      setStatusRadio(''),
      setDisplayLevel(''),
      setAddedPolicyNumber([]),
      setAddedSuffixCode([]),
      setAddedSeriesDesignator([]),
      setAddedAccountCode([]),
      setAddedSubscriberId([]);
  };

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}cmdb-service/suspectErrDropdown/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response && response.data) {
        setInquiryDropDownOptions(response.data);
      } else {
        console.error('No data found');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) fetchDropdownData();
  }, [token]);

  const handlePolicyNumberChange = (e) => {
    setPolicyNumber(e.target.value);
  };

  const handleSuffixCodeChange = (e) => {
    setSuffixCode(e.target.value);
  };
  const handleAccountCodeChange = (e) => {
    setAccountCode(e.target.value);
  };
  const handleSubscriberIdChange = (e) => {
    setSubscriberId(e.target.value);
  };
  const handleSeriesDesignatorChange = (e) => {
    setSeriesDesignator(e.target.value);
  };
  const handleAddPolicyNumber = () => {
    let errors: any = {};
    if (policyNumber) {
      const regEx: RegExp = /^\d{2}[A-Za-z0-9]\d{4}$/;

      let isValid = policyNumber.match(regEx);
      const exists = addedPolicyNumber.some(
        (option) => option === policyNumber
      );

      if (policyNumber.length == 7) {
        if (!isValid) {
          errors.policyNumber =
            'Policy Number must be numeric, Except for the 3rd position.';
          setErrors(errors);
          return;
        }
      } else {
        errors.policyNumber = 'Policy Number must be 7 digit Number';
        setErrors(errors);
        return;
      }

      if (exists) {
        errors.policyNumber = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedPolicyNumber((prev) => [
        ...prev,
        policyNumber.toLocaleUpperCase(),
      ]);
      setPolicyNumber('');
      setErrors('');
    } else {
      errors.policyNumber = 'Please enter the Policy Number';
      setErrors(errors);
    }
  };

  const handleAddSuffixCode = () => {
    let errors: any = {};
    if (suffixCode) {
      const isValid = /^[A-Za-z]{2}$/.test(suffixCode);
      const exists = addedSuffixCode.some((option) => option === suffixCode);

      if (!isValid) {
        errors.suffixCode = 'Suffix code must be 2 digit alphabet';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.suffixCode = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedSuffixCode((prev) => [...prev, suffixCode.toUpperCase()]);
      setSuffixCode('');
      setErrors('');
    } else {
      errors.suffixCode = 'Please enter the Suffix Code';
      setErrors(errors);
    }
  };

  const handleAddAccountCode = () => {
    let errors: any = {};
    if (accountCode) {
      const isValid = /^\d{3}$/.test(accountCode);
      const exists = addedAccountCode.some((option) => option === accountCode);
      if (!isValid) {
        errors.accountCode = 'Account code must be 3 digit alphabet';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.accountCode = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedAccountCode((prev) => [...prev, accountCode]);
      setAccountCode('');
      setErrors('');
    } else {
      errors.accountCode = 'Please enter the Account Code';
      setErrors(errors);
    }
  };

  const handleAddSubId = () => {
    let errors: any = {};
    if (subscriberId) {
      const isValid = /^\d{9}$/.test(subscriberId);
      const exists = addedSubscriberId.some(
        (option) => option === subscriberId
      );

      if (!isValid) {
        errors.subscriberId = 'Subscriber ID number should be 9 digit number';
        setErrors(errors);
        return;
      }
      if (exists) {
        errors.subscriberId = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }

      setAddedSubscriberId((prev) => [...prev, subscriberId]);
      setSubscriberId('');
      setErrors('');
    } else {
      errors.subscriberId = ' Please enter the Subscriber Id';
      setErrors(errors);
    }
  };

  const handleAddSeriesId = () => {
    let errors: any = {};
    if (seriesDesignator) {
      const isValid = /^\d{2}$/.test(seriesDesignator);

      if (!isValid) {
        errors.seriesDesignator = 'Series Designator must be 2 digit';
        setErrors(errors);
        return;
      }

      setAddedSeriesDesignator((prev) => [
        ...prev,
        `${seriesDesignator}-All-All`,
      ]);
      setSeriesDesignator('');
      setErrors('');
    } else {
      errors.seriesDesignator = 'Please enter the Series Designator';
      setErrors(errors);
    }
  };

  const handleSelectItem = (index, selectedItem, setSelectedItem) => {
    if (!selectedItem.includes(index)) {
      setSelectedItem([index]);
    } else {
      setSelectedItem((prev) => prev.filter((item) => item !== index));
    }
  };

  const removeSelectedItem = (
    addedItem,
    setAddedItem,
    selectedItem,
    setSelectedItem
  ) => {
    const updatedItem = addedItem.filter(
      (_, index) => !selectedItem.includes(index)
    );
    setAddedItem(updatedItem);
    setSelectedItem([]);
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Heading css={{ textAlign: 'center' }} offset={5}>
                Suspect Error Inquiry Search Criteria
              </Heading>
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="ReportType:"
                    display="row"
                    onChange={(e) => handleChange('reportType', e.target.value)}
                    errorMessage={errors.reportType}
                    value={reportType}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Case Structure" value="CS" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Error Code" value="EC" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Claim Source" value="CLS" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <RadioGroup
                    label="Display Level:"
                    display="row"
                    onChange={(e) => {
                      handleChange('displayLevel', e.target.value);
                    }}
                    value={displayLevel}
                    errorMessage={errors.displayLevel}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Policy" value="P" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Suffix" value="S" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Account" value="A" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
              </Grid>
              <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading css={{ textAlign: 'center' }} offset={5}>
                    General Information
                  </Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Policy Number"
                    value={policyNumber}
                    onChange={handlePolicyNumberChange}
                    errorMessage={errors.policyNumber}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddPolicyNumber}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Suffix Code"
                    value={suffixCode}
                    onChange={handleSuffixCodeChange}
                    errorMessage={errors.suffixCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSuffixCode}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Account Code"
                    value={accountCode}
                    onChange={handleAccountCodeChange}
                    errorMessage={errors.accountCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddAccountCode}
                  >
                    Add
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedPolicyNumber}
                    selectedItems={selectedPolicyNumbers}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedPolicyNumbers,
                        setSelectedPolicyNumbers
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedPolicyNumber,
                        setAddedPolicyNumber,
                        selectedPolicyNumbers,
                        setSelectedPolicyNumbers
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSuffixCode}
                    selectedItems={selectedSuffixCode}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSuffixCode,
                        setSelectedSuffixCode
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSuffixCode,
                        setAddedSuffixCode,
                        selectedSuffixCode,
                        setSelectedSuffixCode
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedAccountCode}
                    selectedItems={selectedAccountCode}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedAccountCode,
                        setSelectedAccountCode
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedAccountCode,
                        setAddedAccountCode,
                        selectedAccountCode,
                        setSelectedAccountCode
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
              </Grid>
              <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Error Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={errorCode}
                    options={inquiryDropdownOptions?.errorCodeList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    onChange={(value) => {
                      handleChange('errorCode', value);
                    }}
                    errorMessage={errors.errorCode}
                    isDisabled={readOnly}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 4 }}>
                  <SelectInput
                    label="Priority Level"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={[
                      { id: 1, label: '01', value: '01' },
                      { id: 2, label: '02', value: '02' },
                      { id: 3, label: '03', value: '03' },
                      { id: 4, label: '07', value: '07' },
                      { id: 5, label: '08', value: '08' },
                      { id: 6, label: '09', value: '09' },
                    ]}
                    isDisabled={readOnly}
                    value={priorityLevel}
                    onChange={(value) => {
                      handleChange('priorityLevel', value);
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <RadioGroup
                    label="Dollar Amount"
                    display="column"
                    onChange={(e) => handleChange('dollarInd', e.target.value)}
                    value={dollarInd}
                    isDisabled={readOnly}
                  >
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Over" value="over" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                      <RadioGroup.Radio label="Under" value="under" />
                    </Grid.Col>
                  </RadioGroup>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="$"
                    onChange={(e) =>
                      handleChange('dollarAmount', e.target.value)
                    }
                    value={dollarAmount}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Claim Source Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    isDisabled={readOnly}
                    value={claimSourceCode}
                    onChange={(value) => {
                      handleChange('claimSourceCode', value);
                    }}
                    options={inquiryDropdownOptions?.sourceSystemList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label="Charge Date From"
                    value={chargeDateFrom}
                    subText={' '}
                    onChange={(date) => {
                      handleChange('chargeDateFrom', date);
                    }}
                    isDisabled={readOnly}
                    errorMessage={toDateChangeErr}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <DateInput
                    label="Charge Date To"
                    value={chargeDateTo}
                    subText={' '}
                    onChange={(date) => {
                      handleChange('chargeDateTo', date);
                    }}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 4, md: 4, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Subscriber ID (SSN)"
                    value={subscriberId}
                    onChange={handleSubscriberIdChange}
                    isDisabled={readOnly}
                    errorMessage={errors.subscriberId}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSubId}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <TextInput
                    label="Series Designator"
                    value={seriesDesignator}
                    onChange={handleSeriesDesignatorChange}
                    isDisabled={readOnly}
                    errorMessage={errors.seriesDesignator}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '1.5rem',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.9rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleAddSeriesId}
                  >
                    Add
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="Check Number"
                    value={checkNumber}
                    onChange={(e) =>
                      handleChange('checkNumber', e.target.value)
                    }
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSubscriberId}
                    selectedItems={selectedSubscriberId}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSubscriberId,
                        setSelectedSubscriberId
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSubscriberId,
                        setAddedSubscriberId,
                        selectedSubscriberId,
                        setSelectedSubscriberId
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <SelectableList
                    items={addedSeriesDesignator}
                    selectedItems={selectedSeriesDesignator}
                    onSelectItem={(index) =>
                      handleSelectItem(
                        index,
                        selectedSeriesDesignator,
                        setSelectedSeriesDesignator
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Button
                    isDisabled={readOnly}
                    onClick={() =>
                      removeSelectedItem(
                        addedSeriesDesignator,
                        setAddedSeriesDesignator,
                        selectedSeriesDesignator,
                        setSelectedSeriesDesignator
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid.Col>
                <RadioGroup
                  label=""
                  display="column"
                  onChange={(e) => handleChange('statusRadio', e.target.value)}
                  value={statusRadio}
                  isDisabled={readOnly}
                >
                  <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                    <RadioGroup.Radio label="Complete" value="complete" />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                    <RadioGroup.Radio label="Partial" value="partial" />
                  </Grid.Col>
                </RadioGroup>
                <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                  <TextInput
                    label="CPS#"
                    value={csp}
                    onChange={(e) => handleChange('csp', e.target.value)}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading css={{ textAlign: 'center' }} offset={5}>
                    Financial Tags
                  </Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Legal Entity"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={legalEntity}
                    onChange={(value) => handleChange('legalEntity', value)}
                    options={inquiryDropdownOptions?.legalEntity?.map(
                      (col) => ({
                        value: col,
                        label: col,
                      })
                    )}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="GL Reinsurance"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={glReinsurance}
                    onChange={(value) => handleChange('glReinsurance', value)}
                    options={inquiryDropdownOptions?.reInsTyp?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Legal Entity Group"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={legalEntityGroup}
                    isDisabled={readOnly}
                    onChange={(value) =>
                      handleChange('legalEntityGroup', value)
                    }
                    // options={[
                    //   { value: 'none', label: 'None' },
                    //   { value: 'Other', label: 'Other' },
                    // ]}
                    options={inquiryDropdownOptions?.legalEntityGrpCd?.map(
                      (col) => ({
                        value: col,
                        label: col,
                      })
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Funding Arrangement"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    value={fundingArrangment}
                    isDisabled={readOnly}
                    onChange={(value) =>
                      handleChange('fundingArrangment', value)
                    }
                    options={inquiryDropdownOptions?.fndCd?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handlePageSubmit}
                  >
                    Search
                  </Button>

                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
      {isOpen && (
        <Modal
          title="Please correct the following errors"
          isOpen={isOpen}
          size={'md'}
          onClose={() => setIsOpen(false)}
        >
          <Modal.Section>
            Please enter at least one of the following criteria:
            <br />
            Policy Number, Suffix Code, Account Code,
            <br />
            Error Code
            <br />
            Priority Level
            <br />
            Dollar Amount
            <br />
            Claim Source Code
            <br />
            Charge Date <br />
            Subcriber Id
            <br />
            Check Number <br />
            Legal Entity <br />
            GL Reinsurance <br />
            Legal Entity Group <br />
            Funding Arrangement
            <br />
          </Modal.Section>
        </Modal>
      )}

      {isOpenSuffix && (
        <Modal
          title="Suffix Code can not be chosen own its own"
          isOpen={isOpenSuffix}
          size={'md'}
          onClose={() => setIsopenSuffix(false)}
        >
          <Modal.Section>
            Please enter at least one of the following criteria:
            <br />- Policy Number or Error Code
          </Modal.Section>
        </Modal>
      )}
      {isOpenAccount && (
        <Modal
          title="Account Code can not be chosen own its own"
          isOpen={isOpenAccount}
          size={'md'}
          onClose={() => setISOpenAccount(false)}
        >
          <Modal.Section>
            Please enter at least one of the following criteria:
            <br />
            - Policy Number or Error code <br />
            - Subscriber ID(SSN) or Legal Entity Group code
            <br />- Funding Arrangemnet List
          </Modal.Section>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withAuth(Inquiry);
