import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { Sidebar } from '@src/components/Sidebar';
import { PageHeader } from '@src/components';
import { Heading } from '@abyss/web/ui/Heading';
import { Button } from '@abyss/web/ui/Button';
import Suffix_PolicyInfo from './Suffix_PolicyInfo';
import Suffix_GeneralInfo from './Suffix_GeneralInfo';
import Suffix_FundingBankingInfo from './Suffix_FundingBankingInfo';
import Suffix_PresentSIFS from './Suffix_PresentSIFS';
import useAuth from '@src/utils/useAuth';
import withAuth from '@src/auth/withAuth';

const SuffixDetails = () => {
  const { readOnly } = useAuth();

  const handleButtonClick = () => {
    window.location.href =
      '/fadb/inquiry/case-structure/case-structure-search/suffix-search';
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>Suffix Details</Heading>
                  <Suffix_PolicyInfo />
                  <Suffix_GeneralInfo />
                  <Suffix_FundingBankingInfo />
                  <Suffix_PresentSIFS />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleButtonClick}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                  >
                    Return To List
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(SuffixDetails);
