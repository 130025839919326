import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { getEnvironment } from '@src/utils/environmentUtil';
import { TextInput } from '@abyss/web/ui/TextInput';
const Product = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      unetPrdctCd: payload['UNET_PRDCT_CD'],
      prdctClssId: payload['PRDCT_CLSS_ID'],
      covTypCd: payload['COV_TYP_CD'],
      glPrdctCd: payload['GL_PRDCT_CD'],
      netInd: payload['NET_IND'],
      dualContrInd: payload['DUAL_CONTR_IND'],
      gateInd: payload['GATE_IND'],
      prdctTxt: payload['PRDCT_TXT'],
      mbrMajPrdctCd: payload['MBR_MAJ_PRDCT_CD'],
      selChoCd: payload['SEL_CHO_CD'],
      lstUpdtDt: payload['LSTUPDTDT'],
      lstUpdtTm: payload['LST_UPDT_TM'],
      lstUpdtId: payload['LST_UPDT_ID'],
      updtInd: payload['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/product/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [unetPrdctCd, setunetPrdctCd] = useState('');
  const [prdctClssId, setprdctClssId] = useState('');
  const [covTypCd, setcovTypCd] = useState('');
  const [glPrdctCd, setglPrdctCd] = useState('');
  const [netInd, setnetInd] = useState('');
  const [dualContrInd, setdualContrInd] = useState('');
  const [gateInd, setgateInd] = useState('');
  const [prdctTxt, setprdctTxt] = useState('');
  const [selChoCd, setSelChoCd] = useState('');
  const [mbrMajPrdctCd, setmbrMajPrdctCd] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRows(row['original']);
      console.log('deleted row: ', row);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setHideAdd(true);
    setFlag(true);
    setUpdateRow(row);
    setunetPrdctCd(row?.['UNET_PRDCT_CD']);
    setprdctClssId(row?.['PRDCT_CLSS_ID']);
    setcovTypCd(row?.['COV_TYP_CD']);
    setglPrdctCd(row?.['GL_PRDCT_CD']);
    setnetInd(row?.['NET_IND']);
    setdualContrInd(row?.['DUAL_CONTR_IND']);
    setgateInd(row?.['GATE_IND']);
    setprdctTxt(row?.['PRDCT_TXT']);
    setSelChoCd(row?.['SEL_CHO_CD']);
    setmbrMajPrdctCd(row?.['MBR_MAJ_PRDCT_CD']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
      console.log('upds row: ', row);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const addRow = () => {
    setHideUpdate(true);
    setFlag(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const save = async () => {
    setFlag(false);
    setHideUpdate(false);
    const now = new Date();
    let payload = {
      unetPrdctCd: unetPrdctCd,
      prdctClssId: prdctClssId,
      covTypCd: covTypCd,
      glPrdctCd: glPrdctCd,
      netInd: netInd,
      dualContrInd: dualContrInd,
      gateInd: gateInd,
      prdctTxt: prdctTxt,
      mbrMajPrdctCd: mbrMajPrdctCd,
      selChoCd: selChoCd,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: userName,
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setunetPrdctCd('');
    setprdctClssId('');
    setcovTypCd('');
    setglPrdctCd('');
    setnetInd('');
    setdualContrInd('');
    setgateInd('');
    setprdctTxt('');
    setSelChoCd('');
    setmbrMajPrdctCd('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/product/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    setFlag(false);
    setHideAdd(false);
    const now = new Date();
    let payload = {
      newRecord: {
        unetPrdctCd: unetPrdctCd,
        prdctClssId: prdctClssId,
        covTypCd: covTypCd,
        glPrdctCd: glPrdctCd,
        netInd: netInd,
        dualContrInd: dualContrInd,
        gateInd: gateInd,
        prdctTxt: prdctTxt,
        mbrMajPrdctCd: mbrMajPrdctCd,
        selChoCd: selChoCd,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: userName,
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
      oldRecord: {
        unetPrdctCd: updateRow['UNET_PRDCT_CD'],
        prdctClssId: updateRow['PRDCT_CLSS_ID'],
        covTypCd: updateRow['COV_TYP_CD'],
        glPrdctCd: updateRow['GL_PRDCT_CD'],
        netInd: updateRow['NET_IND'],
        dualContrInd: updateRow['DUAL_CONTR_IND'],
        gateInd: updateRow['GATE_IND'],
        prdctTxt: updateRow['PRDCT_TXT'],
        mbrMajPrdctCd: updateRow['MBR_MAJ_PRDCT_CD'],
        selChoCd: updateRow['SEL_CHO_CD'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
    };
    setunetPrdctCd('');
    setprdctClssId('');
    setcovTypCd('');
    setglPrdctCd('');
    setnetInd('');
    setdualContrInd('');
    setgateInd('');
    setprdctTxt('');
    setSelChoCd('');
    setmbrMajPrdctCd('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/product/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setunetPrdctCd('');
    setprdctClssId('');
    setcovTypCd('');
    setglPrdctCd('');
    setnetInd('');
    setdualContrInd('');
    setgateInd('');
    setprdctTxt('');
    setSelChoCd('');
    setmbrMajPrdctCd('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />
              <Button variant="tertiary" onClick={addRow} isDisabled={hideAdd}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="UNET_PRDCT_CD"
                        value={unetPrdctCd}
                        onChange={(e) => setunetPrdctCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="PRDCT_CLSS_ID"
                        value={prdctClssId}
                        onChange={(e) => setprdctClssId(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="COV_TYP_CD"
                        value={covTypCd}
                        onChange={(e) => setcovTypCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GL_PRDCT_CD"
                        value={glPrdctCd}
                        onChange={(e) => setglPrdctCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="NET_IND"
                        value={netInd}
                        onChange={(e) => setnetInd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="DUAL_CONTR_IND"
                        value={dualContrInd}
                        onChange={(e) => setdualContrInd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GATE_IND"
                        value={gateInd}
                        onChange={(e) => setgateInd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="PRDCT_TXT"
                        value={prdctTxt}
                        onChange={(e) => setprdctTxt(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="MBR_MAJ_PRDCT_CD"
                        value={mbrMajPrdctCd}
                        onChange={(e) => setmbrMajPrdctCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="SEL_CHO_CD"
                        value={selChoCd}
                        onChange={(e) => setSelChoCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={updateFlag ? update : save}>Save</Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }} css={{ marginTop: '25px' }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(Product);
