// Login.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useIsAuthenticated } from '@azure/msal-react';
import { useRouter } from '@abyss/web/hooks/useRouter';

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { getLocation } = useRouter();
  const location = getLocation();
  const [routeChanged, setRouteChanged] = useState(false);

  useEffect(() => {
    if (location.pathname === '/' && !isAuthenticated) {
      setRouteChanged(true);
    }
  }, [location]);

  useEffect(() => {
    if (routeChanged) {
      console.log('in if condition');
      instance
        .loginRedirect(loginRequest)
        .then((response) => {
          console.log('response---', response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [instance, isAuthenticated, routeChanged]);

  return <></>;
};

export default Login;
