// useAuth.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode'; // import dependency

const useAuth = () => {
  const { accounts } = useMsal();
  const [roles, setRoles] = useState<string[] | undefined>([]);
  const [token, setToken] = useState<string | undefined>('');
  const [readOnly, setReadOnly] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const [msid, setMsid] = useState('');
  const [userName, setUserName] = useState('');
  const [userRolesGroup, setUserRolesGroup] = useState([]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setRoles(accounts[0]?.idTokenClaims?.roles);
      setToken(accounts[0]?.idToken);
      setUserRolesGroup(accounts[0]?.idTokenClaims?.groups);
    }

    if (token) {
      const decodedToken = jwtDecode(token);
      setMsid(decodedToken.MSID);
    }

    if (accounts[0]?.name) {
      const fullName = accounts[0].name;
      const lastName = fullName.split(', ')[1];
      setUserName(lastName);
    }
  }, [accounts, token, userName]);

  return { roles, token, readOnly, msid, userName, noAccess, userRolesGroup };
};

export default useAuth;
