import React, { useState } from 'react';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import axios from 'axios';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import withAuth from '@src/auth/withAuth';
import { TextInput } from '@abyss/web/ui/TextInput';

const UcasEscp = () => {
  const { token } = useAuth();
  const { navigate, getLocation } = useRouter();
  const [radioValue, setRadioValue] = useState('single');
  const [seresDesg, setSeresDesg] = useState('');
  const [seresDesgErr, setSeresDesgErr] = useState('');
  const [batchNumb, setBatchNumb] = useState('');
  const [batchNumbErr, setBatchNumbErr] = useState('');
  const [checkNbr, setCheckNbr] = useState('');
  const [checkNbrErr, setCheckNbrErr] = useState('');
  const [batchDt, setBatchDt] = useState('');
  const [batchDtErr, setBatchDtErr] = useState('');
  const [cps, setCps] = useState('');
  const [cpsErr, setCpsErr] = useState('');
  const [srcCode, setSrcCode] = useState('');
  const [srcCodeErr, setSrcCodeErr] = useState('');
  const [flag, setFlag] = useState(false);
  const location = getLocation();
  const [trnNonBookCds, setTrnNonBookCds] = useState('');
  const [trnBatchSeqNbr, setTrnBatchSeqNbr] = useState('');
  const [trnBatchNbr, settrnBatchNbr] = useState('');
  const [trnBatchdt, settrnBatchdt] = useState('');
  const [trnPolNbrExp, settrnPolNbrExp] = useState('');
  const [trnSuffix, settrnSuffix] = useState('');
  const [trnAcct, settrnAcct] = useState('');
  const [trnSersDesg2, settrnSersDesg2] = useState('');
  const [trnScndrySd, settrnScndrySd] = useState('');
  const [trnAcctDiv, settrnAcctDiv] = useState('');
  const [trnFundInd, settrnFundInd] = useState('');
  const [trnFormNbr, settrnFormNbr] = useState('');
  const [trnTypeDocInd, settrnTypeDocInd] = useState('');
  const [trnCorrErrCd, settrnCorrErrCd] = useState('');
  const [trnActionCd, settrnActionCd] = useState('');
  const [trnFeeInd, settrnFeeInd] = useState('');
  const [trnSpecCd, settrnSpecCd] = useState('');
  const [trnIssueDt, settrnIssueDt] = useState('');
  const [trnChargeDt, settrnChargeDt] = useState('');
  const [trnCheckAmt9, settrnCheckAmt9] = useState('');
  const [trnOptOutInd, settrnOptOutInd] = useState('');
  const [trnSummDetInd, settrnSummDetInd] = useState('');
  const [trnMtchcntrlNbr, settrnMtchcntrlNbr] = useState('');
  const [trnPolHldrName, settrnPolHldrName] = useState('');
  const [trnPayeeName, settrnPayeeName] = useState('');
  const [trnEmployName, settrnEmployName] = useState('');
  const [trnSourceCd, settrnSourceCd] = useState('');
  const [trnCheckNbr, settrnCheckNbr] = useState('');
  const [clmPaySeqNbr, setclmPaySeqNbr] = useState('');
  const [trnPolProdCd, settrnPolProdCd] = useState('');
  const [trnDtOfServ, settrnDtOfServ] = useState('');
  //get column definition
  const save = async () => {
    const now = new Date();
    setFlag(false);
    // let payload = {
    //   stCd: stCdForm,
    //   glLocNbr: glLocNbrForm,
    //   effDt: effDt,
    //   medSurchrgInd: medSurchrgIndForm,
    //   stNm: stNm,
    //   stAbbrCd: stAbbrCdForm,
    //   uhicTaxRtAh: uhicRtAH,
    //   uhicTaxRtLf: uhicRtLf,
    //   uhicTaxRtMedsup: uhicRtMeds,
    //   uhicTaxRt4: uhicRt4,
    //   uhicTaxRt5: uhicRt5,
    //   hpTaxRtAh: hpTaxRtAh,
    //   hpTaxRtLf: hpTaxRtLf,
    //   hpTaxRtRt3: hpTaxRt3,
    //   hpTaxRtRt4: hpTaxRt4,
    //   hpTaxRtRt5: hpTaxRt5,
    //   lstUpdtDt: now.toLocaleDateString('en-US', {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //   }),
    //   lstUpdtId: userName,
    //   lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
    //   updtInd: 'Y',
    // };
    setTrnNonBookCds('');
    setTrnBatchSeqNbr('');
    settrnBatchdt('');
    settrnBatchNbr('');
    settrnPolNbrExp('');
    settrnSuffix('');
    settrnAcct('');
    settrnSersDesg2('');
    settrnScndrySd('');
    settrnAcctDiv('');
    settrnFundInd('');
    settrnFormNbr('');
    settrnTypeDocInd('');
    settrnCorrErrCd('');
    settrnActionCd('');
    settrnFeeInd('');
    settrnSpecCd('');
    settrnIssueDt('');
    settrnChargeDt('');
    settrnCheckAmt9('');
    settrnPolProdCd('');
    settrnOptOutInd('');
    settrnDtOfServ('');
    settrnSummDetInd('');
    settrnMtchcntrlNbr('');
    settrnPolHldrName('');
    settrnPayeeName('');
    settrnEmployName('');
    settrnSourceCd('');
    settrnCheckNbr('');
    setclmPaySeqNbr('');
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // };
    // try {
    //   const response = await axios.post(
    //     `${BASE_URL}cts/add/state/${location?.state?.database}`,
    //     payload,
    //     config
    //   );
    //   if (response.status === 200) {
    //     console.log(response.data);
    //     getTableData1();
    //   }
    // } catch (error) {
    //   console.error(error);
    //   return {};
    // }
  };
  const close = () => {
    setFlag(false);
    setTrnNonBookCds('');
    setTrnBatchSeqNbr('');
    settrnBatchdt('');
    settrnBatchNbr('');
    settrnPolNbrExp('');
    settrnSuffix('');
    settrnAcct('');
    settrnSersDesg2('');
    settrnScndrySd('');
    settrnAcctDiv('');
    settrnFundInd('');
    settrnFormNbr('');
    settrnTypeDocInd('');
    settrnCorrErrCd('');
    settrnActionCd('');
    settrnFeeInd('');
    settrnSpecCd('');
    settrnIssueDt('');
    settrnChargeDt('');
    settrnCheckAmt9('');
    settrnPolProdCd('');
    settrnOptOutInd('');
    settrnDtOfServ('');
    settrnSummDetInd('');
    settrnMtchcntrlNbr('');
    settrnPolHldrName('');
    settrnPayeeName('');
    settrnEmployName('');
    settrnSourceCd('');
    settrnCheckNbr('');
    setclmPaySeqNbr('');
  };
  const columns = React.useMemo(
    () => [
      { Header: 'ERROR CODES', accessor: 'trnNonBookCds' },
      { Header: 'BATCH SEQ #', accessor: 'trnBatchSeqNbr' },
      { Header: 'BATCH DT', accessor: 'trnBatchdt' },
      { Header: 'BATCH NBR', accessor: 'trnBatchNbr' },
      { Header: 'POLICY NBR', accessor: 'trnPolNbrExp' },
      { Header: 'SUFFIX', accessor: 'trnSuffix' },
      { Header: 'ACCOUNT NBR', accessor: 'trnAcct' },
      { Header: 'SER.DES SD', accessor: 'trnSersDesg2' },
      { Header: 'BULK ACCT SD', accessor: 'trnScndrySd' },
      { Header: 'HMO DIV', accessor: 'trnAcctDiv' },
      { Header: 'FUND CODE', accessor: 'trnFundInd' },
      { Header: 'FORM CODE', accessor: 'trnFormNbr' },
      { Header: 'TYPE CODE', accessor: 'trnTypeDocInd' },
      { Header: 'ERR CODE', accessor: 'trnCorrErrCd' },
      { Header: 'ACTION CD', accessor: 'trnActionCd' },
      { Header: 'FEE CODE', accessor: 'trnFeeInd' },
      { Header: 'SPEC CODE', accessor: 'trnSpecCd' },
      { Header: 'ISSUE DATE', accessor: 'trnIssueDt' },
      { Header: 'CHARGE DATE', accessor: 'trnChargeDt' },
      { Header: 'CHECK AMOUNT', accessor: 'trnCheckAmt9' },
      { Header: 'PROD CODE', accessor: 'trnPolProdCd' },
      { Header: 'OPT OUT IND', accessor: 'trnOptOutInd' },
      { Header: 'DATE OF SERVICE', accessor: 'trnDtOfServ' },
      { Header: 'SUM TYPE', accessor: 'trnSummDetInd' },
      { Header: 'MATCH CONTROL', accessor: 'trnMtchcntrlNbr' },
      { Header: 'MEM FIRST NAME', accessor: 'trnPolHldrName' },
      { Header: 'PAYEE NAME', accessor: 'trnPayeeName' },
      { Header: 'EMPLOYEE NAME', accessor: 'trnEmployName' },
      { Header: 'SRC CODE', accessor: 'trnSourceCd' },
      { Header: 'CHECK NBR', accessor: 'trnCheckNbr' },
      { Header: 'CL-PAY-SEQ-NBR', accessor: 'clmPaySeqNbr' },
    ],
    []
  );
  const checkAlphaNumeric = (str) => {
    var letters = /^[0-9a-zA-Z]+$/;
    if (str.match(letters)) return true;
    else return false;
  };
  const getSubLine = async (row) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload = {
      flag: radioValue === 'single' ? 1 : 2,
      serisDesg: seresDesg,
      chkNbr: checkNbr,
      clPaySeqNbr: cps,
      batchDate: batchDt,
      srcCode: srcCode,
      batchNumb: batchNumb,
      selectedAction: 'R',
      dtSeqNbr: row['trnDtSeqNbr'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}ucas/cfrErrTrans/getSublineData/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        if (response.data !== 'Not available') {
          getTableState1.setData(response.data, true);
        }

        // getTableState.setData(response.data, true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const updateRows = async (row) => {
    setFlag(true);
    await getSubLine(row);
    // setHideAdd(true);
    // setUpdateRow(row);
    setTrnNonBookCds(row['trnNonBookCds']);
    setTrnBatchSeqNbr(row['trnBatchSeqNbr']);
    settrnBatchdt(row['trnBatchdt']);
    settrnBatchNbr(row['trnBatchNbr']);
    settrnPolNbrExp(row['trnPolNbrExp']);
    settrnSuffix(row['trnSuffix']);
    settrnAcct(row['trnAcct']);
    settrnSersDesg2(row['trnSersDesg2']);
    settrnScndrySd(row['trnScndrySd']);
    settrnAcctDiv(row['trnAcctDiv']);
    settrnFundInd(row['trnFundInd']);
    settrnFormNbr(row['trnFormNbr']);
    settrnTypeDocInd(row['trnTypeDocInd']);
    settrnCorrErrCd(row['trnCorrErrCd']);
    settrnActionCd(row['trnActionCd']);
    settrnFeeInd(row['trnFeeInd']);
    settrnSpecCd(row['trnSpecCd(']);
    settrnIssueDt(row['trnIssueDt']);
    settrnChargeDt(row['trnChargeDt']);
    settrnCheckAmt9(row['trnCheckAmt9']);
    settrnPolProdCd(row['trnPolProdCd']);
    settrnOptOutInd(row['trnOptOutInd']);
    settrnDtOfServ(row['trnDtOfServ']);
    settrnSummDetInd(row['trnSummDetInd']);
    settrnMtchcntrlNbr(row['trnMtchcntrlNbr']);
    settrnPolHldrName(row['trnPolHldrName']);
    settrnPayeeName(row['trnPayeeName']);
    settrnEmployName(row['trnEmployName']);
    settrnSourceCd(row['trnSourceCd']);
    settrnCheckNbr(row['trnCheckNbr']);
    setclmPaySeqNbr(row['clmPaySeqNbr']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    label: <IconSymbol icon="edit" />,
  };
  const validate = () => {
    let valid = true;
    if (radioValue === 'single') {
      if (seresDesg == undefined || seresDesg == '') {
        setSeresDesgErr('SERS DESG IS REQUIRED');
        valid = false;
      }
      if (checkNbr == undefined || checkNbr == '') {
        setCheckNbrErr('CHECK NBR IS REQUIRED');
        valid = false;
      }
      if (!checkAlphaNumeric(seresDesg)) {
        setSeresDesgErr('SERIES DESIGNATOR MISSING OR INVALID');
        valid = false;
      }

      if (checkNbr !== undefined) {
        if (isNaN(Number(checkNbr))) {
          setCheckNbrErr('CHECK NBR SHOULD BE NUMERIC');
          valid = false;
        }
      }

      if (cps !== undefined) {
        if (isNaN(Number(cps))) {
          setCpsErr('CL-PAY-SEQ-NBR SHOULD BE NUMERIC');
          valid = false;
        }
      }
    } else {
      if (batchNumb == undefined || batchNumb == '') {
        setBatchNumbErr('BATCH-NUMB IS REQUIRED');
        valid = false;
      }

      if (batchDt == undefined || batchDt == '') {
        setBatchDt('BATCH-DT IS REQUIRED');
        valid = false;
      }
      if (srcCode == undefined || srcCode == '') {
        setSrcCodeErr('SRC-CODE IS REQUIRED');
        valid = false;
      }
      if (isNaN(Number(batchDt))) {
        setBatchDtErr('BATCH-DT IS INVALID');
        valid = false;
      }

      if (!checkAlphaNumeric(batchDt)) {
        setBatchDt('BATCH-DT MISSING OR INVALID');
        valid = false;
      }

      if (batchDt.trim() == '' || batchNumb.trim() == '') {
        setBatchDtErr('SELECTION CRITERIA IS MISSING');
        setBatchNumbErr('SELECTION CRITERIA IS MISSING');
        valid = false;
      }

      if (!checkAlphaNumeric(batchNumb)) {
        setBatchNumbErr('BATCH NUMBER MISSING OR INVALID');
        valid = false;
      }

      if (srcCode !== undefined) {
        if (isNaN(Number(srcCode))) {
          setSrcCodeErr('SOURCE CODE SHOULD BE NUMERIC');
          valid = false;
        }
      }
    }
    return valid;
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: columns,

    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: [updateLinkButton],
  });
  const columns1 = React.useMemo(
    () => [
      { Header: 'SUB TYPE CD', accessor: 'subSubLine' },
      { Header: 'FROM DATE', accessor: 'subFromDt' },
      { Header: 'TO DATE', accessor: 'subToDt' },
      { Header: 'AMOUNT', accessor: 'subSublineAmt9' },
      { Header: 'SCMP SUB IND', accessor: 'subScmpSubInd' },
      { Header: 'SUBLINE PPD', accessor: 'subPpd' },
    ],
    []
  );

  const getTableState1 = useDataTable({
    showPagination: true,
    initialData: [],
    initialColumns: columns1,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
  });
  const handleClick = () => {
    navigate('/fmtf/ucas');
  };
  const clearErrors = () => {
    setSeresDesgErr('');
    setBatchNumbErr('');
    setCheckNbrErr('');
    setBatchDtErr('');
    setCpsErr('');
    setSrcCodeErr('');
  };
  const lookup = async () => {
    clearErrors();
    if (validate()) {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let payload = {
        flag: radioValue === 'single' ? 1 : 2,
        serisDesg: seresDesg,
        chkNbr: checkNbr,
        clPaySeqNbr: cps,
        batchDate: batchDt,
        srcCode: srcCode,
        batchNumb: batchNumb,
      };
      try {
        const response = await axios.post(
          `${BASE_URL}ucas/cfrErrTrans/getTableData/${location?.state?.database}`,
          payload,
          config
        );
        if (response.status === 200) {
          console.log(response.data);
          getTableState.setData(response.data, true);
        }
      } catch (error) {
        console.error(error);
        return {};
      }
    }
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{ marginTop: '135px', marginLeft: '12px' }}
              >
                Transaction Correction Screen
              </Heading>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Database:
                  </Text>
                  <span>DEV - {location?.state?.database}</span>

                  <Text
                    css={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: '20px',
                    }}
                  >
                    Table:
                  </Text>
                  <span>{location?.state?.systemSelectedValue}</span>
                  <RadioGroup
                    label="Selection:"
                    onChange={(e) => setRadioValue(e.target.value)}
                    value={radioValue}
                  >
                    <RadioGroup.Radio label="Single Selection" value="single" />
                    <RadioGroup.Radio
                      label="Multiple Selection"
                      value="multiple"
                    />
                  </RadioGroup>
                </Grid.Col>
                <Grid>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="SERS DESG"
                      value={seresDesg}
                      onChange={(e) => setSeresDesg(e.target.value)}
                      errorMessage={seresDesgErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="BATCH-NUMB"
                      value={batchNumb}
                      onChange={(e) => setBatchNumb(e.target.value)}
                      errorMessage={batchNumbErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="CHECK NBR"
                      value={checkNbr}
                      onChange={(e) => setCheckNbr(e.target.value)}
                      errorMessage={checkNbrErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="BATCH-DT"
                      value={batchDt}
                      onChange={(e) => setBatchDt(e.target.value)}
                      errorMessage={batchDtErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="CL-PAY-SEQ-NBR"
                      value={cps}
                      onChange={(e) => setCps(e.target.value)}
                      errorMessage={cpsErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <TextInput
                      label="SRC-CODE"
                      value={srcCode}
                      onChange={(e) => setSrcCode(e.target.value)}
                      errorMessage={srcCodeErr}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <Button onClick={lookup}>Look up</Button>
                  </Grid.Col>
                </Grid>
                {flag && (
                  <>
                    <Grid
                      css={{
                        background: '#F6F5F5',
                        margin: 'auto',
                        width: '96%',
                      }}
                    >
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ERROR CODES"
                          value={trnNonBookCds}
                          onChange={(e) => setTrnNonBookCds(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BATCH SEQ #"
                          value={trnBatchSeqNbr}
                          onChange={(e) => setTrnBatchSeqNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BATCH DT"
                          value={trnBatchdt}
                          onChange={(e) => settrnBatchdt(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BATCH NBR"
                          value={trnBatchNbr}
                          onChange={(e) => settrnBatchNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="POLICY NBR"
                          value={trnPolNbrExp}
                          onChange={(e) => settrnPolNbrExp(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUFFIX"
                          value={trnSuffix}
                          onChange={(e) => settrnSuffix(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ACCOUNT NBR"
                          value={trnAcct}
                          onChange={(e) => settrnAcct(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SER.DES SD"
                          value={trnSersDesg2}
                          onChange={(e) => settrnSersDesg2(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="BULK ACCT SD"
                          value={trnScndrySd}
                          onChange={(e) => settrnScndrySd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="HMO DIV"
                          value={trnAcctDiv}
                          onChange={(e) => settrnAcctDiv(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FUND CODE"
                          value={trnFundInd}
                          onChange={(e) => settrnFundInd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FORM CODE"
                          value={trnFormNbr}
                          onChange={(e) => settrnFormNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="TYPE CODE"
                          value={trnTypeDocInd}
                          onChange={(e) => settrnTypeDocInd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ERR CODE"
                          value={trnCorrErrCd}
                          onChange={(e) => settrnCorrErrCd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ACTION CD"
                          value={trnActionCd}
                          onChange={(e) => settrnActionCd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="FEE CODE"
                          value={trnFeeInd}
                          onChange={(e) => settrnFeeInd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SPEC CODE"
                          value={trnSpecCd}
                          onChange={(e) => settrnSpecCd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="ISSUE DATE"
                          value={trnIssueDt}
                          onChange={(e) => settrnIssueDt(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="CHARGE DATE"
                          value={trnChargeDt}
                          onChange={(e) => settrnChargeDt(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="CHECK AMOUNT"
                          value={trnCheckAmt9}
                          onChange={(e) => settrnCheckAmt9(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="PROD CODE"
                          value={trnPolProdCd}
                          onChange={(e) => settrnPolProdCd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="OPT OUT IND"
                          value={trnOptOutInd}
                          onChange={(e) => settrnOptOutInd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="DATE OF SERVICE"
                          value={trnDtOfServ}
                          onChange={(e) => settrnDtOfServ(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SUM TYPE"
                          value={trnSummDetInd}
                          onChange={(e) => settrnSummDetInd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="MATCH CONTROL"
                          value={trnMtchcntrlNbr}
                          onChange={(e) => settrnMtchcntrlNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="MEM FIRST NAME"
                          value={trnPolHldrName}
                          onChange={(e) => settrnPolHldrName(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="PAYEE NAME"
                          value={trnPayeeName}
                          onChange={(e) => settrnPayeeName(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="EMPLOYEE NAME"
                          value={trnEmployName}
                          onChange={(e) => settrnEmployName(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="SRC CODE"
                          value={trnSourceCd}
                          onChange={(e) => settrnSourceCd(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="CHECK NBR"
                          value={trnCheckNbr}
                          onChange={(e) => settrnCheckNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 4 }}>
                        <TextInput
                          label="'CL-PAY-SEQ-NBR"
                          value={clmPaySeqNbr}
                          onChange={(e) => setclmPaySeqNbr(e.target.value)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }}>
                        <Button onClick={save}>Save</Button>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 1 }}>
                        <Button onClick={close}>Cancel</Button>
                      </Grid.Col>
                    </Grid>
                  </>
                )}
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title=""
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'left',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title=""
                    tableState={getTableState1}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(UcasEscp);
