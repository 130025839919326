import React from "react";
import { PageFooter } from '@abyss/web/ui/PageFooter';
import styled from "styled-components";

const StickyFooter = styled(PageFooter)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  boxSizing: 'border-box';
  z-index: 1000;
  background-color: #003C71;
  color: #fff;
  padding: 10px 20px;
  line-height: 4;
  // box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);

  & > *{
    margin: 0;
    // padding: 0 20px;
  }

`

export const Footer = () => {
  return (
    <>
      <StickyFooter subFooterTitle="United HealthCare Services, Inc" />


    </>


  );
};
