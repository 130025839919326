import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { useRouter } from '@abyss/web/hooks/useRouter';
import {
  updateAutomatedFeedErrorCorrection,
  updateAutomatedFeedErrorCorrectionError,
  updateAutomatedFeedErrorCorrectionSuccess,
} from '@src/redux/action/FADB/inquiry/automatedFeeds/searchAPI';
import { automatedFeedErrorCorrectionApi } from '@src/redux/api/FADB/inquiry/automatedFeeds/searchAPI';
import PolicyDetails from '../CaseStructure/PolicyDetails';
import useAuth from '@src/utils/useAuth';
const TransactionStatusModal = ({ isOpen, onClose, filterTableData }) => {
  const { navigate } = useRouter();
  const dispatch = useDispatch();
  const { token } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [selectedValue, setselectedValue] = useState('S');
  const correctionData = useSelector(
    (state) => state?.automatedFeedSearch?.findData
  );

  const handleChange = (value) => {
    setselectedValue(value);
  };
  const takeAction = async (action: string) => {
    const payload = {
      fileId: correctionData?.fileId,
      trnId: correctionData?.trnId,
      trnErrCd: correctionData?.trnErrCd,
      action: action,
      errStsRad: selectedValue === 'M' ? 'All' : '',
    };
    try {
      dispatch(updateAutomatedFeedErrorCorrection(payload));
      const response = await automatedFeedErrorCorrectionApi(payload, config);
      if (response.status === 200) {
        dispatch(updateAutomatedFeedErrorCorrectionSuccess(response.data));
        //filter out table data
        filterTableData();
        onClose();
      }
    } catch (error) {
      updateAutomatedFeedErrorCorrectionError(error);
    }
  };

  const handleAction = (message, action) => {
    const confirmDialog = window.confirm(message);
    if (confirmDialog) {
      takeAction(action);
    }
  };

  return (
    <>
      <React.Fragment>
        {!correctionData ? (
          <div>Loading ....</div>
        ) : (
          <Modal isOpen={isOpen} onClose={onClose}>
            <Modal.Section>
              <Flex
                justify="center"
                alignItems="center"
                alignContent="center"
                direction="column"
              >
                <Text fontWeight={'bold'}>
                  Transaction Error Correction For Error Number:
                  {correctionData?.trnErrCd}
                </Text>

                <Text>Error Description: {correctionData?.errorDesc}</Text>
              </Flex>
              <hr />
              <Flex
                justify="space-between"
                alignContent="flex-start"
                direction="column"
              >
                <Text>Error Info:</Text>

                <RadioGroup
                  label="Radio Sandbox"
                  onChange={(e) => handleChange(e.target.value)}
                  value={selectedValue}
                  hideLabel
                >
                  <RadioGroup.Radio label="Single Transaction" value="S" />
                  <RadioGroup.Radio label="Multiple Transaction" value="M" />
                </RadioGroup>
              </Flex>

              <Grid css={{ padding: '0px !important', marginTop: '20px' }}>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>File Id :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{correctionData?.fileId}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>User Name :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{correctionData?.userShortNm}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Transaction Id :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{correctionData?.trnId}</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text css={{ fontWeight: 600 }}>Error Date :</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 3 }}>
                  <Text>{correctionData?.errCdDt}</Text>
                </Grid.Col>
              </Grid>
              <hr />
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={() =>
                      handleAction(
                        'Are you sure you want to send this request for rerun?',
                        'R'
                      )
                    }
                    // onClick={() => handleSendClick(requestId, navigate)}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    Rerun
                  </Button>
                  <Button
                    onClick={() =>
                      handleAction(
                        'Are you sure you want to send this request for override?',
                        'O'
                      )
                    }
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    Override
                  </Button>
                  <Button
                    onClick={() =>
                      handleAction(
                        'Are you sure you want to delete this error?',
                        'D'
                      )
                    }
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => navigate('/fadb/inquiry/automated-feeds')}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Selection
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    Return to List
                  </Button>
                </Grid.Col>
              </Grid>
            </Modal.Section>
          </Modal>
        )}
      </React.Fragment>
    </>
  );
};

export default TransactionStatusModal;
