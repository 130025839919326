import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Footer } from '@src/components/Footer';
import useAuth from '@src/utils/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { BASE_URL } from '@src/utils/endpoints';
import withAuth from '@src/auth/withAuth';

const FinancialStructure = () => {
  const { readOnly, msid, token } = useAuth();

  const { navigate } = useRouter();
  const [options, setOptions]: any = useState([]);
  const [legalEntityOptions, setLeagalEntityOptions]: any = useState([]);
  const id = 'finStrDropdownData';
  const lglEntyCdDropdown = 'lglEntyCdDropdown';
  const years = [];
  for (let year = 1990; year <= 2029; year++) {
    years.push({ id: year, value: year.toString(), label: year.toString() });
  }

  const [formValues, setFormValues] = useState({
    bookMonthFrom: '',
    bookMonthTo: '',
    bookYearFrom: '',
    bookYearTo: '',
    policyNumber: '',
    suffixCode: '',
    fundingArrangmentGroupCode: '',
    fundingArrangementCode: '',
    legalEntityGroupCode: '',
  });

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const handleChange = (value, fieldId) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [fieldId]: value,
    }));
  };
  const regEx = /^\d{2}[A-Za-z0-9]\d{4}$/;
  const checkAlphabet = (sufixcd) => {
    const letters: RegExp = /^[A-Za-z ]+$/;
    if (sufixcd.match(letters)) {
      return false;
    } else {
      return true;
    }
  };

  const handleValidation = () => {
    const errors = {};
    let isValid = true;

    if (!formValues.bookMonthFrom) {
      errors.bookMonthFrom = 'Book Month From is required';
      isValid = false;
    }

    if (!formValues.bookMonthTo) {
      errors.bookMonthTo = 'Book Month To is required';
      isValid = false;
    }

    if (!formValues.bookYearFrom) {
      errors.bookYearFrom = 'Book Year From is required';
      isValid = false;
    }

    if (!formValues.bookYearTo) {
      errors.bookYearTo = 'Book Year To is required';
      isValid = false;
    }
    if (
      formValues.bookYearFrom !== null &&
      formValues.bookYearTo !== null &&
      formValues.bookMonthFrom !== null &&
      formValues.bookMonthTo !== null
    ) {
      if (formValues.bookYearTo < formValues.bookYearFrom) {
        errors.bookYearTo = 'From Year is not before To Year.';
        errors.bookYearFrom = 'From Year is not before To Year.';
        isValid = false;
      }
      if (formValues.bookYearTo === formValues.bookYearFrom) {
        if (formValues.bookMonthTo < formValues.bookMonthFrom) {
          errors.bookMonthFrom = 'From Month is not before To Month.';
          errors.bookMonthTo = 'From Month is not before To Month.';
          isValid = false;
        }
      }
    }
    if (
      !formValues.fundingArrangementCode &&
      !formValues.fundingArrangmentGroupCode &&
      !formValues.legalEntityGroupCode
    ) {
      errors.commonError =
        'At least one of the following fields is required: Funding Arrangement Code, Funding Arrangement Group Code, or Legal Entity Group Code';

      isValid = false;
    } else if (
      formValues.fundingArrangementCode &&
      formValues.fundingArrangmentGroupCode
    ) {
      errors.commonError =
        'Please select either Funding Arrangement Code or Funding Arrangement Group Code';
      isValid = false;
    }

    if (
      null === formValues.policyNumber ||
      formValues.policyNumber === undefined ||
      formValues.policyNumber === '' ||
      formValues.policyNumber.trim() === ''
    ) {
      errors.policyNumber = 'Policy Number is required';
      isValid = false;
    }
    if (formValues.policyNumber.length !== 7) {
      errors.policyNumber = 'Policy Number must have exactly 7 digits';
      isValid = false;
    } else if (!regEx.test(formValues.policyNumber)) {
      errors.policyNumber =
        'Policy Number must be numeric, Except for the 3rd position.';
      isValid = false;
    }

    if (
      formValues.suffixCode !== null &&
      formValues.suffixCode !== undefined &&
      formValues.suffixCode !== ''
    ) {
      if (checkAlphabet(formValues.suffixCode)) {
        errors.suffixCode =
          'Please enter a valid suffix with only letters or spaces.';
        isValid = false;
      } else if (formValues.suffixCode.length > 2) {
        errors.suffixCode = 'Suffix Code can be max of 2 characters long.';
        isValid = false;
      }
    }
    setErrors(errors);
    setIsValid(isValid);
    return isValid;
  };
  const payload = {
    fromBookMonth: formValues.bookMonthFrom,
    toBookMonth: formValues.bookMonthTo,
    fromBookYear: formValues.bookYearFrom,
    toBookYear: formValues.bookYearTo,
    policyNumber: formValues.policyNumber.toLocaleUpperCase(),
    suffixCode: formValues.suffixCode.toLocaleUpperCase(),
    funGrpCd: formValues.fundingArrangmentGroupCode,
    funCd: formValues.fundingArrangementCode,
    legEntCd: formValues.legalEntityGroupCode,
    userName: msid,
  };

  const apiUrl = `${BASE_URL}cmdb-service/finSt-saveData`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const handleSubmit = (event) => {
    if (handleValidation()) {
      axios
        .post(apiUrl, payload, config)
        .then((response) => {
          navigate(
            '/cmdb/correction-management/financial-structure/financial-structure-submit',
            {
              state: {
                data: response.data,
                requestDetails: payload,
              },
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleReset = () => {
    setFormValues({
      bookMonthFrom: '',
      bookMonthTo: '',
      bookYearFrom: '',
      bookYearTo: '',
      policyNumber: '',
      suffixCode: '',
      fundingArrangmentGroupCode: '',
      fundingArrangementCode: '',
      legalEntityGroupCode: '',
    });
  };

  const handleBack = () => {
    navigate('/cmdb/correction-management');
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/finStrDropdownData/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const res = await axios.get(
          `${BASE_URL}cmdb-service/legEntGrpCd/${lglEntyCdDropdown}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data && res && res.data) {
          setOptions(response.data);
          setLeagalEntityOptions(res.data.legalEntity);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  return (
    <React.Fragment>
      <Grid css={{ marginLeft: '200px', marginTop: '50px' }}>
        <Grid.Col
          span={{ xs: 10 }}
          css={{
            paddingBottom: '2.5rem',
            paddingTop: '0.5rem',
            paddingRight: '3.5rem',
            paddingLeft: '0rem',
          }}
        >
          <Card
            css={{
              marginTop: '70px',
              border: 'none',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>Custom Correction Request</Heading>
                </Grid.Col>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    borderBottom: '1px solid #A9A9A9',
                  }}
                >
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Financial Structure</Heading>
                  </Grid.Col>
                  <Grid
                    css={{
                      margin: '0px !important',
                      marginTop: '20px !important',
                    }}
                  >
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                        Required Criteria:
                      </Text>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Text css={{ fontWeight: 600 }}>Book Month:</Text>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="From Month"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        options={options?.monthList?.map((col) => ({
                          value: col.value,
                          label: col.label,
                        }))}
                        isDisabled={readOnly}
                        value={formValues.bookMonthFrom}
                        onChange={(event) =>
                          handleChange(event, 'bookMonthFrom')
                        }
                        errorMessage={errors.bookMonthFrom}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="From Year"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        options={years}
                        isDisabled={readOnly}
                        value={formValues.bookYearFrom}
                        onChange={(event) =>
                          handleChange(event, 'bookYearFrom')
                        }
                        errorMessage={errors.bookYearFrom}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}></Grid.Col>

                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="To Month"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        options={options?.monthList?.map((col) => ({
                          value: col.value,
                          label: col.label,
                        }))}
                        isDisabled={readOnly}
                        value={formValues.bookMonthTo}
                        onChange={(event) => handleChange(event, 'bookMonthTo')}
                        errorMessage={errors.bookMonthTo}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <SelectInput
                        label="To Year"
                        placeholder="Pick one"
                        isClearable
                        isSearchable
                        options={years}
                        isDisabled={readOnly}
                        value={formValues.bookYearTo}
                        onChange={(event) => handleChange(event, 'bookYearTo')}
                        errorMessage={errors.bookYearTo}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}></Grid.Col>
                    <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                      <TextInput
                        label="Policy Number"
                        isDisabled={readOnly}
                        value={formValues.policyNumber}
                        onChange={(event) =>
                          handleChange(event.target.value, 'policyNumber')
                        }
                        errorMessage={errors.policyNumber}
                      />
                    </Grid.Col>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Text css={{ fontWeight: 600, fontSize: '18px' }}>
                    Other Criteria:
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="Suffix Code"
                    isDisabled={readOnly}
                    value={formValues.suffixCode}
                    onChange={(event) =>
                      handleChange(event.target.value, 'suffixCode')
                    }
                    errorMessage={errors.suffixCode}
                  />
                </Grid.Col>
              </Grid>
              <Grid
                css={{
                  margin: '0px !important',
                  marginTop: '20px !important',
                  border: '1px solid black',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading
                    offset={5}
                    textAlign="left"
                    css={{ fontWeight: 600, color: 'red !important' }}
                  >
                    Atleast One Required:
                  </Heading>
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Funding Arrangement Group Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    css={{ float: 'left' }}
                    options={options?.fundingGroupList?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.fundingArrangmentGroupCode}
                    onChange={(event) =>
                      handleChange(event, 'fundingArrangmentGroupCode')
                    }
                    errorMessage={errors.fundingArrangmentGroupCode}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                  <Heading offset={5} textAlign="center">
                    -OR-
                  </Heading>
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Funding Arrangement Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={options?.fundingList?.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.fundingArrangementCode}
                    onChange={(event) =>
                      handleChange(event, 'fundingArrangementCode')
                    }
                    errorMessage={errors.fundingArrangementCode}
                  />
                </Grid.Col>

                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Legal Entity Group Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={legalEntityOptions.map((col) => ({
                      value: col.value,
                      label: col.label,
                    }))}
                    isDisabled={readOnly}
                    value={formValues.legalEntityGroupCode}
                    onChange={(event) =>
                      handleChange(event, 'legalEntityGroupCode')
                    }
                    errorMessage={errors.legalEntityGroupCode}
                  />
                </Grid.Col>
              </Grid>
              <Text css={{ fontWeight: 600, color: 'red !important' }}>
                {errors.commonError}{' '}
              </Text>

              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    css={{
                      'abyss-button-root': {
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                    isDisabled={readOnly}
                    onClick={handleBack}
                  >
                    Correction Home
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Grid.Col>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(FinancialStructure);
