// authConfig.js
import { REDIRECT_URL, LOCAL_BASE_URL } from 'src/utils/endpoints';
const clientID = 'fe878baa-7a9c-42f2-b78c-98c0d3dd9606';
const tenantID = 'db05faca-c82a-4b9d-b9c5-0f64b6755421';
// const redirectUri = 'https://fmt-a8eshfgserf4had7.a03.azurefd.net'; // or your app's redirect URI
// const redirectUri = 'https://fmt-azdev.optum.com/home';
// const redirectUri = 'https://fmt-azdev.optum.com/home';
const redirectUri = REDIRECT_URL;

export const msalConfig = {
  auth: {
    clientId: clientID,
    authority: `https://login.microsoftonline.com/${tenantID}`,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndPoint: 'https://graph.microsoft.com/v1.0/me',
};
