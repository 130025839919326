import { BASE_URL } from "@src/utils/endpoints";
import axios from "axios";
export const handleBackButton = (path, navigate) => {
  navigate(path);
};

export const handleSendClick = (requestId, handleModalState, handleAlertState, config) => {

  if (requestId) {
    try {
      axios
        .post(
          `${BASE_URL}manageRequest/check-valid-sts-cd-financial-review`,
          { requestId: requestId },
          config
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data['errorMessage'] === 'success') {
              handleModalState(`Financial Review Confirmation`, `Are you sure you want to send Request ID: ${requestId} for Financial Review?`)
            }
            else {
              handleAlertState(response.data['errorMessage'])
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const submitData = (statusCode, requestId, handleModalState, handleAlertState, config, msid, handleCompletion) => {
  if (requestId) {
    try {
      axios
        .get(
          `${BASE_URL}manageRequest/update-rtrvlRqst-stsCd/${statusCode}/${requestId}/${msid}`, config
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data['errorMessage'] === 'success') {
              handleCompletion();
            }
            else {
              handleAlertState(response.data['errorMessage'])
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const handleSubmitClick = (statusCode, requestId, handleModalState, handleAlertState, config, msid, handleCompletion) => {
  if (requestId) {
    try {
      axios
        .get(
          `${BASE_URL}manageRequest/check-valid-sts-cd-claim-accounting/${requestId}`, config
        )
        .then((response) => {

          if (response.status === 200) {
            if (response.data['errorMessage'] === 'success') {
              handleModalState(`Submit to Claim Accounting Confirmation`, `Are you sure you want to send Request ID: ${requestId}for Claim Accounting?`, 'S')
              // axios
              //   .get(
              //     `${BASE_URL}manageRequest/update-rtrvlRqst-stsCd/${statusCode}/${requestId}/${msid}`, config
              //   )
              //   .then((response) => {
              //     if (response.status === 200) {
              //       if (response.data['errorMessage'] === 'success') {
              //         handleModalState(`Submit to Claim Accounting Confirmation`, `Are you sure you want to send Request ID: ${requestId} for Claim Accounting?`)
              //       }
              //       else {
              //         handleAlertState(response.data['errorMessage'])
              //       }
              //     }
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
            } else {
              handleAlertState(response.data['errorMessage'])

            }

          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const handleDenyClick = (statusCode, requestId, handleModalState, handleAlertState, config, msid, handleCompletion) => {
  if (requestId) {
    try {
      axios
        .get(
          `${BASE_URL}manageRequest/update-rtrvlRqst-stsCd/${statusCode}/${requestId}/${msid}`, config
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data['errorMessage'] === 'success') {
              handleCompletion();
              // handleModalState(`Deny Request Confirmation`, `Are you sure you want to deny Request ID: ${requestId} ?`)
            }
            else {
              handleAlertState(response.data['errorMessage'])
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};