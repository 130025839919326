import {
  LOOK_UP_POLICY,
  LOOK_UP_POLICY_SUCCESS,
  LOOK_UP_POLICY_ERROR,
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_ERROR,
  FETCH_LEGAL_ENTITIES_REQUEST,
  FETCH_LEGAL_ENTITIES_SUCCESS,
  FETCH_LEGAL_ENTITIES_FAILURE,
  FETCH_DYNAMIC_DROPDOWN_REQUEST,
  FETCH_DYNAMIC_DROPDOWN_SUCCESS,
  FETCH_DYNAMIC_DROPDOWN_FAILURE,
  CANCEL_LOOK_UP_POLICY,
} from './policyConstants';

export function lookUpPolicy(policyNumber) {
  console.log('LOOK_UP_POLICY action----', policyNumber);
  return {
    type: LOOK_UP_POLICY,
    policyNumber,
  };
}

export function lookUpPolicySuccess(policyData) {
  return {
    type: LOOK_UP_POLICY_SUCCESS,
    policyData,
  };
}

export function lookUpPolicyError(error) {
  return {
    type: LOOK_UP_POLICY_ERROR,
    error,
  };
}

export function updatePolicy(policyData) {
  return {
    type: UPDATE_POLICY,
    policyData,
  };
}

export function updatePolicySuccess(policyData) {
  return {
    type: UPDATE_POLICY_SUCCESS,
    policyData,
  };
}

export function updatePolicyError(error) {
  return {
    type: UPDATE_POLICY_ERROR,
    error,
  };
}

export function fetchLegalEntitiesRequest() {
  return { type: FETCH_LEGAL_ENTITIES_REQUEST };
}

export function fetchLegalEntitiesSuccess(data) {
  return { type: FETCH_LEGAL_ENTITIES_SUCCESS, data };
}

export function fetchLegalEntitiesFailure(error) {
  return { type: FETCH_LEGAL_ENTITIES_FAILURE, error };
}

export function fetchDynamicDropdownRequest() {
  return { type: FETCH_DYNAMIC_DROPDOWN_REQUEST };
}

export function fetchDynamicDropdownSuccess(data) {
  return { type: FETCH_DYNAMIC_DROPDOWN_SUCCESS, data };
}

export function fetchDynamicDropdownFailure(error) {
  return { type: FETCH_DYNAMIC_DROPDOWN_FAILURE, error };
}

export function cancelLookUp() {
  return {
    type: CANCEL_LOOK_UP_POLICY,
  };
}
