import React, { useEffect, useState } from 'react';
import useAuth from '@src/utils/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { SelectableList } from '@src/pages/CMDB/SuspectErrors/SelectableList';
import { format } from 'date-fns';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Card } from '@abyss/web/ui/Card';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Heading } from '@abyss/web/ui/Heading';
import { convertDateFormat } from '@src/utils/convertDateFormat';
import { Modal } from '@abyss/web/ui/Modal';
import {
  automatedFeedSearchApi,
  automatedFeedDynamicApi,
} from '@src/redux/api/FADB/inquiry/automatedFeeds/searchAPI';
import {
  updateAutomatedFeedSearch,
  updateAutomatedFeedSearchSuccess,
  updateAutomatedFeedSearchError,
  setPayload,
  setFileData,
  setTransData,
} from '@src/redux/action/FADB/inquiry/automatedFeeds/searchAPI';
import {
  fetchDynamicDropdownFailure,
  fetchDynamicDropdownSuccess,
} from '@src/redux/action/FADB/policy/policyActions';
import withAuth from '@src/auth/withAuth';

const AutomatedFeeds = () => {
  const { readOnly, token } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { navigate, getLocation } = useRouter();
  const dispatch = useDispatch();
  const [searchScreenValue, setSearchScreenValue] = useState('');
  const [searchScreenIncludeValue, setSearchScreenIncludeValue] = useState('');
  const [caseInstallationSourceSystem, setCaseInstallationSourceSystem] = useState('');
 
  const [fileStatus, setFileStatus] = useState('');
  const [authorOfChanges, setAutherOfChanges] = useState('');
  const [fileSourceIdentification, setFileSourceIndentification] = useState('');
  const [fileNumber, setFileNumber] = useState('');
  const [errorNumber, setErrorNumber] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    include: '',
    levelOfDetail: '',
    changeFileDate: '',
    fileNumbererror: '',
    changeTransDate: '',
  });
  const [caseInstallationDropdown, setCaseInstallationDropdown] = useState([]);
  const [fileStatusDropdown, setFileStatusDropdown] = useState([]);
  const [transStatusDropdown, setTransStatusDropdown] = useState([]);
  const [fileChangeFromDate, setFileChangeFromDate] = useState('');
  const [fileChangeToDate, setFileChangeToDate] = useState('');
  const [fileSourceFromDate, setFileSourceFromDate] = useState('');
  const [fileSourceToDate, setFileSourceToDate] = useState('');
  const [transChangeFromDate, setTransChangeFromDate] = useState('');
  const [transChangeToDate, setTransChangeToDate] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors]: any = useState({});
  const [errorsFile, setErrorsFile]: any = useState({});
  const [errorsTrans, setErrorsTrans]: any = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [transStatus, setTransStatus] = useState('')
  const [addedErrorNumber, setAddedErrorNumber] = useState<number[]>([]);
  const [selectedErrorNumber, setSelectedErrorNumber] = useState<number[]>([]);
  const [transAuthorOfChanges, setTransAuthorOfChanges] = useState('')
  const isSelectedFeedTrans = searchScreenValue == 'T';
  const isSelectedFeedFile = searchScreenValue == 'F';
  const redirectionPath =
    '/fadb/inquiry/automated-feeds/automated-feeds-search';
  let location = getLocation();

  const handleDynamicDropdown = () => {
    automatedFeedDynamicApi(config)
      .then((response) => {
        setCaseInstallationDropdown(response?.data?.caseInst);
        setFileStatusDropdown(response?.data?.fileStatus);
        setTransStatusDropdown(response?.data?.transStatus);
        dispatch(fetchDynamicDropdownSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDynamicDropdownFailure(error));
      });
  };

  const loading = useSelector((state) => state.automatedFeedSearch.loading);
  useEffect(() => {
    if (token) handleDynamicDropdown();
  }, [token]);

  const handleChange = (name, value) => {
    switch (name) {
      case 'searchScreen':
        setSearchScreenValue(value);
        break;
      case 'include':
        setSearchScreenIncludeValue(value);
        break;
      case 'autoCsInstlltnSrc':
        setCaseInstallationSourceSystem(value);
        break;
      case 'stsCd':
        setFileStatus(value);
        break;
      case 'errorNbr':
        setErrorNumber(value);
        break;
      case 'athrOfChngs':
        setAutherOfChanges(value);
        break;
        case 'transAuthorOfChanges':
          setTransAuthorOfChanges(value);
          break;
      case 'sourceId':
        setFileSourceIndentification(value);
        break;
      case 'fileNbr':
        setFileNumber(value);
        break;
      case 'fileChangeFromDate':
        setFileChangeFromDate(value);
        break;
      case 'fileChangeToDate':
        setFileChangeToDate(value);
        break;
      case 'fileSourceFromDate':
        setFileSourceFromDate(value);
        break;
      case 'fileSourceToDate':
        setFileSourceToDate(value);
        break;
      case 'transChangeFromDate':
        setTransChangeFromDate(value);
        break;
      case 'transChangeToDate':
        setTransChangeToDate(value);
        break;
      case 'transStatus':
        setTransStatus(value);
        break;
      default:
        break;
    }
  };

  const checkNumeric = (value) => {
    const num: RegExp = /^[0-9]*$/;
    if (value.match(num)) {
      return false;
    } else {
      return true;
    }
  };

  const reset = () => {
    setSearchScreenValue('');
    setSearchScreenIncludeValue('');
    setCaseInstallationSourceSystem('');
    setFileStatus('');
    setTransStatus('')
    setAddedErrorNumber([]);
    setErrorNumber('');
    setSelectedErrorNumber([]);
    setAutherOfChanges('');
    setFileSourceIndentification('');
    setFileNumber('');
    setFileChangeFromDate('');
    setFileChangeToDate('');
    setFileSourceFromDate('');
    setFileSourceToDate('');
    setTransChangeFromDate('');
    setTransChangeToDate('');
    setTransAuthorOfChanges('')
    setErrors('');
    setErrorsFile('');
    setErrorsTrans('');
  }

  const checkMandatoryFields = () => {
    let errors: any = {};
    let isValid = true;
    if (!searchScreenValue) {
      errors.searchScreenValue = 'Level of Detail is a required field';
      isValid = false;
    }
    if (!searchScreenIncludeValue) {
      errors.searchScreenIncludeValue = 'Include is a required field';
      isValid = false;
    }
    if (fileNumber && checkNumeric(fileNumber)) {
      errors.fileNumber = 'File Number must be numeric';
      isValid = false;
    }
    setErrors(errors);
    setIsValid(isValid);
    return isValid;
  };

  const transform = (date) => {
    return date
      ? format(new Date(date), 'yyyy-MM-dd')
      : format(new Date('9999-12-31'), 'yyyy-MM-dd');
  };

  const mandFileFields = () => {
    let errorsFile: any = {};
    let isValid = true;
    if (isSelectedFeedFile && !fileChangeFromDate ) {
      errorsFile.fileChangeFromDate = 'Change Date is required field'
      isValid= false;
    } if (isSelectedFeedFile && !fileChangeToDate ) {
      errorsFile.fileChangeToDate = 'Change Date is required field'
      isValid= false;
    }
    if (isSelectedFeedFile && fileChangeFromDate && fileChangeToDate) {
      const frmDt = fileChangeFromDate
        ? transform(fileChangeFromDate)
        : transform('0100-01-01');
      const toDt = fileChangeToDate
        ? transform(fileChangeToDate)
        : transform('9999-12-31');
      const diffInMs: number = Date.parse(toDt) - Date.parse(frmDt);
      const diffInDays: number = diffInMs / 1000 / 60 / 60 / 24;
      if (diffInDays < 0 || diffInDays > 30) {
        errorsFile.fileChangeToDate = 'Change date period should not be greater than 30 days';
        isValid = false;
      }
    }
    setErrorsFile(errorsFile);
    setIsValid(isValid);
    return isValid;
  };

  const mandTransFields = () => {
    let errorsTrans: any = {};
    let isValid = true;
    if (isSelectedFeedTrans && !transChangeFromDate) {
      errorsTrans.transChangeFromDate = 'Change Date is required field'
      isValid= false;
    } 
    if (isSelectedFeedTrans && !transChangeToDate) {
      errorsTrans.transChangeToDate = 'Change Date is required field'
      isValid= false;
    }
    if (
      isSelectedFeedTrans &&
      addedErrorNumber?.length > 0 &&
      searchScreenIncludeValue !== 'E'
    ) {
      setIsOpenModal(true)
      isValid = false;
    } if (isSelectedFeedTrans && transChangeFromDate && transChangeToDate) {
      const frmDt = transChangeFromDate
        ? transform(transChangeFromDate)
        : transform('0100-01-01');
      const toDt = transChangeToDate
        ? transform(transChangeToDate)
        : transform('9999-12-31');
      const diffInMs: number = Date.parse(toDt) - Date.parse(frmDt);
      const diffInDays: number = diffInMs / 1000 / 60 / 60 / 24;
      if (diffInDays < 0 || diffInDays > 30) {
        errorsTrans.transChangeToDate = 'Change date period should not be greater than 30 days';
        isValid = false;
      }
    }
    setErrorsTrans(errorsTrans);
    setIsValid(isValid);
    return isValid;
  };

  const handleAddErrorCode = () => {
    let errors: any = {};
    if (errorNumber) {
      const isValid = /^\d{1,7}$/.test(errorNumber);
      const exists = addedErrorNumber && addedErrorNumber.some((option) => option === errorNumber);
      if (exists) {
        errors.errorNumber = 'You have entered a duplicate value';
        setErrors(errors);
        return;
      }
      if (checkNumeric(errorNumber)) {
        errors.errorNumber = 'Error number must be numeric';
        setErrors(errors);
        return;
      }
      if (!isValid) {
        errors.errorNumber = 'Error Number must be less than  or equal to 7 character';
        setErrors(errors);
        return;
      }
      setAddedErrorNumber((prev) =>  [...prev, errorNumber]);
      setErrorNumber('');
      setErrors('');
    } else {
      errors.accountCode = 'Please enter the Account Code';
      setErrors(errors);
    }
  };

  const handleSelectItem = (index, selectedItem, setSelectedItem) => {
    if (!selectedItem.includes(index)) {
      setSelectedItem([index]);
    } else {
      setSelectedItem((prev) => prev.filter((item) => item !== index));
    }
  };

  const removeSelectedItem = (
    addedItem,
    setAddedItem,
    selectedItem,
    setSelectedItem
  ) => {
    const updatedItem = addedItem.filter(
      (_, index) => !selectedItem.includes(index)
    );
    setAddedItem(updatedItem);
    setSelectedItem([]);
  };  
  
  const handleSubmitButton = () => {
    if (checkMandatoryFields() && mandFileFields() && mandTransFields()) {
          handleSubmitApi();
          if (isSelectedFeedFile) {
            navigate(redirectionPath, {
              state: {
                searchScreenValue,
                searchScreenIncludeValue,
                caseInstallationSourceSystem,
                addedErrorNumber,
                transStatus,
                fileStatus,
                transAuthorOfChanges,
                authorOfChanges,
                transChangeFromDate,
                fileChangeFromDate,
                transChangeToDate,
                fileChangeToDate,
                fileSourceIdentification,
                fileNumber,
                fileSourceFromDate,
                fileSourceToDate,
              },
            });
          }
          if (isSelectedFeedTrans) {
            navigate(redirectionPath, {
              state: {
                searchScreenValue,
                searchScreenIncludeValue,
                caseInstallationSourceSystem,
                addedErrorNumber,
                transStatus,
                fileStatus,
                transAuthorOfChanges,
                authorOfChanges,
                transChangeFromDate,
                fileChangeFromDate,
                transChangeToDate,
                fileChangeToDate,
                fileSourceIdentification,
                fileNumber,
                fileSourceFromDate,
                fileSourceToDate,
              },
            });
          }
        }
  setErrorMessages({
    ...errorMessages,
    ...{
      include: '',
      levelOfDetail: '',
    },
  });
};

  const handleSubmitApi = async () => {
      const selectedStsLabel = fileStatusDropdown.find(
        (item) => item.value === fileStatus
      );
      const selectedCaseSourceLabel = caseInstallationDropdown.find(
        (item) => item.value === caseInstallationSourceSystem
      );
      const feedDataObj = {
        searchScreen: searchScreenValue,
        include: searchScreenIncludeValue,
        autoCsInstlltnSrc: caseInstallationSourceSystem
          ? caseInstallationSourceSystem
          : '',
        autoCsInstlltnSrcLabel: selectedCaseSourceLabel?.label
          ? selectedCaseSourceLabel?.label
          : '',
        errorNbr: addedErrorNumber && addedErrorNumber.join(','),
        stsCd: isSelectedFeedTrans ? transStatus || '' : fileStatus || '',
        stsLabel: selectedStsLabel?.label ? selectedStsLabel?.label : '',
        athrOfChngs: isSelectedFeedTrans
          ? transAuthorOfChanges || ''
          : authorOfChanges || '',
        sourceId: fileSourceIdentification ? fileSourceIdentification : '',
        fileNbr: fileNumber ? fileNumber : '',
        fromFileSrcDt: fileSourceFromDate
          ? convertDateFormat(fileSourceFromDate)
          : '',
        toFileSrcDt: fileSourceToDate
          ? convertDateFormat(fileSourceToDate)
          : '',
        fromChgDt: convertDateFormat(
          isSelectedFeedTrans ? transChangeFromDate : fileChangeFromDate
        ),
        toChgDt: convertDateFormat(
          isSelectedFeedTrans ? transChangeToDate : fileChangeToDate
        ),
        pageNumber: 1,
        recordsPerPage: '',
      };
      try {
        dispatch(
          setPayload({
            feedSelection: isSelectedFeedFile ? 'F' : 'T',
            changeDateFrom: isSelectedFeedTrans
              ? transChangeFromDate
              : fileChangeFromDate,
            changeDateTo: isSelectedFeedTrans
              ? transChangeToDate
              : fileChangeToDate,
            include: searchScreenIncludeValue,
            caseInstallationSource: selectedCaseSourceLabel?.label,
            errorNumber: addedErrorNumber,
          })
        );
        dispatch(updateAutomatedFeedSearch(feedDataObj));
        const response = await automatedFeedSearchApi(feedDataObj, config);
        if (response.status === 200) {
          dispatch(updateAutomatedFeedSearchSuccess(response.data));
          if (isSelectedFeedFile) {
            dispatch(setFileData(response.data));
          }
          if (isSelectedFeedTrans) {
            dispatch(setTransData(response.data));
          }
        }
      } catch (error) {
        dispatch(updateAutomatedFeedSearchError(error));
      }
    
  setErrorMessages({
    ...errorMessages,
    ...{
      include: '',
      levelOfDetail: '',
    },
  });
};

useEffect(()=>{
  let data = location?.state;
  setSearchScreenValue(data?.searchScreenValue);
  setSearchScreenIncludeValue(data?.searchScreenIncludeValue);
  setCaseInstallationSourceSystem(data?.caseInstallationSourceSystem);
  setFileStatus(data?.fileStatus);
  setTransStatus(data?.transStatus);
  setTransAuthorOfChanges(data?.transAuthorOfChanges);
  setAutherOfChanges(data?.authorOfChanges);
  setFileSourceIndentification(data?.fileSourceIdentification);
  setFileNumber(data?.fileNumber);
  setFileChangeFromDate(data?.fileChangeFromDate);
  setFileChangeToDate(data?.fileChangeToDate);
  setFileSourceFromDate(data?.fileSourceFromDate);
  setFileSourceToDate(data?.fileSourceToDate);
  setTransChangeFromDate(data?.transChangeFromDate);
  setTransChangeToDate(data?.transChangeToDate);
},[location?.state])

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5} css={{ textAlign: "center" }}>
                      Automated Feeds Inquiry Search Criteria
                    </Heading>
                  </Grid.Col>
                </Grid>
                <Grid css={{ margin: '0px !important' }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <RadioGroup
                      label="Level of Detail:"
                      display="row"
                      onChange={(e) =>
                        handleChange('searchScreen', e.target.value)
                      }
                      value={searchScreenValue}
                      isDisabled={readOnly}
                      errorMessage={errors.searchScreenValue}
                    >
                      <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                        <RadioGroup.Radio label="File" value="F" />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                        <RadioGroup.Radio
                          label="Transaction"
                          value="T"
                          isDisabled={readOnly}
                        />
                      </Grid.Col>
                    </RadioGroup>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <RadioGroup
                      label="Include:"
                      display="row"
                      onChange={(e) => handleChange('include', e.target.value)}
                      value={searchScreenIncludeValue}
                      isDisabled={readOnly}
                      errorMessage={errors.searchScreenIncludeValue}
                    >
                      <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                        <RadioGroup.Radio
                          label="All"
                          value="A"
                          isDisabled={readOnly}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                        <RadioGroup.Radio label="Errors" value="E" />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
                        <RadioGroup.Radio
                          label="Without Errors"
                          value="W"
                          isDisabled={readOnly}
                        />
                      </Grid.Col>
                    </RadioGroup>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="Case Installation Source System"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      labelKey="label"
                      valueKey="value"
                      // isDisabled={readOnly}
                      value={caseInstallationSourceSystem}
                      onChange={(val) => {
                        handleChange('autoCsInstlltnSrc', val);
                      }}
                      options={caseInstallationDropdown.map((type: any) => ({
                        value: type.value,
                        label: type.label,
                      }))}
                    />
                  </Grid.Col>
                </Grid>
                <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>File</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="Status"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={fileStatus}
                      onChange={(val) => {
                        handleChange('stsCd', val);
                      }}
                      options={fileStatusDropdown.map((type: any) => ({
                        value: type.value,
                        label: type.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Author of Changes"
                      value={authorOfChanges}
                      onChange={(e) =>
                        handleChange('athrOfChngs', e.target.value)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="File Source Identification"
                      value={fileSourceIdentification}
                      onChange={(e) => handleChange('sourceId', e.target.value)}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="File number"
                      value={fileNumber}
                      onChange={(e) => handleChange('fileNbr', e.target.value)}
                      isDisabled={readOnly}
                      errorMessage={errors.fileNumber}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Change Date From"
                      placeholder="Pick one"
                      isClearable
                      value={fileChangeFromDate}
                      onChange={(date) =>
                        handleChange('fileChangeFromDate', date)
                      }
                      errorMessage={errorsFile.fileChangeFromDate}
                      css={{ float: 'left' }}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Change Date To"
                      placeholder="Pick one"
                      isClearable
                      value={fileChangeToDate}
                      onChange={(date) =>
                        handleChange('fileChangeToDate', date)
                      }
                      errorMessage={errorsFile.fileChangeToDate}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="File Source Creation Date From"
                      placeholder="Pick one"
                      isClearable
                      value={fileSourceFromDate}
                      onChange={(date) =>
                        handleChange('fileSourceFromDate', date)
                      }
                      css={{ float: 'left' }}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="File Source Creation Date To"
                      placeholder="Pick one"
                      isClearable
                      value={fileSourceToDate}
                      onChange={(date) =>
                        handleChange('fileSourceToDate', date)
                      }
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                </Grid>
                <Grid css={{ 'abyss-grid': { marginTop: '2rem' } }}>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Heading offset={5}>Transaction</Heading>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4 }}>
                    <SelectInput
                      label="Status"
                      placeholder="Pick one"
                      isClearable
                      isSearchable
                      value={transStatus}
                      onChange={(val) => {
                        handleChange('transStatus', val);
                      }}
                      options={transStatusDropdown.map((type: any) => ({
                        value: type.value,
                        label: type.label,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <TextInput
                      label="Author of Changes"
                      value={transAuthorOfChanges}
                      onChange={(e) =>
                        handleChange('transAuthorOfChanges', e.target.value)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Change Date From"
                      placeholder="Pick one"
                      isClearable
                      value={transChangeFromDate}
                      onChange={(date) =>
                        handleChange('transChangeFromDate', date)
                      }
                      errorMessage={errorsTrans.transChangeFromDate}
                      css={{ float: 'left' }}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                    <DateInput
                      label="Change Date To"
                      placeholder="Pick one"
                      isClearable
                      value={transChangeToDate}
                      onChange={(date) =>
                        handleChange('transChangeToDate', date)
                      }
                      errorMessage={errorsTrans.transChangeToDate}
                      isDisabled={readOnly}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                    <TextInput
                      label="Error Number"
                      value={errorNumber}
                      onChange={(e) => handleChange('errorNbr', e.target.value)}
                      errorMessage={errors.errorNumber}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '1.5rem',
                          paddingLeft: '0.9rem',
                          paddingRight: '0.9rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={handleAddErrorCode}
                    >
                      Add
                    </Button>
                  </Grid.Col>
                </Grid>
                <Grid css={{ paddingLeft: "262px", marginTop: "-30px" }}>
                  <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 3 }} >
                    <SelectableList
                      items={addedErrorNumber}
                      selectedItems={selectedErrorNumber}
                      onSelectItem={(index) =>
                        handleSelectItem(
                          index,
                          selectedErrorNumber,
                          setSelectedErrorNumber
                        )
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
                    <Button isDisabled={readOnly} onClick={() =>
                      removeSelectedItem(
                        addedErrorNumber,
                        setAddedErrorNumber,
                        selectedErrorNumber,
                        setSelectedErrorNumber
                      )
                    }>Remove</Button>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      'abyss-grid-col': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      onClick={handleSubmitButton}
                      isDisabled={readOnly}
                    >
                      Search
                    </Button>
                    <Button
                      css={{
                        'abyss-button-root': {
                          marginTop: '25px !important',
                          margin: '0.5rem',
                        },
                      }}
                      isDisabled={readOnly}
                      onClick={reset}
                    >
                      Cancel
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        {isOpenModal && (
          <Modal
            title=""
            isOpen={isOpenModal}
            size={'md'}
            onClose={() => setIsOpenModal(false)}
          >
            <Modal.Section>
              Choosing an error number and selecting "Include: ALL"<br />
              or "Include: WITHOUT" are not valid combinations.<br />
              If you are trying to query on certain error numbers,<br />
              please select "Include: ERRORS".
            </Modal.Section>
          </Modal>
        )}
      </React.Fragment>
    </>
  );
};

export default withAuth(AutomatedFeeds);
