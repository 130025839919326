import {
  UPDATE_CASE_STRUCTURE_SEARCH,
  UPDATE_CASE_STRUCTURE_SEARCH_ERROR,
  UPDATE_CASE_STRUCTURE_SEARCH_SUCCESS,
} from '@src/redux/action/FADB/inquiry/caseStructure/caseStructureContants';

const initialState = {
  error: null,
  data: [],
};

export default function caseStructureReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CASE_STRUCTURE_SEARCH:
      return { ...state, data: action.data };
    case UPDATE_CASE_STRUCTURE_SEARCH_SUCCESS:
      return { ...state, data: action.data };
    case UPDATE_CASE_STRUCTURE_SEARCH_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
