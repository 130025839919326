import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { getEnvironment } from '@src/utils/environmentUtil';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { TextInput } from '@abyss/web/ui/TextInput';
import { BASE_URL } from '@src/utils/endpoints';

const Bypass = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let updatePayload = {
      polNbr: payload['POL_NBR'],
      plnVarCdNbr: payload['PLN_VAR_CD_NBR'],
      rptCdNbr: payload['RPT_CD_NBR'],
      bypSrcCd: payload['BYP_SRC_CD'],
      bypLvlCd: payload['BYP_LVL_CD'],
      bypRsnTxt: payload['BYP_RSN_TXT'],
      lstUpdtDt: payload['LSTUPDTDT'],
      lstUpdtTm: payload['LST_UPDT_TM'],
      lstUpdtId: payload['LST_UPDT_ID'],
      updtInd: payload['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/bus-proc-byp-rule/${location?.state?.database}`,
        updatePayload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [polNbr, setPolNbr] = useState('');
  const [plnVarCdNbr, setPlnVarCdNbr] = useState('');
  const [rptCdNbr, setRptCdNbr] = useState('');
  const [bypsrcCd, setbypsrcCd] = useState('');
  const [bybLvlCd, setBypLvlCd] = useState('');
  const [bypRsnTxt, setBypRsnTxt] = useState('');
  const [flag, setFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const { navigate } = useRouter();
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRows(row['original']);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setHideAdd(true);
    setUpdateRow(row);
    setPolNbr(row['POL_NBR']);
    setPlnVarCdNbr(row['PLN_VAR_CD_NBR']);
    setRptCdNbr(row['RPT_CD_NBR']);
    setbypsrcCd(row['BYP_SRC_CD']);
    setBypLvlCd(row['BYP_LVL_CD']);
    setBypRsnTxt(row['BYP_RSN_TXT']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const save = async () => {
    setFlag(false);
    setHideUpdate(false);
    let payload = {
      polNbr: polNbr,
      plnVarCdNbr: plnVarCdNbr,
      rptCdNbr: rptCdNbr,
      bypSrcCd: bypsrcCd,
      bypLvlCd: bybLvlCd,
      bypRsnTxt: bypRsnTxt,
      lstUpdtDt: '',
      lstUpdtTm: '',
      lstUpdtId: userName,
      updtInd: 'Y',
    };
    setPolNbr('');
    setPlnVarCdNbr('');
    setRptCdNbr('');
    setbypsrcCd('');
    setBypLvlCd('');
    setBypRsnTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/bus-proc-byp-rule/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    setFlag(false);
    setHideAdd(false);
    const now = new Date();
    let payload = {
      newRecord: {
        bypLvlCd: bybLvlCd,
        bypRsnTxt: bypRsnTxt,
        bypSrcCd: bypsrcCd,
        plnVarCdNbr: plnVarCdNbr,
        polNbr: polNbr,
        rptCdNbr: rptCdNbr,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: userName,
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
      oldRecord: {
        bypLvlCd: updateRow['BYP_LVL_CD'],
        bypRsnTxt: updateRow['BYP_RSN_TXT'],
        bypSrcCd: updateRow['BYP_SRC_CD'],
        plnVarCdNbr: updateRow['PLN_VAR_CD_NBR'],
        polNbr: updateRow['POL_NBR'],
        rptCdNbr: updateRow['RPT_CD_NBR'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        updtInd: updateRow['UPDT_IND'],
      },
    };
    setPolNbr('');
    setPlnVarCdNbr('');
    setRptCdNbr('');
    setbypsrcCd('');
    setBypLvlCd('');
    setBypRsnTxt('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/bus-proc-byp-rule/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setPolNbr('');
    setPlnVarCdNbr('');
    setRptCdNbr('');
    setbypsrcCd('');
    setBypLvlCd('');
    setBypRsnTxt('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />
              <Button variant="tertiary" onClick={addRow} isDisabled={hideAdd}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="POL_NBR"
                        value={polNbr}
                        onChange={(e) => setPolNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="PLN_VAR_CD_NBR"
                        value={plnVarCdNbr}
                        onChange={(e) => setPlnVarCdNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="RPT_CD_NBR"
                        value={rptCdNbr}
                        onChange={(e) => setRptCdNbr(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="BYP_SRC_CD"
                        value={bypsrcCd}
                        onChange={(e) => setbypsrcCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="BYP_LVL_CD"
                        value={bybLvlCd}
                        onChange={(e) => setBypLvlCd(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="BYP_RSN_TXT"
                        value={bypRsnTxt}
                        onChange={(e) => setBypRsnTxt(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={updateFlag ? update : save}>Save</Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(Bypass);
