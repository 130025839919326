import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Grid } from '@abyss/web/ui/Grid';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Text } from '@abyss/web/ui/Text';
import { Heading } from '@abyss/web/ui/Heading';
import { convertDateFormat } from '@src/utils/convertDateFormat';
import useAuth from '@src/utils/useAuth';
import TransactionStatusModal from './TransactionStatusModel';
import {
  automatedFeedErrorCorrectionApi,
  automatedFeedPolicyApi,
  automatedFeedSearchApi,
} from '@src/redux/api/FADB/inquiry/automatedFeeds/searchAPI';
import {
  setFileData,
  setTransData,
  updateAutomatedFeedErrorCorrection,
  updateAutomatedFeedErrorCorrectionError,
  updateAutomatedFeedErrorCorrectionSuccess,
  updateAutomatedFeedPolicyDetails,
  updateAutomatedFeedPolicyDetailsError,
  updateAutomatedFeedPolicyDetailsSuccess,
  updateAutomatedFeedSearch,
  updateAutomatedFeedSearchError,
  updateAutomatedFeedSearchSuccess,
} from '@src/redux/action/FADB/inquiry/automatedFeeds/searchAPI';
import withAuth from '@src/auth/withAuth';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { WrapperLoader } from '@src/common/WrapperLoader/WrapperLoader';

const AutomatedFeedsSearch = () => {
  const { navigate, getLocation } = useRouter();
  const [records, setRecords] = useState('0 records');
  const dispatch = useDispatch();
  const { token } = useAuth();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const redirectionPath =
    '/fadb/inquiry/automated-feeds/automated-feeds-search/policyNumber';

  let location = getLocation();
  let data = location?.state;
  const today = new Date();
  const day = today.toLocaleString('default', { weekday: 'long' });
  const date = today.getDate();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const currentDate = `${day}, ${month} ${date}, ${year}`;

  const filterHandler = async () => {
    const feedDataObj = {
      searchScreen: feedSelection,
      include: include,
      autoCsInstlltnSrc: caseInstallationSource,
      autoCsInstlltnSrcLabel: '',
      errorNbr: '',
      stsCd: '',
      stsLabel: '',
      athrOfChngs: '',
      sourceId: '',
      fileNbr: '',
      fromChgDt: convertDateFormat(changeDateFrom),
      toChgDt: convertDateFormat(changeDateTo),
    };
    dispatch(updateAutomatedFeedSearch(feedDataObj));
    try {
      const response = await automatedFeedSearchApi(feedDataObj, config);
      if (response.status === 200) {
        dispatch(updateAutomatedFeedSearchSuccess(response.data));
        navigate('/fadb/inquiry/automated-feeds/automated-feeds-search');
      }
    } catch (error) {
      dispatch(updateAutomatedFeedSearchError(error));
    }
  };
  const apiHandler = async () => {
    const feedDataObj = {
      searchScreen: feedSelection,
      include: include,
      autoCsInstlltnSrc: caseInstallationSource,
      autoCsInstlltnSrcLabel: '',
      errorNbr: '',
      stsCd: '',
      stsLabel: '',
      athrOfChngs: '',
      sourceId: '',
      fileNbr: '',
      fromChgDt: convertDateFormat(changeDateFrom),
      toChgDt: convertDateFormat(changeDateTo),
    };
    dispatch(updateAutomatedFeedSearch(feedDataObj));
    try {
      const response = await automatedFeedSearchApi(feedDataObj, config);
      if (response.status === 200) {
        dispatch(updateAutomatedFeedSearchSuccess(response.data));
        if (feedSelection === 'F') {
          dispatch(setFileData(response.data));
          navigate(redirectionPath);
        }
        if (feedSelection === 'T') {
          dispatch(setTransData(response.data));

          navigate(redirectionPath);
        }
      }
    } catch (error) {
      dispatch(updateAutomatedFeedSearchError(error));
    }
  };

  //get data from autofeed search reducer
  const searchData = useSelector((state) => state.automatedFeedSearch.feedData);

  useEffect(()=>{
     if(searchData?.pageItems && Array.isArray(searchData?.pageItems) && searchData?.pageItems?.length > 0){
      dataTablePropsPagination.setData(searchData?.pageItems);
      setRecords(`${searchData.pageItems.length} records`)
     }
     searchData?.pageItems ? setIsLoading(false) : null; 
  },[searchData])

  const payloadData = useSelector((state) => state.automatedFeedSearch.data);
  let {
    feedSelection,
    changeDateFrom,
    changeDateTo,
    caseInstallationSource,
    include,
    errorNumber,
  } = payloadData && payloadData;

  // Make API call if search data not present
  useEffect(() => {
    if (!searchData) {
      if (token) apiHandler();
    }
  }, [searchData, dispatch, token]);

  const handleModal = () => {
    setIsOpen(true);
  };

  const getPolicyDetails = async (
    policySelectedCellObj,
    selectedPolicyNumber
  ) => {
    let { fileNbr, transId, caseInstall } =
      policySelectedCellObj && policySelectedCellObj;
    let feedDataObj = {
      fileId: fileNbr,
      trnId: transId,
      autoCsInstlltnSrc: caseInstall,
    };
    try {
      dispatch(updateAutomatedFeedPolicyDetails(feedDataObj));
      const response = await automatedFeedPolicyApi(feedDataObj, config);
      if (response.status === 200) {
        dispatch(updateAutomatedFeedPolicyDetailsSuccess(response.data));
        navigate(`${redirectionPath}/${selectedPolicyNumber}`);
      }
    } catch (error) {
      updateAutomatedFeedPolicyDetailsError(error);
    }
  };

  const getErrorCorrectionDetails = async (payload) => {
    try {
      // dispatch(updateAutomatedFeedErrorCorrection(payload));
      const response = await automatedFeedErrorCorrectionApi(payload, config);
      if (response.status === 200) {
        dispatch(updateAutomatedFeedErrorCorrectionSuccess(response.data));
        handleModal();
      }
    } catch (error) {
      updateAutomatedFeedErrorCorrectionError(error);
    }
  };

  const LinkCellRenderer = ({ cellDataObj, selectedPolicyNumber }) => {
    const policySelectedCellObj = cellDataObj.find(
      (item) => item.policyNumber === selectedPolicyNumber
    );
    return (
      <a
        onClick={() =>
          getPolicyDetails(policySelectedCellObj, selectedPolicyNumber)
        }
      >
        {selectedPolicyNumber}
      </a>
    );
  };

  const TransStatusCellRenderer = ({ value, cellActions, row }) => {
    if (
      row.original.transStatus === 'Error' ||
      row.original.transStatus === 'Failed Derivation' ||
      row.original.transStatus === 'Updated'
    ) {
      return (
        <a
          onClick={() => {
            // const result = cellDataObj.find((item) => item.transStatus === value);
            const payload = {
              fileId: row.original?.ndDFileId,
              trnId: row.original?.transId,
              trnErrCd: row.original?.errorNumber,
              action: 'F',
            };
            getErrorCorrectionDetails(payload);
          }}
        >
          {value}
        </a>
      );
    } else return value;
  };
  const handleClick = () => {
    navigate('/fadb/inquiry/automated-feeds', { state: data });
  };

  const customHeaderButtons = [
    {
      label: 'Modify Search',
      onClick: handleClick,
    },
  ];

  const columns = React.useMemo(
    () =>
      feedSelection === 'F'
        ? [
            {
              Header: 'SRC CREATION DT',
              accessor: 'sourceCreationDt',
              style: { width: '10px !important' },
            },
            {
              Header: 'TYPE CODE	',
              accessor: 'typeCode',
              Cell: ({value}) =>{
               return value === 'P'
                ? 'Prime'
                : value === 'C'
                ? 'Claim Master'
                : value === 'I'
                ? 'Individual Stop/Loss'
                : value === 'S'
                ? 'Claim Structure'
                : value
              }
            },
            {
              Header: 'CHG DT	',
              accessor: 'changeDate',
            },
            {
              Header: 'STATUS CODE	',
              accessor: 'statusCode',
              Cell: ({value}) =>{
                return value === 'C'
                ? 'Corrected Transaction'
                : value === 'D'
                ? 'Duplicate File'
                : value === 'F'
                ? 'Failed Update'
                : value === 'L'
                ? 'Loading'
                : value === 'P'
                ? 'Processing'
                : value === 'R'
                ? 'Reprocessing'
                : value ==='U'
                ? 'Updated'
                : value === 'W'
                ? 'Waiting to be Processed'
                : value === 'S'
                ? 'Restart'
                : value
              }
            },
            {
              Header: 'CHG BY	',
              accessor: 'changedBy',
            },
            {
              Header: 'LOAD DT	',
              accessor: 'loadDate',
            },
            {
              Header: 'PROCESS DT	',
              accessor: 'processDate',
            },
            {
              Header: 'FILE NBR	',
              accessor: 'fileNbr',
            },
            {
              Header: 'SRC ID	',
              accessor: 'sourceId',
            },
            {
              Header: 'GRP ID',
              accessor: 'groupId',
            },
          ]
        : [
            {
              Header: 'CASE INSTALL',

              accessor: 'caseInstall',
            },
            {
              Header: 'TRANS ID	',
              accessor: 'transId',
            },
            {
              Header: 'TRANS SOURCE ID	',
              accessor: 'transSourceId',
            },
            {
              Header: 'TRANS STATUS	',
              accessor: 'transStatus',
              Cell: ({ value, cellActions, row }) => {
                return (
                  <TransStatusCellRenderer
                    value={value}
                    cellActions={cellActions}
                    row={row}
                  />
                );
              },
            },
            {
              Header: 'POLICY NUMBER	',
              accessor: 'policyNumber',
              Cell: ({ data, value }) => (
                <LinkCellRenderer
                  cellDataObj={data}
                  selectedPolicyNumber={value}
                />
              ),
            },
            {
              Header: 'SUFFIX	',
              accessor: 'suffixCd',
            },
            {
              Header: 'ACCOUNT NUMBER	',
              accessor: 'accountNumber',
            },
            {
              Header: 'ERROR NUMBER	',
              accessor: 'errorNumber',
            },
            {
              Header: 'ERROR DESCRIPTION	',
              accessor: 'errorDescription',
            },
            {
              Header: 'CHANGE DATE',
              accessor: 'changeDate',
            },
            {
              Header: 'FILE NBR',
              accessor: 'fileNbr',
            },
            {
              Header: 'SOURCE ID',
              accessor: 'sourceId',
            },
          ],
    []
  );

  const getHeadersForEmptyData = () =>{
    let columnNames = {};
    if(feedSelection === 'F'){
       columnNames = {
        "SRC CREATION DT": null,
        "TYPE CODE": null,
        "CHG DT": null,
        "STATUS CODE": null,
        "CHG BY": null,
        "LOAD DT": null,
        "PROCESS DT": null,
        "FILE NBR": null,
        "SRC ID": null,
        "GRP ID": null
       }
    }else{
      columnNames={
        "CASE INSTALL": null,
        "TRANS ID": null,
        "TRANS SOURCE ID": null,
        "TRANS STATUS": null,
        "POLICY NUMBER": null,
        "SUFFIX": null,
        "ACCOUNT NUMBER": null,
        "ERROR NUMBER": null,
        "ERROR DESCRIPTION": null,
        "CHANGE DATE": null,
        "FILE NBR":  null,
        "SOURCE ID": null,
      }
    }
    return columnNames;
  }

  const columnNameMapping = {
    sourceCreationDt : 'SRC CREATION DT',
    typeCode : 'TYPE CODE',
    changeDate: 'CHG DT',
    statusCode: 'STATUS CODE',
    changedBy: 'CHG BY',
    loadDate: 'LOAD DT',
    processDate: 'PROCESS DT',
    fileNbr: 'FILE NBR',
    sourceId: 'SRC ID',
    groupId: 'GRP ID',
    caseInstall: 'CASE INSTALL',
    transId: 'TRANS ID',
    transSourceId: 'TRANS SOURCE ID',
    transStatus: 'TRANS STATUS',
    policyNumber: 'POLICY NUMBER',
    suffixCd: 'SUFFIX',
    accountNumber: 'ACCOUNT NUMBER',
    errorNumber: 'ERROR NUMBER',
    errorDescription: 'ERROR DESCRIPTION',
    // changeDate: 'CHANGE DATE',
    //sourceId: 'SOURCE ID'
  }

  const downloadExcel = () => {
    let fullData = [];
    dataTablePropsPagination?.filter?.preGlobalFilteredRows?.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      fullData.push(updatedKeyValues);
    });
    if (fullData.length == 0) {
      fullData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(fullData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, feedSelection == 'F'
           ? 'File_Level_Results(Change_Date).xlsx'
          : 'Transaction Maintenance List.xlsx');
  };

  const downloadFilterExcel = () => {
    let filteredData = [];
    dataTablePropsPagination.filter?.filteredRows.forEach((obj) => {
      const updatedKeyValues = Object.keys(obj.values).reduce((acc, item) => {
        const newKey = columnNameMapping[item] || item;
        acc[newKey] = obj.values[item];
        return acc;
      }, {});
      filteredData.push(updatedKeyValues);
    });
    if (filteredData.length == 0) {
      filteredData.push(getHeadersForEmptyData());
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, feedSelection == 'F'
          ? 'File_Level_Results(Change_Date).xlsx'
         : 'Transaction Maintenance List.xlsx');
  };

  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData: (searchData?.pageItems && Array.isArray(searchData?.pageItems) && searchData?.pageItems?.length > 0) ? searchData?.pageItems : [],
    initialColumns: columns,
    downloadButtonConfig: {
       // optional custom action; can also be an array of objects
      custom: [{
        title: 'Download filtered dataset (XLSX)', // option item title
        icon: <IconSymbol icon="filter_alt" />, // option item icon
        onClick: downloadFilterExcel,
      },
      {
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      }]
    },
    customHeaderButtons,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: false,
  });

    useEffect(()=>{
    setRecords(`${dataTablePropsPagination.filter?.filteredRows?.length} records`)
  },[dataTablePropsPagination?.filter?.filteredRows])

  let getIncludeVal = () => {
    return include === 'A'
      ? 'All'
      : include === 'E'
      ? 'Error'
      : 'Without Error';
  };

  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col
            span={{ xs: 10 }}
            css={{
              paddingBottom: '2.5rem',
              paddingTop: '0.5rem',
              paddingRight: '3.5rem',
              paddingLeft: '0rem',
            }}
          >
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Box color="$white">
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    padding: '0px !important',
                  }}
                >
                  <Grid.Col
                    span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Heading
                      textAlign="center"
                      css={{ margin: '0 auto', flexGrow: 1 }}
                      offset={3}
                    >
                      {feedSelection === 'F' ? 'File ' : 'Transaction '}
                      Maintenance List
                    </Heading>
                    <Text
                      css={{
                        marginLeft: 'auto',
                        textAlign: 'right',
                        alignSelf: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      {currentDate}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    margin: '0px !important',
                    marginTop: '20px !important',
                    padding: '0px !important',
                  }}
                >
                  {' '}
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text
                      fontWeight={700}
                      css={{ textDecoration: 'underline', fontSize: '18px' }}
                    >
                      Search Criteria Used:
                    </Text>
                  </Grid.Col>
                  {caseInstallationSource && (
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Text fontWeight={600}>
                        Case Installation Source: {caseInstallationSource}
                      </Text>
                    </Grid.Col>
                  )}
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={600}>Include: {getIncludeVal()}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={600}>
                      Change Date: From {convertDateFormat(changeDateFrom)} To{' '}
                      {convertDateFormat(changeDateTo)}
                    </Text>
                  </Grid.Col>
                  {errorNumber != '' ? (
                    <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                      <Text fontWeight={600}>
                        Error Number: {errorNumber && errorNumber.join(',')}
                      </Text>
                    </Grid.Col>
                  ) : (
                    ' '
                  )}
                  <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    <Text fontWeight={300} css={{ fontSize: '14px' }}>
                      {records}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid css={{ margin: '0px !important' }}>
                  {isLoading && <WrapperLoader />}
                  {!isLoading && (
                    <DataTable
                      title="File Maintenance List"
                      hideTitleHeader
                      tableState={dataTablePropsPagination}
                    />
                    )}
                </Grid>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
        {isOpen && (
          <TransactionStatusModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            filterTableData={filterHandler}
          />
        )}
      </React.Fragment>
    </>
  );
};

export default withAuth(AutomatedFeedsSearch);
