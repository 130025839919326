import React from 'react';
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';
import { Text } from '@abyss/web/ui/Text';
import { Layout } from '@abyss/web/ui/Layout';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Heading } from '@abyss/web/ui/Heading';
import { Button } from '@abyss/web/ui/Button';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import useAuth from '@src/utils/useAuth';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export const PageHeader = () => {
  const { userName } = useAuth();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const logOutAction = () => {
    const logOutConfirmed = window.confirm('Logout ?');
    if (isAuthenticated && logOutConfirmed) instance.logoutRedirect();
  };

  return (
    <>
      {isAuthenticated && (
        <PageHeaderPrimitives.Container
          css={{
            'abyss-page-header-root': {
              width: '100vw',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 16px',
              boxSizing: 'border-box',
              position: 'fixed',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            },
            'abyss-page-header-container': {
              maxWidth: 'none !important',
            },
          }}
        >
          <Layout.Group
            css={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
              justifyContent: 'flex-start',
            }}
          >
            {/* <Brandmark brand="uhc" affiliate="uhc" variant="lockup_horizontal" color="blue" size="$lg" /> */}
            {/* <Brandmark brand="uhc" size="40%" affiliate="uhc" variant="wordmark" color="blue" /> */}
            <img
              src="https://fmtwebdev.optum.com/assets/UHC_Home_logo.png"
              alt="brand logo"
            />

            {/* <Layout.Group
          css={{
            flex: '1',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'flex-start',
          }}
        >
          <Text size="$sm">A UnitedHealth Group Company</Text>
          <Brandmark brand="uhc" size="4%" affiliate="uhc" variant="u_mark" color="blue" width="1rem" css={{ 'abyss-brandmark': {} }} title="A UnitedHealth Group Company" />
        </Layout.Group> */}
          </Layout.Group>
          <Layout.Group
            css={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Heading display="h1" css={{ fontSize: '34px !important' }}>
              Finance Maintenance Tool
            </Heading>
          </Layout.Group>
          <Layout.Group
            css={{
              flex: '1',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Text fontWeight={900} css={{ fontSize: '14px' }}>
              Welcome, {userName}
            </Text>
            <Button onClick={logOutAction} size="$md">
              {/* <IconMaterial
            icon="power_settings_new"
            isScreenReadable
            color="$primary2"
          /> */}
              Log Out
            </Button>
          </Layout.Group>
        </PageHeaderPrimitives.Container>
      )}
    </>
  );
};
