import {
  UPDATE_AUTOMATED_FEED_SEARCH,
  UPDATE_AUTOMATED_FEED_SEARCH_ERROR,
  UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS,
  SET_PAYLOAD_POLICY_DETAILS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS,
  UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS,
  UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR,
  SET_FILE_DATA,
  SET_TRANS_DATA,
} from './automatedConstants';

export function updateAutomatedFeedSearch(feedData) {
  return {
    type: UPDATE_AUTOMATED_FEED_SEARCH,
    feedData,
  };
}
export function updateAutomatedFeedSearchSuccess(feedData) {
  return {
    type: UPDATE_AUTOMATED_FEED_SEARCH_SUCCESS,
    feedData,
  };
}

export function updateAutomatedFeedSearchError(error) {
  return {
    type: UPDATE_AUTOMATED_FEED_SEARCH_ERROR,
    error,
  };
}

export const setPayload = (data) => ({
  type: SET_PAYLOAD_POLICY_DETAILS,
  data,
});

export const setFileData = (fileData) => ({
  type: SET_FILE_DATA,
  fileData,
});
export const setTransData = (transData) => ({
  type: SET_TRANS_DATA,
  transData,
});
export function updateAutomatedFeedPolicyDetails(policyData) {
  return {
    type: UPDATE_AUTOMATED_FEED_POLICY_DETAILS,
    policyData,
  };
}
export function updateAutomatedFeedPolicyDetailsSuccess(policyData) {
  return {
    type: UPDATE_AUTOMATED_FEED_POLICY_DETAILS_SUCCESS,
    policyData,
  };
}

export function updateAutomatedFeedPolicyDetailsError(error) {
  return {
    type: UPDATE_AUTOMATED_FEED_POLICY_DETAILS_ERROR,
    error,
  };
}
export function updateAutomatedFeedErrorCorrection(findData) {
  return {
    type: UPDATE_AUTOMATED_FEED_ERROR_CORRECTION,
    findData,
  };
}
export function updateAutomatedFeedErrorCorrectionSuccess(findData) {
  return {
    type: UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_SUCCESS,
    findData,
  };
}

export function updateAutomatedFeedErrorCorrectionError(error) {
  return {
    type: UPDATE_AUTOMATED_FEED_ERROR_CORRECTION_ERROR,
    error,
  };
}
