import { takeLatest, put, call } from 'redux-saga/effects';

import {
  UPDATE_CASE_STRUCTURE_SEARCH,
  UPDATE_CASE_STRUCTURE_SEARCH_ERROR,
  UPDATE_CASE_STRUCTURE_SEARCH_SUCCESS,
} from '@src/redux/action/FADB/inquiry/caseStructure/caseStructureContants';
import { caseStructureSearchApi } from '@src/redux/api/FADB/inquiry/caseStructure/caseStructureAPI';
function* searchSaga(action) {
  try {
    const data = yield call(caseStructureSearchApi, action.data);
    yield put({ type: UPDATE_CASE_STRUCTURE_SEARCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: UPDATE_CASE_STRUCTURE_SEARCH_ERROR, error });
  }
}
export default function* searchCaseStructureSaga() {
  yield takeLatest(UPDATE_CASE_STRUCTURE_SEARCH, searchSaga);
}
