export const formateDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const parseDate = (formattedDateStr: string): string => {
  const [month, day, year] = formattedDateStr.split('/');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};


export function currentDateFormat(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',

  };
  return date.toLocaleDateString('en-US', options);
}