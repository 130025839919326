import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { getEnvironment } from '@src/utils/environmentUtil';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
const CtsSelectionTable = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
  });
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>
              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length || 0} Records
              </span>
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(CtsSelectionTable);
