import React from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import * as XLSX from 'xlsx';

import { Grid } from '@abyss/web/ui/Grid';

import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

export default function CorrectableTransactionTable({ state }) {
  const { getLocation } = useRouter();
  const location = getLocation();
  console.log('data----', location?.state?.response);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Rsch Req',
        accessor: 'rschReqInd',
        style: { width: '10px !important' },
      },
      {
        Header: 'Charge Date',
        accessor: 'chrgDt',
      },
      {
        Header: 'Transaction ID',
        accessor: 'transId',
      },
      {
        Header: 'Subl Seq',
        accessor: 'sublSeqNbr',
      },
      {
        Header: 'Policy Number',
        accessor: 'polNbr',
      },
      {
        Header: 'Suffix Code',
        accessor: 'sufxCd',
      },
      {
        Header: 'Account Number',
        accessor: 'polAcctNbr',
      },
      {
        Header: 'HMO Account',
        accessor: 'hmoAcctDivNbr',
      },
      {
        Header: 'SD',
        accessor: 'srsDesgCd',
      },
      {
        Header: 'Check Number',
        accessor: 'chkNbr',
      },
      {
        Header: 'CPS#',
        accessor: 'clmPaySeqNbr',
      },
      {
        Header: 'Subl Type',
        accessor: 'sublTypCd',
      },
      {
        Header: 'Subl Amount',
        accessor: 'sublSrvcAmt',
      },
      {
        Header: 'Subscr SSN',
        accessor: 'subscrSsn',
      },
      {
        Header: 'Issue Date',
        accessor: 'chkIssDt',
      },
      {
        Header: 'Svc Date',
        accessor: 'transFstSvcDt',
      },
      {
        Header: 'Fund Arregement',
        accessor: 'fundArngCd',
      },
      {
        Header: 'Leg Entity',
        accessor: 'legEntyCd',
      },
      {
        Header: 'Case Fund',
        accessor: 'caseFundArngCd',
      },
      {
        Header: 'Trans Src',
        accessor: 'transSrcCd',
      },
      {
        Header: 'Actv Ret',
        accessor: 'actvRetCd',
      },
      {
        Header: 'TOPS Trans',
        accessor: 'transCd',
      },
      {
        Header: 'Fee Code',
        accessor: 'feeCd',
      },
      {
        Header: 'Fund Code',
        accessor: 'fundCd',
      },
      {
        Header: 'Product Code',
        accessor: 'prdctCd',
      },
      {
        Header: 'UNET Spcl',
        accessor: 'unetSpclCd',
      },
    ],
    []
  );
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.response);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'CMDB-Apply Itemized Correction.xlsx');
  };
  const dataTablePropsPagination = useDataTable({
    showPagination: true,
    initialData:
      location?.state?.response === 'No records to correct'
        ? []
        : location?.state?.response,
    initialColumns: columns,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showGlobalFilter: true,
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
  });
  return (
    <React.Fragment>
      <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
        <DataTable
          title="Correctable Transactions"
          hideTitleHeader
          tableState={dataTablePropsPagination}
        />
      </Grid.Col>
    </React.Fragment>
  );
}
