import React, { useState } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import useAuth from '@src/utils/useAuth';
import { BASE_URL } from '@src/utils/endpoints';
import { TextInput } from '@abyss/web/ui/TextInput';
import { getEnvironment } from '@src/utils/environmentUtil';
const MbrLegEnty = () => {
  const { getLocation, navigate } = useRouter();
  const location = getLocation();
  const { token, userName } = useAuth();
  const deleteRows = async (payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let payload1 = {
      mbrLegEntyCd: payload['MBR_LEG_ENTY_CD'],
      glBusunitCd: payload['GL_BUSUNIT_CD'],
      obligId: payload['OBLIG_ID'],
      lstUpdtDt: payload['LSTUPDTDT'],
      lstUpdtTm: payload['LST_UPDT_TM'],
      lstUpdtId: payload['LST_UPDT_ID'],
      updtInd: payload['UPDT_IND'],
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/delete/mbr-leg-enty/${location?.state?.database}`,
        payload1,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTableData1 = async () => {
    let payload = [
      location?.state?.database,
      location?.state?.systemSelectedValue,
    ];
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}tableMaintainance/cts/${location?.state?.systemSelectedValue}`,
        payload,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [updateRow, setUpdateRow] = useState({});
  const [mbrLegEnty, setMbrLegEnty] = useState('');
  const [glBus, setGlBus] = useState('');
  const [oblig, setOblig] = useState('');
  const [flag, setFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [hideAdd, setHideAdd] = useState(false);
  const [hideUpdate, setHideUpdate] = useState(false);
  const deleteLinkButton = {
    onClick: ({ deleteRow, row }) => {
      deleteRows(row['original']);
      console.log('deleted row: ', row);
    },
    icon: <IconSymbol icon="delete" />,
    label: hideUpdate ? <IconSymbol icon="delete" /> : 'Delete',
  };
  const updateRows = (row) => {
    setUpdateFlag(true);
    setFlag(true);
    setHideAdd(true);
    setUpdateRow(row);
    setMbrLegEnty(row['MBR_LEG_ENTY_CD']);
    setGlBus(row['GL_BUSUNIT_CD']);
    setOblig(row['OBLIG_ID']);
  };
  const updateLinkButton = {
    onClick: ({ updateRow, row }) => {
      updateRows(row['original']);
      console.log('upds row: ', row);
    },
    icon: <IconSymbol icon="edit" />,
    label: 'Edit',
  };
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(location?.state?.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'dataTable.xlsx');
  };
  const getTableState = useDataTable({
    showPagination: true,
    initialData: Array.isArray(location?.state?.data)
      ? location?.state?.data
      : [],
    initialColumns: Array.isArray(location?.state?.columns)
      ? location?.state?.columns
      : [],
    showSelection: true,
    showDownloadButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    downloadButtonConfig: {
      custom: {
        // optional custom action; can also be an array of objects
        title: 'Download full dataset (XLSX)', // option item title
        icon: <IconSymbol icon="download_done" />, // option item icon
        onClick: downloadExcel,
      },
    },
    showGlobalFilter: true,
    individualActions: hideUpdate
      ? [deleteLinkButton]
      : [updateLinkButton, deleteLinkButton],
  });
  const addRow = () => {
    setFlag(true);
    setHideUpdate(true);
  };
  const handleClick = () => {
    navigate(-1);
  };
  const save = async () => {
    setHideUpdate(false);
    const now = new Date();
    setFlag(false);
    let payload = {
      mbrLegEntyCd: mbrLegEnty,
      glBusunitCd: glBus,
      obligId: oblig,
      lstUpdtDt: now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      lstUpdtId: userName,
      lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
      updtInd: 'Y',
    };
    setMbrLegEnty('');
    setOblig('');
    setGlBus('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/add/mbr-leg-enty/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const update = async () => {
    const now = new Date();
    setHideAdd(false);
    setFlag(false);
    let payload = {
      newRecord: {
        mbrLegEntyCd: mbrLegEnty,
        glBusunitCd: glBus,
        obligId: oblig,
        lstUpdtDt: now.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        lstUpdtId: userName,
        lstUpdtTm: now.toLocaleDateString('en-US', { hour12: false }),
        updtInd: 'Y',
      },
      oldRecord: {
        mbrLegEntyCd: updateRow['MBR_LEG_ENTY_CD'],
        glBusunitCd: updateRow['GL_BUSUNIT_CD'],
        obligId: updateRow['OBLIG_ID'],
        lstUpdtDt: updateRow['LSTUPDTDT'],
        lstUpdtTm: updateRow['LST_UPDT_TM'],
        lstUpdtId: updateRow['LST_UPDT_ID'],
        updtInd: updateRow['UPDT_IND'],
      },
    };
    setMbrLegEnty('');
    setOblig('');
    setGlBus('');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}cts/update/mbr-leg-enty/${location?.state?.database}`,
        payload,
        config
      );
      if (response.status === 200) {
        console.log(response.data);
        let tableData = await getTableData1();
        getTableState.setData(tableData || [], true);
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const close = () => {
    setFlag(false);
    setHideUpdate(false);
    setHideAdd(false);
    setMbrLegEnty('');
    setOblig('');
    setGlBus('');
  };
  return (
    <>
      <React.Fragment>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
            <Card
              css={{
                marginTop: '90px',
                marginLeft: '200px',
                border: 'none',
              }}
            >
              <Heading
                offset={5}
                css={{
                  marginTop: '135px',
                  marginLeft: '12px',
                  marginBottom: '10px',
                }}
              >
                Financial Tables Query Result Screen
              </Heading>
              <Text
                css={{ fontWeight: 600, fontSize: '18px', marginLeft: '12px' }}
              >
                Database:
              </Text>
              <span>
                {' '}
                {getEnvironment()} - {location?.state?.database}
              </span>

              <Text
                css={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginLeft: '20px',
                }}
              >
                Table:
              </Text>
              <span>{location?.state?.systemSelectedValue}</span>
              <br />
              <span style={{ marginLeft: '12px' }}>
                {location?.state?.data?.length} Records
              </span>
              <br />
              <Button variant="tertiary" onClick={addRow} isDisabled={hideAdd}>
                + Add Row
              </Button>
              {flag && (
                <>
                  <Grid
                    css={{
                      background: '#F6F5F5',
                      margin: 'auto',
                      width: '96%',
                    }}
                  >
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="MBR_LEG_ENTY_CD"
                        value={mbrLegEnty}
                        onChange={(e) => setMbrLegEnty(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="GL_BUSUNIT_CD"
                        value={glBus}
                        onChange={(e) => setGlBus(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 4 }}>
                      <TextInput
                        label="OBLIG_ID"
                        value={oblig}
                        onChange={(e) => setOblig(e.target.value)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={updateFlag ? update : save}>Save</Button>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1 }}>
                      <Button onClick={close}>Cancel</Button>
                    </Grid.Col>
                  </Grid>
                </>
              )}
              <Grid
                css={{
                  margin: '0px !important',
                }}
              >
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <DataTable
                    title="Unlock Claim Transactions"
                    tableState={getTableState}
                    hideTitleHeader
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col
                  span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                  css={{
                    'abyss-grid-col': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Button
                    onClick={handleClick}
                    css={{
                      'abyss-button-root': {
                        borderRadius: '0px',
                        marginTop: '25px !important',
                        margin: '0.5rem',
                      },
                    }}
                  >
                    New Table
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid css={{ margin: '0px !important' }}>
          <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            <Footer />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    </>
  );
};

export default withAuth(MbrLegEnty);
