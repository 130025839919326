import React, { useState, useEffect } from 'react';
import { PageHeader } from '@src/components';
import { Sidebar } from '@src/components/Sidebar';
import { Popover } from '@abyss/web/ui/Popover';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Card } from '@abyss/web/ui/Card';
import { Box } from '@abyss/web/ui/Box';
import { Footer } from '@src/components/Footer';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { DateInput } from '@abyss/web/ui/DateInput';
import useAuth from '@src/utils/useAuth';
import axios from 'axios';
import { TextInput } from '@abyss/web/ui/TextInput';
import withAuth from '@src/auth/withAuth';
import { Alert } from '@abyss/web/ui/Alert';
import { BASE_URL, API_BASE_URL } from '@src/utils/endpoints';

const ErrorDownloads = () => {
  const { readOnly, token } = useAuth();

  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedPriorityLevel, setSelectedPriorityLevel] = useState('');
  const [selectedErrorCode, setSelectedErrorCode] = useState('');
  const [sourceSys, setSourceSys] = useState('');
  const [dollarAmt, setDollarAmt] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dollarVal, setDollarVal] = useState('');

  const [toDateErr, setToDateError] = useState('');
  const [fromDateErr, setFromDateError] = useState('');
  const [levelError, setLevelError] = useState('');
  const [priorityError, setpriorityError] = useState('');
  const [ecError, setEcError] = useState('');
  const [dollarAmtError, setDollarAmtError] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [errorDownloadOptions, setErrorDownloadOptions]: any = useState([]);
  const id = 'errorDropdownData';

  const convertDateFormat = (dateStr: string): string => {
    const [month, day, year] = dateStr.split('/');
    return `${month?.padStart(2, '0')}-${day?.padStart(2, '0')}-${year}`;
  };

  const reset = () => {
    setSelectedLevel('');
    setSelectedPriorityLevel('');
    setSelectedErrorCode('');
    setSourceSys('');
    setDollarAmt('');
    setFromDate('');
    setToDate('');
    setDollarVal('');
  };
  const resetErorFields = () => {
    setLevelError('');
    setpriorityError('');
    setEcError('');
    setToDateError('');
    setFromDateError('');
    setDollarAmtError('');
  };
  const validate = () => {
    let validFlag = true;
    resetErorFields();

    if (
      null === selectedLevel ||
      selectedLevel === undefined ||
      selectedLevel === ''
    ) {
      setLevelError('Please select level value ');
      validFlag = false;
    }
    if (
      null === selectedPriorityLevel ||
      undefined === selectedPriorityLevel ||
      '' === selectedPriorityLevel
    ) {
      setpriorityError('Please select priority value ');
      validFlag = false;
    }
    if (
      null === selectedErrorCode ||
      undefined === selectedErrorCode ||
      '' === selectedErrorCode
    ) {
      setEcError('Please select error code value ');
      validFlag = false;
    }
    if (fromDate !== null && toDate !== null) {
      let to = new Date(fromDate);
      let from = new Date(toDate);
      if (to > from) {
        setFromDateError('Charge Date From is not before To Date.');
        validFlag = false;
      }
    }
    return validFlag;
  };

  const handleClick = async () => {
    const valid = validate();
    if (valid) {
      try {
        let selectedClaimSourceCode =
          sourceSys === null || sourceSys === '' || sourceSys === undefined
            ? '-1'
            : sourceSys;
        let selectedDollarAmount =
          dollarAmt === null || dollarAmt === '' || dollarAmt === undefined
            ? '-1'
            : dollarAmt;
        let moneyDollars =
          dollarVal === null || dollarVal === '' || dollarVal === undefined
            ? '-1'
            : dollarVal;
        let chargeFromDate =
          null == fromDate || fromDate === undefined || fromDate === ''
            ? -1
            : convertDateFormat(fromDate);
        let chargeToDate =
          null == toDate || toDate === undefined || toDate === ''
            ? -1
            : convertDateFormat(toDate);
        const response = await axios.get(
          `${BASE_URL}cmdb-service/error-download/policydownload/${selectedLevel}/${selectedPriorityLevel}/${selectedErrorCode}/${selectedClaimSourceCode}/${selectedDollarAmount}/${moneyDollars}/${chargeFromDate}/${chargeToDate}`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          window.open(
            `${BASE_URL}cmdb-service/error-download/policydownload/${selectedLevel}/${selectedPriorityLevel}/${selectedErrorCode}/${selectedClaimSourceCode}/${selectedDollarAmount}/${moneyDollars}/${chargeFromDate}/${chargeToDate}`,
            '_self'
          );
        }
      } catch (error) {
        if (error.status === 404) {
          setIsOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cmdb-service/errorDropdownData/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data) {
          setErrorDownloadOptions(response.data);
        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (token) fetchDropdownData();
  }, [token]);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={10} css={{ paddingBottom: '2.5rem' }}>
          <Card
            css={{
              marginTop: '90px',
              marginLeft: '200px',
              border: 'none',
              boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
            }}
          >
            <Box color="$white">
              <Grid css={{ margin: '0px !important' }}>
                <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                  <Heading offset={5}>Suspect Errors Download</Heading>
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Level"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={[
                      { id: 1, label: 'Policy', value: 'P' },
                      { id: 2, label: 'Suffix', value: 'S' },
                      { id: 3, label: 'Account', value: 'A' },
                      { id: 4, label: 'Legal Entity', value: 'L' },
                    ]}
                    isDisabled={readOnly}
                    value={selectedLevel}
                    onChange={(value) => setSelectedLevel(value)}
                    errorMessage={levelError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Priority Level"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={[
                      { id: 1, label: '01', value: '01' },
                      { id: 2, label: '02', value: '02' },
                      { id: 3, label: '03', value: '03' },
                      { id: 4, label: '07', value: '07' },
                      { id: 5, label: '08', value: '08' },
                      { id: 6, label: '09', value: '09' },
                    ]}
                    isDisabled={readOnly}
                    value={selectedPriorityLevel}
                    onChange={(value) => setSelectedPriorityLevel(value)}
                    errorMessage={priorityError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Error Code"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={errorDownloadOptions?.errorCodeList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={selectedErrorCode}
                    onChange={(value) => setSelectedErrorCode(value)}
                    errorMessage={ecError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Source System"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    options={errorDownloadOptions?.sourceSystemList?.map(
                      (col) => ({
                        value: col.value,
                        label: col.label,
                      })
                    )}
                    isDisabled={readOnly}
                    value={sourceSys}
                    onChange={(value) => setSourceSys(value)}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <SelectInput
                    label="Dollar Amount:"
                    placeholder="Pick one"
                    isClearable
                    isSearchable
                    isDisabled={readOnly}
                    options={[
                      { id: 1, label: 'Over', value: 'O' },
                      { id: 2, label: 'Under', value: 'U' },
                    ]}
                    value={dollarAmt}
                    onChange={(value) => setDollarAmt(value)}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <TextInput
                    label="$"
                    value={dollarVal}
                    onChange={(e) => setDollarVal(e.target.value)}
                    errorMessage={dollarAmtError}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <DateInput
                    label="Charge Date From"
                    isDisabled={readOnly}
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    errorMessage={fromDateErr}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 4, md: 4, lg: 4, xl: 4 }}>
                  <DateInput
                    label="Charge Date To"
                    isDisabled={readOnly}
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    errorMessage={toDateErr}
                  />
                </Grid.Col>
              </Grid>
              <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                <Alert
                  isVisible={isOpen}
                  css={{
                    'abyss-alert-root': {
                      alignItems: 'center',
                    },
                  }}
                  onClose={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  No record found against the search criteria.
                </Alert>
              </Grid.Col>
              <Grid.Col
                span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
                css={{
                  'abyss-grid-col': {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
              >
                <Button
                  css={{
                    'abyss-button-root': {
                      marginTop: '25px !important',
                      margin: '0.5rem',
                    },
                  }}
                  isDisabled={readOnly}
                  onClick={handleClick}
                >
                  Create Report
                </Button>
                <Button
                  css={{
                    'abyss-button-root': {
                      marginTop: '25px !important',
                      margin: '0.5rem',
                    },
                  }}
                  isDisabled={readOnly}
                  onClick={() => {
                    reset();
                    resetErorFields();
                  }}
                >
                  Cancel
                </Button>
              </Grid.Col>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
      <Grid css={{ margin: '0px !important' }}>
        <Grid.Col span={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(ErrorDownloads);
